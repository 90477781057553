import { Container } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CategoryForm from "../../components/category/CategoryForm";

export default function AddNewCategory() {
  const { t } = useTranslation();
  return (
    <Container>
      <h1 className="display-4 mt-4 mb-5">{t("categoryPage.add")}</h1>
      <CategoryForm />
    </Container>
  );
}
