import React from "react";
import TextInputWithTranslationModal from "../TextInputWithTranslationModal";
import CategoryIcon from "@mui/icons-material/Category";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Toast from "../Toast";
import { areObjectsEqual } from "../../services/helperFunctions";
import useFormHook from "../../hooks/useFormHook";
import { languageResources } from "../../services/i18next";
import { addNewTeam, editTeam } from "../../services/api/team";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import CountrySelector from "../CountrySelector";

// should be same as mongodb model
const initialTeamObj = {
  //this reduce func returns {en:"", ar:""}
  name: Object.keys(languageResources).reduce((acc, key) => {
    acc[key] = "";
    return acc;
  }, {}),
  country: "",
};

export default function TeamForm({ team }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const teamSchema = yup.object().shape({
    name: yup.object().shape({
      en: yup.string().required(1, t("teamPage.errors.nameMinErr")),
      ar: yup.string().required(1, t("teamPage.errors.nameMinErr")),
    }),
  });
  const { formik, postMutation, editMutation, snackbar, handleSnackbarClose } =
    useFormHook(
      navigate,
      initialTeamObj,
      team,
      addNewTeam,
      editTeam,
      teamSchema,
      "teamPage",
      "teams"
    );
  return (
    <div style={{ width: "100%" }}>
      <Toast handleSnackbarClose={handleSnackbarClose} snackbar={snackbar} />
      <TextInputWithTranslationModal
        iconComponent={<CategoryIcon />}
        labelI18Key={"teamPage.addNewTeamPage.firstTextFieldLabel"}
        formik={formik}
        fieldName={"name"} //For Team DB model, there is a field called "name:{en,ar}"
      />
      <div className="d-flex  align-item-center mt-2 mb-5">
        <CountrySelector formik={formik} />
        {/* <FormControl sx={{alignSelf:"flex-end"}}>//#TODO: select division for each year.
          <FormLabel id="demo-radio-buttons-group-label">Divison</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            row
            onChange={formik.onChange}
          >
            <FormControlLabel
              value="first"
              control={<Radio />}
              label="First"
            />
            <FormControlLabel value="second" control={<Radio />} label="Second" />
          </RadioGroup>
        </FormControl> */}
      </div>
      <div className="d-flex justify-content-center gap-5 mt-5">
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            team
              ? editMutation.mutate({
                  _id: team._id,
                  updatedTeam: formik.values,
                })
              : postMutation.mutate(formik.values);
          }}
          disabled={
            (Object.keys(formik.errors).length > 0 ? true : false) ||
            areObjectsEqual(team, formik.values)
          }
        >
          {t("buttons.save")}
        </Button>
        <Button
          variant="contained"
          size="large"
          color="error"
          onClick={() => {
            navigate(-1);
          }}
        >
          {t("buttons.cancel")}
        </Button>
      </div>
    </div>
  );
}
