import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SeasonTeamSelector from "../SeasonTeamSelector";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PlayerCareerBreadCrumb from "./PlayerCareerBreadcrumb";
import { useQuery } from "@tanstack/react-query";
import { getTeams } from "../../services/api/team";
import LoadingAnimation from "../LoadingAnimation";
import { useTranslation } from "react-i18next";

export default function SeasonsAccordion({
  formik,
  currentSeasonTeamSelected,
  setCurrentSeasonTeamSelected,
}) {
    const {t} = useTranslation();
  const {
    isPending,
    error,
    data: teamsData,
  } = useQuery({
    queryKey: ["teams"],
    queryFn: getTeams,
  });

  if (isPending) {
    <LoadingAnimation />;
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>{t("playerPage.playerCareer")}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <SeasonTeamSelector
          formik={formik}
          currentSeasonTeamSelected={currentSeasonTeamSelected}
          setCurrentSeasonTeamSelected={setCurrentSeasonTeamSelected}
          data={teamsData}
        />
        {/* <div className="mt-5">
          {playerTeamsKeys.length > 0 ? (
            playerTeamsKeys.map((each) => (
              <p>
                {each} - {formik.values.teams[each].teamId} -{" "}
                {formik.values.teams[each].onLoan.toString()}
              </p>
            ))
          ) : (
            <p className="display-6 lead text">
              There is no data for this player's career.
            </p>
          )}
        </div> */}
        <PlayerCareerBreadCrumb
          data={formik.values.teams}
          handleDelete={(idx) => {
            const temp = formik.values.teams;
            temp.splice(idx, 1)
            formik.setFieldValue("teams", temp);
          }}
          teams={teamsData}
        />
      </AccordionDetails>
    </Accordion>
  );
}
