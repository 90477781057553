import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "@mui/material";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Toast from "../../components/Toast";
import { Link } from "react-router-dom";
import { getMatches, deleteMatch } from "../../services/api/match";
import LoadingAnimation from "../../components/LoadingAnimation";
import CustomTable from "../../components/CustomTable";
import DetailedMatchViewModal from "../../components/Match/DetailedMatchViewModal";

export default function Match() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [matchToView, setMatchToView] = useState(undefined);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const { isPending, error, data } = useQuery({
    queryKey: ["matches"],
    queryFn: () => {
      return getMatches();
    },
  });
  const deleteMatchMutation = useMutation({
    mutationFn: deleteMatch,
    onSuccess: () => {
      queryClient.invalidateQueries("matches");
      setSnackbar({
        open: true,
        message: "Match deleted successfully!",
        severity: "success",
      });
    },
    onError: () => {
      setSnackbar({
        open: true,
        message: t("Error! Couldn't delete the match."),
        severity: "error",
      });
    },
  });

  if (isPending) {
    return <LoadingAnimation />;
  }
  if (error) {
    return <h1>OPS! An error occured.</h1>;
  }

  console.log(data);

  return (
    <Container>
      <h1 className="mt-5"> Matches' Table</h1>
      <div className="d-flex justify-content-between mt-5 mb-2">
        <div></div>
        <div>
          <Link to={"add-new"}>
            <Button variant="contained">
              Add a New Match <AddIcon />
            </Button>
          </Link>
        </div>
      </div>
      <CustomTable
        columns={[
          { headerName: "Match" },
          { headerName: "Category" },
          { headerName: "Match Stage" },
          { headerName: "Stadium" },
          { headerName: "Date" },
        ]}
        rows={data.map((eachMatch) => ({
          matchName: `${
            eachMatch.teamOne.team
              ? eachMatch.teamOne.team.name.en
              : eachMatch.teamOne.nationalTeam.name.en
          } VS ${
            eachMatch.teamTwo.team
              ? eachMatch.teamTwo.team.name.en
              : eachMatch.teamTwo.nationalTeam.name.en
          } (${eachMatch.teamOne.goals} ${
            eachMatch.teamOne.penaltyGoals && eachMatch.teamOne.penaltyGoals > -1
              ? "(" + eachMatch.teamOne.penaltyGoals + ")"
              : ""
          } - ${eachMatch.teamTwo.goals}${
            eachMatch.teamTwo.penaltyGoals && eachMatch.teamTwo.penaltyGoals > -1
              ? " (" + eachMatch.teamTwo.penaltyGoals + ")"
              : ""
          })`,
          categoryName: eachMatch.category.name.en,
          matachStage: eachMatch.matchStage.name.en,
          stadium: eachMatch.stadium.name.en,
          date: new Date(eachMatch.matchDate).toDateString(),
          _id: eachMatch._id,
        }))}
        handleDelete={(id) => {
          deleteMatchMutation.mutate(id);
        }}
        handleDetailedView={(matchId) => {
          setOpen(true);
          setMatchToView(data.find((eachMatch) => eachMatch._id == matchId));
        }}
        pageName={"matchPage"}
        // getRowId={(row) => row._id}//must be unique
      />
      <Toast
        handleSnackbarClose={() => {
          setSnackbar({ open: false, message: "" });
        }}
        snackbar={snackbar}
      />

      <DetailedMatchViewModal
        open={open}
        setOpen={setOpen}
        matchObj={matchToView}
      />
    </Container>
  );
}
