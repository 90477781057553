import React, { useEffect, useState } from "react";
import { getPlayers } from "../../services/api/player";
import { jaroDistanceSearch } from "../../services/helperFunctions";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const storageName = "wheredTheyDuod";
const getCachedDuo = () => {
  return JSON.parse(localStorage.getItem(storageName));
};

const saveToCache = (type, payload) => {
  if (type === "NEW_DUO") {
    localStorage.setItem(
      storageName,
      JSON.stringify({
        playerOne: payload.playerOne,
        playerTwo: payload.playerTwo,
        teamsPlayedFor: payload.teamsPlayedFor,
        teams: payload.teams,
      })
    );
  } else if (type === "ANOTHER_GAME") {
    localStorage.removeItem(storageName);
  } else if (type === "SAVE_USER_ANSWER") {
    console.log("CAllededded?!!!");

    const temp = getCachedDuo();
    localStorage.setItem(
      storageName,
      JSON.stringify({ ...temp, userFinalAnswer: payload.userFinalAnswer })
    );
  }
};

export default function useWheredTheyDuodGameModeHook(language) {
  const [twoPlayerObjAndTeams, setTwoPlayerObjAndTeams] = useState();
  const [teams, setTeams] = useState();

  const returnSuggestTeams = (query) => {
    const keys = Object.keys(twoPlayerObjAndTeams.teamsPlayedFor);
    return jaroDistanceSearch(
      [
        ...teams.map((et) => et.name[language.lng]),
        ...keys.map(
          (ek) => twoPlayerObjAndTeams.teamsPlayedFor[ek].name[language.lng]
        ),
      ],
      query
    ).splice(0, 5);
  };

  useEffect(() => {
    sleep(2000).then(() => {
      const cachedDuo = getCachedDuo();
      if (cachedDuo && cachedDuo.playerOne) {
        setTwoPlayerObjAndTeams({
          playerOne: cachedDuo.playerOne,
          playerTwo: cachedDuo.playerTwo,
          teamsPlayedFor: cachedDuo.teamsPlayedFor,
        });
        setTeams(cachedDuo.teams);
      } else {
        const fetchForWheredTheyDuodMode = async () => {
          const obj = await getPlayers(
            undefined,
            undefined,
            undefined,
            undefined,
            true
          );
          setTwoPlayerObjAndTeams({
            playerOne: obj.playerOne,
            playerTwo: obj.playerTwo,
            teamsPlayedFor: obj.teamsPlayedFor,
          });
          setTeams(obj.randomTeams);
          // cache
          saveToCache("NEW_DUO", {
            playerOne: obj.playerOne,
            playerTwo: obj.playerTwo,
            teamsPlayedFor: obj.teamsPlayedFor,
            teams: obj.randomTeams,
          });
        };
        fetchForWheredTheyDuodMode();
      }
    });
  }, []);

  return {
    isLoading: !twoPlayerObjAndTeams && !teams,
    twoPlayerObjAndTeams,
    returnSuggestTeams,
    saveToCache,
    getCachedDuo,
  };
}
