import { baseServerUrl } from "./globalVars";
import { getFBToken } from "./auth";

export const getNationalTeams = async () => {
  const token = await getFBToken();
  const res = await fetch(`${baseServerUrl}/nationalTeam`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return await res.json();
};

// export const addNewNationalTeam = async (teamObj) => {
//   const response = await fetch(`${baseServerUrl}/team`, {
//     method: "POST",
//     headers: { "Content-Type": "application/json" },
//     body: JSON.stringify(teamObj),
//   });
//   return response.data;
// };

// export const deleteNationalTeam = async (id) => {
//   const response = await fetch(`${baseServerUrl}/team/${id}`, {
//     method: "DELETE",
//     headers: { "Content-Type": "application/json" },
//   });
//   return response.data;
// };

// export const editTeam = async (obj) => {
//   const response = await fetch(`${baseServerUrl}/team/${obj._id}`, {
//     method: "PUT",
//     headers: { "Content-Type": "application/json" },
//     body: JSON.stringify(obj.updatedTeam),
//   });
//   return response.data;
// };
