import createDataContext from "./createDataContext";
// import { languageResources } from "../services/i18next"; //this contains the list of supported languages
const defaultTableOptions = {
  rowsPerPage: 5,
  page: 0,
  searchString: "",
  selectedCountry: "",
  currentSelectedFilterType: "search",
};

const userReducer = (state, action) => {
  switch (action.type) {
    case "set_user_table_options":
      return {
        ...state,
        [action.payload.pageName]: {
          ...state[action.payload.pageName],
          ...action.payload.tableOptions,
        },
      };
    case "set_search_string":
      return {
        ...state,
        [action.payload.pageName]: {
          ...state[action.payload.pageName],
          searchString: action.payload.searchString,
          selectedCountry: "",
          page: 0,
        },
      };
    case "set_selected_country":
      return {
        ...state,
        [action.payload.pageName]: {
          ...state[action.payload.pageName],
          searchString: "",
          selectedCountry: action.payload.selectedCountry,
          page: 0,
        },
      };
    case "set_current_filter_type":
      return {
        ...state,
        [action.payload.pageName]: {
          ...state[action.payload.pageName],
          currentSelectedFilterType: action.payload.filterType,
          page: 0,
        },
      };
    default:
      return state;
  }
};

const setUserTableOptionsForPage = (dispatch) => {
  return (pageName, options) => {
    dispatch({
      type: "set_user_table_options",
      payload: { pageName, tableOptions: options },
    });
  };
};

const setSearchString = (dispatch) => {
  return (pageName, searchString) => {
    dispatch({
      type: "set_search_string",
      payload: { searchString, pageName },
    });
  };
};

const setSelectedCountry = (dispatch) => {
  return (pageName, selectedCountry) => {
    dispatch({
      type: "set_selected_country",
      payload: { selectedCountry, pageName },
    });
  };
};

const setCurrentFilterType = (dispatch) => {
  return (pageName, filterType) => {
    dispatch({
      type: "set_current_filter_type",
      payload: { filterType, pageName },
    });
  };
};

export const { Context, Provider } = createDataContext(
  userReducer,
  {
    setUserTableOptionsForPage,
    setSearchString,
    setSelectedCountry,
    setCurrentFilterType,
  },
  {
    categoryPage: defaultTableOptions,
    teamPage: defaultTableOptions,
    playerPage: defaultTableOptions,
    matchPage: defaultTableOptions,
  }
);
