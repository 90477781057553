import React from "react";
import Lottie from "react-lottie";
import celebrationData from "../../../lottie/celebrationAni.json";

export default function CelebrationAnimation({ isShowAni, handleAnimationDone }) {
  if (!isShowAni) {
    return <></>;
  }

  return (
    <div style={{ position:"absolute", top: 0, left:0, width:"100%", height:"100%" }}>
      <Lottie
        options={{
          loop: false,
          autoplay: true,
          animationData: celebrationData,
          rendererSettings: { duration: 6 },
        }}
        height={"100%"}
        width={"100%"}
        speed={0.7}
        isClickToPauseDisabled
        eventListeners={[
          {
            eventName: "complete",
            callback: handleAnimationDone,
          },
        ]}
      />
    </div>
  );
}
