import React, { useState, useContext } from "react";
import { ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { CacheProvider } from "@emotion/react";
import { Context as UserContext } from "../context/UserContext";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import LanguageIcon from "@mui/icons-material/Language";
import TranslationModal from "./TranslationModal";
import useMuiDirChangeHook from "../hooks/useMuiDirChangeHook";

export default function TextInputWithTranslationModal({
  iconComponent,
  labelI18Key,
  formik,
  fieldName,
}) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    state: { language },
  } = useContext(UserContext);
  const {theme,cacheRtl} = useMuiDirChangeHook(language);

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <TranslationModal
          isOpen={isModalOpen}
          handleClose={() => {
            setIsModalOpen(false);
          }}
          labelI18Key={labelI18Key}
          iconComponent={iconComponent}
          fieldName={fieldName}
          formik={formik}
        />
        <TextField
          label={isModalOpen ? "" : t(labelI18Key)}
          name={`${fieldName}.${language.lng}`}
          error={
            formik.errors[fieldName] && formik.errors[fieldName][language.lng]
              ? true
              : false
          }
          value={formik.values[fieldName][language.lng]}
          onChange={formik.handleChange}
          sx={{ m: 1, width: "100%" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{iconComponent}</InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                >
                  <LanguageIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </ThemeProvider>
    </CacheProvider>
  );
}
