import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { titleMenuAnimation } from "../../services/gsapFunctions";

export default function useHintsGsapHook(hints) {
  useGSAP(() => {
    if (hints.length == 0) {
      console.log("Skipping animation due to loading or empty chips.");
      return;
    }
    titleMenuAnimation();

    gsap.fromTo(
      ".parent-div",
      {
        opacity: 0,
      },
      { opacity: 1, ease: "back.inOut", delay: 1, duration: 2 }
    );

    gsap.fromTo(
      ".chip-for-ani",
      {
        opacity: 0,
      },
      { opacity: 1, ease: "back.inOut", delay: 2, stagger: 0.3 }
    );
    gsap.fromTo(
      ".button-div-for-ani",
      {
        opacity: 0,
      },
      {
        opacity: 1,
        ease: "expo",
        delay: 4,
        duration: 2,
      }
    );
  }, [hints]);

  return {};
}
