import Button from "@mui/material/Button";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function SingleplayerHome() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <section className="container">
      <h1 className="text-center mt-5">{t("play.singlePlayerPage.modes")}</h1>
      <div className="d-flex flex-column align-items-center mt-5 gap-4">
        <Button
          variant="contained"
          sx={{ width: 200 }}
          onClick={() => {
            navigate("hints");
          }}
        >
          {t("play.singlePlayerPage.hints")}
        </Button>
        {/* <Button variant="contained" sx={{width:200}}> Hints</Button> */}
      </div>
    </section>
  );
}
