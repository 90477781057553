import React, { useState, useEffect } from "react";
import "../../../styles/buttonStyles.css";
import "../../../styles/modalStyle.css";
import { commonBackgroundColorAndBorder } from "../../../services/styles";
import SoccerFieldWithPlayers from "../../../components/Match/SoccerFieldWithPlayers";
import useLineupsGameModeHook from "../../../hooks/Game/useLineupsGameModeHook";
import BallLoading from "../../../components/BallLoading";
import GuessPlayerModal from "../../../components/GameHome/HintsGameMode/GuessPlayerModal";
import "../../../styles/Game/fillTheLineup.css";
import MatchInfo from "../../../components/GameHome/fillTheLineup/MatchInfo";
import CelebrationAnimation from "../../../components/GameHome/fillTheLineup/CelebrationAnimation";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CommonQuickAccessMenu from "../../../components/GameHome/CommonQuickAccessMenu";
import useLineupGsapHook from "../../../hooks/Game/useLineupGsapHook";
import { useTranslation } from "react-i18next";

export default function FillTheLineup() {
  const { t } = useTranslation();
  const {
    language,
    matchObj,
    teamToFillNo,
    userAnswers,
    openPlayerGuessModal,
    openMatchInfoModal,
    setOpenPlayerGuessModal,
    setOpenMatchInfoModal,
    suggestedPlayers,
    otherPlayers,
    userPlayerQuery,
    setUserPlayerQuery,
    bubbleChat,
    checkUserAnswerCorrect,
    checkUserWonGame,
    showCelebrationAnimation,
    userFinalAnswerForCurrentPlayerToGuess,
    setUserFinalAnswerForCurrentPlayerToGuess,
    matchInfoModalWidthSizeThreshold,
    anotherMatch,
    dispatch,
    ACTIONS,
  } = useLineupsGameModeHook();
  const isLoading = !matchObj || !teamToFillNo || !userAnswers || !otherPlayers;
  useLineupGsapHook(
    isLoading,
    matchInfoModalWidthSizeThreshold,
    openMatchInfoModal,
    language.dir
  );

  if (isLoading) {
    return <BallLoading />;
  }

  return (
    <div
      id="fill-the-lineup"
      className="container"
      style={{ direction: language.dir, overflow: "hidden" }}
    >
      <div
        className="title-menu-div"
        style={{
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          marginTop: 20,
        }}
      >
        <h2
          className=" px-3 px-md-5"
          style={{ ...commonBackgroundColorAndBorder }}
        >
          {language.lng == "ar"
            ? `${t(
                "play.singlePlayerPage.fillTheLineupGameMode.fillPlusLineup"
              )}`
            : `${t("play.singlePlayerPage.fillTheLineupGameMode.fill")} ${
                matchObj[`team${teamToFillNo}`].team.name[language.lng]
              }'s
          ${t("play.singlePlayerPage.fillTheLineupGameMode.lineup")}`}
        </h2>
        {/* <p className="m-0 text-white">Test</p> */}
        <CommonQuickAccessMenu
          handleNewGame={anotherMatch}
          newMatchName={t("play.singlePlayerPage.quickAccessMenu.fullLineup")}
        />
      </div>

      <div
        className="parent-div d-flex gap-3 mt-2 mt-md-5"
        style={{ padding: language.dir == "ltr"?"0 5px 0 0": "0 0 0 5px"}}
      >
        {/* Left side is matchInfo */}
        <div
          className={`left-side col-lg-6 ${
            openMatchInfoModal ? "show" : "not-show"
          }`}
          style={
            openMatchInfoModal
              ? {
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  top: 0,
                  left: 0,
                  backgroundColor: "rgba(0,0,0,0.5)",
                  zIndex: 100,
                }
              : null
          }
          onClick={() => {
            setOpenMatchInfoModal(false);
          }}
        >
          <MatchInfo
            matchObj={matchObj}
            language={language}
            openMatchInfoModal={openMatchInfoModal}
            setOpenMatchInfoModal={setOpenMatchInfoModal}
            showButtonsWhenUserWon={checkUserWonGame()}
          />
        </div>
        <div
          className="right-side col-12 col-lg-6"
          style={{ ...commonBackgroundColorAndBorder }}
        >
          {/* right side */}
          <div className="d-flex justify-content-between mb-1">
            <p className="mb-0">
              {t(
                "play.singlePlayerPage.fillTheLineupGameMode.textAboveSoccerField"
              )}{" "}
              {matchObj[`team${teamToFillNo}`].team.name[language.lng]}
            </p>
            <p className="mb-0">{matchObj[`team${teamToFillNo}`].lineup}</p>
          </div>

          <SoccerFieldWithPlayers
            teamLineup={matchObj[`team${teamToFillNo}`].lineup}
            handlePlayerClick={(rowAndColObj) => {
              setOpenPlayerGuessModal(true);
              dispatch({
                type: ACTIONS.CHANGE_CURRENT_PLAYER_TO_GUESS,
                payload: { currentPlayerToGuess: rowAndColObj },
              });
            }}
            players={userAnswers}
          />
          <div className="mt-2 bottom-div-of-soccer-field">
            <p className="m-0">
              {t("play.singlePlayerPage.fillTheLineupGameMode.manager")}:{" "}
              {matchObj[`team${teamToFillNo}`].coach.name[language.lng]}
            </p>
            <p
              className="m-0 text-center match-info"
              onClick={() => {
                setOpenMatchInfoModal(true);
              }}
            >
              Match Info <HelpOutlineIcon style={{ fontSize: 25 }} />
            </p>
          </div>
        </div>
      </div>
      <GuessPlayerModal
        open={openPlayerGuessModal}
        setOpen={setOpenPlayerGuessModal}
        handleSubmission={() => {}}
        suggestedPlayers={suggestedPlayers}
        userPlayerQuery={userPlayerQuery}
        setUserPlayerQuery={setUserPlayerQuery}
        isAnsCorrect={checkUserAnswerCorrect()}
        userFinalAnswer={userFinalAnswerForCurrentPlayerToGuess}
        setUserFinalAnswer={setUserFinalAnswerForCurrentPlayerToGuess}
        fromFillTheLineupMode={true}
        customBubbleChatText={bubbleChat}
      />
      <CelebrationAnimation
        handleAnimationDone={() => {
          dispatch({
            type: ACTIONS.UPDATE_SHOW_CELEBRTAION_ANIMATION,
            payload: false,
          });
          window.innerWidth <= matchInfoModalWidthSizeThreshold &&
            setOpenMatchInfoModal(true);
        }}
        isShowAni={showCelebrationAnimation}
      />
    </div>
  );
}
