import Container  from '@mui/material/Container';
import React from 'react'
import { useTranslation } from 'react-i18next'
import TeamForm from '../../components/Team/TeamForm';

export default function AddNewTeam() {
    const {t} = useTranslation();
  return (

    <Container>
        <h1 className='display-4 mt-4 mb-5'>
            {t("teamPage.add")}
        </h1>
        <TeamForm />
    </Container>
  )
}
