import React, { useContext } from "react";
import { languageResources } from "../../services/i18next";
import { Context as UserContext } from "../../context/UserContext";
import { useTranslation } from "react-i18next";
import "../../styles/modalStyle.css";
import "../../styles/buttonStyles.css";
import { storageName as storageNameHints } from "../../hooks/Game/useHintsGameModeHook";
import { storageName as storageNameFillLineup } from "../../hooks/Game/useLineupsGameModeHook";
import { storageName as storageNameWheredTheyDuod } from "../../hooks/Game/useWheredTheyDuodGameModeHook";

const clearCacheOnCacheLanguage = () => {
  //We need to clear cache when language is changed because the stored data will be in different language.
  [storageNameHints, storageNameFillLineup, storageNameWheredTheyDuod].forEach(
    (eSN) => {
      localStorage.removeItem(eSN);
    }
  );
};

export default function LanguageSelectorModal({ open, setOpen }) {
  const { t } = useTranslation();
  const { setLanguage } = useContext(UserContext);
  return (
    <div
      style={
        open
          ? {
              position: "absolute",
              height: "100%",
              width: "100%",
              top: 0,
              left: 0,
              backgroundColor: "rgba(0,0,0,0.5)",
            }
          : null
      }
      onClick={() => {
        setOpen(false);
      }}
    >
      <div
        className={`guess-player-modal ${open ? "show" : ""}`}
        style={{ maxWidth: 700, padding: "60px 30px" }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div>
          <h2>{t("play.languageSelector.welcome")}</h2>
          <p style={{ marginTop: 0, fontSize: "19px", fontStyle: "italic" }}>
            {t("play.languageSelector.choose")}
          </p>
        </div>

        <div className="mt-5 d-flex flex-column gap-4">
          {Object.keys(languageResources).map((eL, idx) => (
            <button
              // variant="contained"
              className="style-one-button"
              key={idx}
              style={{ fontSize: 25 }}
              onClick={() => {
                setLanguage(eL);
                clearCacheOnCacheLanguage();
                window.location.reload();
              }}
            >
              {languageResources[eL].text[eL]}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
