import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "@mui/material";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getPlayers, deletePlayer } from "../../services/api/player";
import { getNationalTeams } from "../../services/api/nationalTeams";
import LoadingAnimation from "../../components/LoadingAnimation";
import CustomTable from "../../components/CustomTable";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import Toast from "../../components/Toast";
import * as Flags from "country-flag-icons/react/3x2";
import { countries } from "country-flag-icons";
import { Context as UserContext } from "../../context/UserContext";
import { Context as TableOptionsContext } from "../../context/TablePageOptions";
import EnglandFlag from "../../icons/extraFlags/gb-eng";
import Search from "../../components/Search";
import VerticalRadioGroup from "../../components/Player/VerticalRadioGroup";
import CountrySelector from "../../components/CountrySelector";
import WalesFlag from "../../icons/extraFlags/gb-wls";
import DetailedPlayerViewModal from "../../components/Player/DetailedPlayerViewModal";
import ScotlandFlag from "../../icons/extraFlags/gb-sct";
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';

export default function Player() {
  const { t } = useTranslation();
  const {
    state: { language },
  } = useContext(UserContext);
  const { state, setCurrentFilterType } = useContext(TableOptionsContext);
  // const [searchType, setSearchType] = useState(
  //   state["playerPage"].currentSelectedFilterType
  //     ? state["playerPage"].currentSelectedFilterType
  //     : "search"
  // ); //search OR countrySelector
  const [open, setOpen] = useState(false);
  const [playerToView, setPlayerToView] = useState(undefined);
  const handleOpen = () => setOpen(true);
  const queryClient = useQueryClient();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const { isPending, error, data } = useQuery({
    queryKey: [
      "players",
      `${
        state["playerPage"].currentSelectedFilterType == "search"
          ? state["playerPage"].searchString
          : state["playerPage"].selectedCountry
      }`,
    ],
    queryFn: () => {
      return state["playerPage"].currentSelectedFilterType == "search"
        ? getPlayers(state["playerPage"].searchString)
        : getPlayers(undefined, undefined, state["playerPage"].selectedCountry);
    },
  });
  const {
    isPending: isPendingNationalTeams,
    error: isErrorNationalTeams,
    data: nationalTeams,
  } = useQuery({
    queryKey: ["nationalTeams"],
    queryFn: getNationalTeams,
  });
  const deletePlayerMutation = useMutation({
    mutationFn: deletePlayer,
    onSuccess: () => {
      queryClient.invalidateQueries("players");
      setSnackbar({
        open: true,
        message: t("playerPage.success.delete"),
        severity: "success",
      });
    },
    onError: () => {
      setSnackbar({
        open: true,
        message: t("playerPage.errors.delete"),
        severity: "error",
      });
    },
  });
  // const {
  //   isPending: isPendingTeams,
  //   error: errorTeams,
  //   data: teamsData,
  // } = useQuery({
  //   queryKey: ["teams"],
  //   queryFn: getTeams,
  // });
  if (error || isErrorNationalTeams ) {
    return <h1>OPS, an error occurred!</h1>;
  }
  if (isPending || isPendingNationalTeams ) {
    return <LoadingAnimation />;
  }
  return (
    <Container>
      <h1 className="mt-5"> {t("playerPage.title")}</h1>
      <div className="d-flex justify-content-between mt-5 mb-2 align-items-end">
        <div className="d-flex align-items-end gap-4">
          <VerticalRadioGroup
            searchType={state["playerPage"].currentSelectedFilterType}
            setCurrentFilterType={setCurrentFilterType}
          />
          {state["playerPage"].currentSelectedFilterType == "search" && (
            <Search pageName={"playerPage"} />
          )}
          {state["playerPage"].currentSelectedFilterType ==
            "countrySelector" && <CountrySelector pageName={"playerPage"} />}
        </div>
        <div>
          <Link to={"add-new"}>
            <Button variant="contained">
              {t("playerPage.add")} <AddIcon />
            </Button>
          </Link>
        </div>
      </div>
      <CustomTable
        columns={[
          { headerName: t("playerPage.columns.item1") },
          { headerName: t("playerPage.columns.item2") },
          { headerName: t("playerPage.columns.item3") },
          { headerName: t("playerPage.columns.item5") },
          // {
          //   headerName:
          //     t("playerPage.columns.item4") +
          //     " " +
          //     (new Date().getFullYear() - 1).toLocaleString().slice(3, 5) +
          //     "/" +
          //     new Date().getFullYear().toLocaleString().slice(3, 5),
          // },
        ]}
        rows={data.map((eachCat) => {
          let Flag = undefined;
          if (countries.includes(eachCat.country && eachCat.country.alpha_2))
            Flag = Flags[eachCat.country.alpha_2];
          else if (eachCat.country && eachCat.country.alpha_2 === "ENGLAND")
            Flag = EnglandFlag;
          else if (eachCat.country && eachCat.country.alpha_2 === "WALES")
            Flag = WalesFlag;
          else if (eachCat.country && eachCat.country.alpha_2 === "SCOTLAND")
            Flag = ScotlandFlag;
          return {
            playerName: eachCat.name.en,
            playerNameAr: eachCat.name.ar,
            country: (
              <div
                className=""
                style={{ display: "flex", alignItems: "center", gap: 10 }}
              >
                {Flag && <Flag style={{ width: 40, height: 40 }} />}
                {nationalTeams.find(
                  (eachNt) =>
                    eachNt.alpha_2 ==
                    (eachCat.country && eachCat.country.alpha_2)
                )
                  ? nationalTeams.find(
                      (eachNt) => eachNt.alpha_2 == eachCat.country.alpha_2
                    ).name[language.lng]
                  : ""}
              </div>
            ),
            fbrefData: eachCat.fbref && eachCat.fbref.pi  ? <CheckIcon sx={{color:'green'}}/>: <ClearIcon sx={{color:'red'}}/>,
            _id: eachCat._id,
            // currentTeam:
            //   eachCat.teams.length > 0 ? (
            //     <div>
            //       <span>
            //         {" "}
            //         {
            //           teamsData.find(
            //             (eachTeam) =>
            //               eachCat.teams[eachCat.teams.length - 1].teamId ==
            //               eachTeam._id
            //           ).name[language.lng]
            //         }
            //       </span>
            //     </div>
            //   ) : null,
          };
        })}
        handleDelete={(id) => {
          deletePlayerMutation.mutate(id);
        }}
        pageName={"playerPage"}
        // getRowId={(row) => row._id}//must be unique
        handleDetailedView={(playerId) => {
          handleOpen();
          setPlayerToView(
            data.find((eachPlayer) => eachPlayer._id == playerId)
          );
        }}
      />
      <Toast
        handleSnackbarClose={() => {
          setSnackbar({ open: false, message: "" });
        }}
        snackbar={snackbar}
      />
      <DetailedPlayerViewModal
        open={open}
        setOpen={setOpen}
        playerToView={playerToView}
      />
    </Container>
  );
}
