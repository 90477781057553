import { playPagesColors } from "./colors";
export const commonBackgroundColorAndBorder = {
  display: "inline-block",
  padding: 15,
  borderWidth: 5,
  borderStyle: "solid",
  borderColor: playPagesColors.primary,
  borderRadius: 30,
  backgroundColor: "rgb(16 51 90 / 79%)",
  color: "white",
};
