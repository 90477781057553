import React, {useContext} from "react";
import { emphasize, styled } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";
import { Context as UserContext } from "../../context/UserContext";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}
//data is the formik.values.teams -> [{teamId, season, onLoan}, ...]
export default function CustomizedBreadcrumbs({ data, handleDelete, teams }) {
  const {t} = useTranslation();
  const {state:{language}} = useContext(UserContext);
  // const playerTeamsKeys = Object.keys(data).sort((a, b) => a.localeCompare(b)); // this array will contain the seasons. -> e.g., "2023-2024"

  return (
    <div role="presentation" className="mt-5">
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<ChevronRightIcon fontSize="large" sx={{ marginTop: 1 }} />}
        className="d-flex flex-wrap gap-5"
      >
        {data.length > 0 ? (
          data.map((each, idx) => (
            <StyledBreadcrumb
              sx={{ marginTop: 1, padding:2 }}
              key={idx}
              label={
                <span>
                  {each.season} -{" "}
                   {
                     teams.find((eachTeam) => each.teamId == eachTeam._id)
                      .name[language.lng]
                  }
                  {each.onLoan? `- ${t("playerPage.loan")}`: ""}
                </span>
              }
              icon={<SportsSoccerIcon fontSize="small" />}
              deleteIcon={
                <ClearIcon color="red" sx={{ color: "red !important" }} />
              }
              onDelete={() => {
                handleDelete( idx);
              }}
            />
          ))
        ) : (
          <p className="display-6 lead text">
            {t("playerPage.playerCareerEmpty")}
          </p>
        )}
      </Breadcrumbs>
    </div>
  );
}
