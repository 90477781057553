import React from "react";
import Lottie from "react-lottie";
import animationData from "../lottie/rollingball.json";
import { playPagesColors } from "../services/colors";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice",
  //   },
};
export default function BallLoading() {
  return (
    <div style={{ marginTop: 100, display: "flex",
    justifyContent: "center",
    alignItems: "center", }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: 'rgb(25 118 210 / 30%)',
          width: 130,
          height: 130,
          borderRadius: 400,
        }}
      >
        <Lottie
          options={defaultOptions}
          height={100}
          width={100}
          isClickToPauseDisabled
        />
      </div>
    </div>
  );
}
