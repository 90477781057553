import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PersonIcon from "@mui/icons-material/Person";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import CategoryIcon from "@mui/icons-material/Category";
import { createTheme } from "@mui/material/styles";
import { enUS, arSD } from "@mui/x-data-grid";
import { Context as UserContext } from "../context/UserContext";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";
import { ThemeProvider } from "@emotion/react";
import GroupsIcon from "@mui/icons-material/Groups";
import Avatar from "@mui/material/Avatar";
import { globalColors } from "../services/colors";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import { signOut } from "firebase/auth";
import { auth } from "../config/firebase";

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const MainShouldFrowardFunc = ({ theme, open }, lng) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: lng === "en" ? `-${drawerWidth}px` : "0px",
  marginRight: lng === "ar" ? `-${drawerWidth}px` : "0px",
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    marginRight: 0,
  }),
});
const AppBarShouldForwardFunc = ({ theme, open }, lng) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: lng === "en" ? `${drawerWidth}px` : "0px",
    marginRight: lng === "ar" ? `${drawerWidth}px` : "0px",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
});

export default function PersistentDrawerLeft({ children }) {
  const { t } = useTranslation();

  const {
    state: { language, user },
    setLanguage,
  } = React.useContext(UserContext);

  const [open, setOpen] = React.useState(false);
  const [openAvatarOptionsDiv, setOpenAvatarOptionsDiv] = React.useState(false);

  const [Main, setMain] = React.useState(
    styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
      ({ theme, open }) => MainShouldFrowardFunc({ theme, open }, language.lng)
    )
  );
  const [AppBar, setAppBar] = React.useState(
    styled(MuiAppBar, {
      shouldForwardProp: (prop) => prop !== "open",
    })(({ theme, open }) =>
      AppBarShouldForwardFunc({ theme, open }, language.lng)
    )
  );
  const [theme, setTheme] = React.useState(
    createTheme(localStorage.getItem("language") === "en" ? enUS : arSD)
  );

  React.useEffect(() => {
    //set the language in localstorage & update user context state.
    //if the language was already set, then update context only.
    setLanguage();
  }, []);
  React.useEffect(() => {
    setAppBar(
      styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== "open",
      })(({ theme, open }) =>
        AppBarShouldForwardFunc({ theme, open }, language.lng)
      )
    );
    setMain(
      styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
        ({ theme, open }) =>
          MainShouldFrowardFunc({ theme, open }, language.lng)
      )
    );
  }, [language]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLanguageChange = () => {
    setLanguage(language.lng === "en" ? "ar" : "en");
    changeLanguage(language.lng === "en" ? "ar" : "en");
    setTheme(createTheme(language.lng === "en" ? arSD : enUS));
  };

  const signOutFromGoogle = async () => {
    try {
      await signOut(auth);
      console.log("User signed out successfully");
      window.location.reload();
    } catch (error) {
      console.log("ERROR signout: ", error);
    }
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          {user.token && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <div
            className="d-flex align-items-center justify-content-between w-100"
            style={{ marginRight: language.dir === "rtl" ? 20 : null }}
          >
            <Typography variant="h6" noWrap component="div">
              {t("header.title")}
            </Typography>

            <div className="d-flex gap-3 align-items-center">
              <button
                className="btn text-white"
                onClick={() => {
                  handleLanguageChange();
                }}
              >
                <Typography variant="h6">
                  {language.lng === "en" ? "عربي" : "English"}
                </Typography>
              </button>
              {user.token && (
                <div className="position-relative">
                  <IconButton
                    onClick={() => {
                      setOpenAvatarOptionsDiv((prev) => !prev);
                    }}
                  >
                    <Avatar
                      sx={{ bgcolor: globalColors.secondary, color: "black" }}
                    >
                      {user.name.substring(0, 2)}
                    </Avatar>
                  </IconButton>
                  <div
                    className="shadow position-absolute"
                    style={{
                      width: 200,
                      display: openAvatarOptionsDiv ? "block" : "none",
                      padding: "10px 30px 30px 2px",
                      transform:
                        language.dir === "ltr"
                          ? "translate(-140px, 10px)"
                          : "translate(140px, 10px)",
                      // zIndex: 20,
                      border: "1px solid",
                      // borderColor: globalColors.primary,
                      borderRadius: 10,
                      color: "black",
                    }}
                  >
                    <div className="d-flex align-items-center gap-1">
                      <PersonOutlineIcon /> <Typography>{user.name}</Typography>
                    </div>
                    <div
                      onClick={() => {
                        signOutFromGoogle();
                      }}
                      className="mt-3 ps-1"
                      style={{ cursor: "pointer" }}
                    >
                      <LogoutIcon /> {t("sidebar.avatarDiv.signOut")}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor={language.align}
        open={open}
      >
        <DrawerHeader>
          <div
            style={{
              position: "absolute",
              left: language.dir === "ltr" ? 10 : null,
              right: language.dir === "rtl" ? 10 : null,
              top: 20,
            }}
            className="font-monospace"
          >
            <p>{t("sidebar.title")}</p>
          </div>
          <IconButton onClick={handleDrawerClose}>
            {language.dir === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {/* rgba(0, 0, 0, 0.04); */}
          {[
            {
              sidebarItemText: t("sidebar.items.item1"),
              href: "/fbk-admin/player",
              icon: <PersonIcon />,
            },
            {
              sidebarItemText: t("sidebar.items.item2"),
              href: "/fbk-admin/team",
              icon: <SportsSoccerIcon />,
            },
            {
              sidebarItemText: t("sidebar.items.item3"),
              href: "/fbk-admin/category",
              icon: <CategoryIcon />,
            },
            {
              sidebarItemText: t("sidebar.items.item4"),
              href: "/fbk-admin/match",
              icon: <GroupsIcon />,
            },
          ].map((eachItem, idx) => {
            return (
              <ListItem key={idx} disablePadding>
                <ListItemButton>
                  <Link
                    to={eachItem.href}
                    style={{ textDecoration: "none", display: "flex" }}
                  >
                    <ListItemIcon>{eachItem.icon}</ListItemIcon>
                    <ListItemText
                      primary={eachItem.sidebarItemText}
                      style={{ color: "black" }}
                    />
                  </Link>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        <Divider />
        {/* <List>
          {["All mail", "Trash", "Spam"].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <PersonIcon /> : <PersonIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </Main>
    </Box>
  );
}
