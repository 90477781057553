export const lineups = [
  "4-3-3",
  "4-3-1-2",
  "4-4-2",
  "5-4-1",
  "4-2-3-1",
  "4-2-2-2",
  "3-4-2-1",
  "3-5-2",
  "4-5-1",
  "4-1-4-1",
  "3-4-1-2",
  "3-4-3",
  "3-5-1-1",
  "4-1-2-1-2",
  "4-2-4",
  "4-4-1-1",
  "5-3-2",
  "5-2-1-2",
  "5-1-2-2",
];

export const categoriesThatAreNational = [
  "World Cup",
  "Asian Cup",
  "AFCON",
  "EURO",
  "Arab Cup",
];

export const reshapePlayers = (lineup) => {
  const players = [];
  players.push([null]);
  lineup.split("-").forEach((numberOfPlayersInRow) => {
    players.push(Array(Number(numberOfPlayersInRow)).fill(null));
  });
  return players;
};
