import React, { useContext } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";
import { Context as UserContext } from "../context/UserContext";
import useMuiDirChangeHook from "../hooks/useMuiDirChangeHook";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";

const year = new Date().getFullYear();
const seasons = [];
for (let i = year; i >= 1951; i--) seasons.push(`${i - 1}/${i}`);

export default function SeasonTeamSelector({
  formik,
  currentSeasonTeamSelected,
  setCurrentSeasonTeamSelected,
  data,
}) {
  const { t } = useTranslation();
  const {
    state: { language },
  } = useContext(UserContext);
  const { theme, cacheRtl } = useMuiDirChangeHook(language);

  const handleAddSeason = () => {
    const career = formik.values.teams;
    let idxToAdd;
    for (let i = 0; i < career.length; i++) {
      //3 cases, ==0, > 0, < 0
      if (
        currentSeasonTeamSelected.season.localeCompare(career[i].season) > 0
      ) {
        idxToAdd = i + 1;
      } else if (
        currentSeasonTeamSelected.season.localeCompare(career[i].season) < 0
      ) {
        idxToAdd = i;
        break;
      }
    }
    career.splice(idxToAdd, 0, {
      season: currentSeasonTeamSelected.season,
      teamId: currentSeasonTeamSelected.teamId,
      onLoan: currentSeasonTeamSelected.onLoan,
    });
    formik.setFieldValue("teams", career);
  };

  if (data) {
    return (
      <div className="d-flex gap-3 flex-wrap align-self-sm-center">
        <CacheProvider value={cacheRtl}>
          <ThemeProvider theme={theme}>
            <Autocomplete
              disablePortal
              options={seasons}
              sx={{ width: 300 }}
              value={currentSeasonTeamSelected.season}
              onChange={(e, value) => {
                setCurrentSeasonTeamSelected({
                  ...currentSeasonTeamSelected,
                  season: value,
                });
              }}
              renderInput={(params) => (
                <TextField {...params} label={t("inputLabels.season")} />
              )}
            />
            <Autocomplete
              disablePortal
              options={data.map((eachObj) => ({
                label: eachObj.name[language.lng],
                id: eachObj._id,
              }))}
              // value={currentSeasonTeamSelected.team ? data.find(each=>each._id === currentSeasonTeamSelected.team).name[language.lng]: ""}
              onChange={(e, value) => {
                setCurrentSeasonTeamSelected({
                  ...currentSeasonTeamSelected,
                  teamId: value ? value.id : "",
                });
              }}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label={t("inputLabels.team")} />
              )}
            />
            <FormControlLabel
              control={<Checkbox />}
              label={t("inputLabels.onLoan")}
              onChange={(e, value) => {
                setCurrentSeasonTeamSelected({
                  ...currentSeasonTeamSelected,
                  onLoan: value,
                });
              }}
            />
          </ThemeProvider>
          <Button
            variant="contained"
            size="small"
            sx={{ alignSelf: "center" }}
            disabled={
              !currentSeasonTeamSelected.season ||
              !currentSeasonTeamSelected.teamId
            }
            onClick={() => {
              handleAddSeason();
              // formik.setFieldValue("teams", [
              //   ...formik.values.teams,
              //    {
              //     teamId: currentSeasonTeamSelected.teamId,
              //     onLoan: currentSeasonTeamSelected.onLoan,
              //   },
              // ]);
            }}
          >
            {t("inputLabels.addOrUpdateSeason")}
          </Button>
        </CacheProvider>
      </div>
    );
  }
}
