import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import PublicIcon from "@mui/icons-material/Public";

export default function VerticalRadioGroup({ searchType, setCurrentFilterType }) {
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        value={searchType}
        onChange={(e) => {
          setCurrentFilterType("teamPage", e.target.value)
        }}
      >
        <FormControlLabel
          value="search"
          control={<Radio />}
          label={<SearchIcon />}
        />
        <FormControlLabel
          value="countrySelector"
          control={<Radio />}
          label={<PublicIcon />}
        />
      </RadioGroup>
    </FormControl>
  );
}
