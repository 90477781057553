import React from 'react'
import { Container, Skeleton } from '@mui/material'
export default function LoadingAnimation() {
  return (
    <Container  sx={{ width: 600, marginTop:20 }}>
    <Skeleton />
    <Skeleton />
    <Skeleton />
    <Skeleton animation="wave" />
    <Skeleton animation={false} />
  </Container>
  )
}
