import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { useTranslation } from "react-i18next";
import useMuiDirChangeHook from "../hooks/useMuiDirChangeHook";
import { Context as UserContext } from "../context/UserContext";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Context as TableOptionsContext } from "../context/TablePageOptions";

export default function Search({ pageName }) {
  const { t } = useTranslation();
  const {
    state: { language },
  } = useContext(UserContext);
  const { state, setSearchString } = useContext(TableOptionsContext);

  const { theme, cacheRtl } = useMuiDirChangeHook(language);
  const [localFilter, setLocalFilter] = useState("");
  useEffect(() => {
    //this useEfftect is to save the text the user has inputted before pressing enter.
    //without this, the search input will be cleared when the user presses 'Enter'
    //this fixes the issue
    if (state[pageName].searchString)
      setLocalFilter(state[pageName].searchString);
  }, [state[pageName].searchString]);
  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <TextField
          label={t("inputLabels.search")}
          value={localFilter}
          id="outlined-start-adornment"
          onChange={(e) => {
            setLocalFilter(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key == "Enter") {
              //   setFilter(localFilter);
              setSearchString(pageName, localFilter);
            }
          }}
          sx={{ m: 1, width: "30ch" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span style={{ rotate: language.dir === "ltr" ? 0 : "90deg" }}>
                  <SearchIcon />
                </span>
              </InputAdornment>
            ),
            endAdornment: localFilter && (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setLocalFilter("");
                    setSearchString(pageName, "");
                  }}
                >
                  <span
                    style={{ rotate: language.dir === "ltr" ? 0 : "90deg" }}
                  >
                    <CloseIcon />
                  </span>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </ThemeProvider>
    </CacheProvider>
  );
}
