import React from "react";
import { playPagesColors } from "../../services/colors";
import "../../styles/Game/GameModeCard.css";
import { useNavigate } from "react-router-dom";

export default function GameModeCard({
  text,
  children,
  fontSize,
  link,
  parentStyles,
  textDivStyles,
}) {
  const navigate = useNavigate();
  return (
    <div
      style={{
        width: 379,
        height: 300,
        borderWidth: 10,
        borderStyle: "solid",
        borderColor: playPagesColors.primary,
        borderRadius: 20,
        marginRight: 14,
        marginLeft: 14,
        backgroundColor: "rgba(50,50,50,0.4)",
        display: "flex",
        ...parentStyles,
      }}
      className="card"
      onClick={() => {
        navigate(`single-player/${link}`);
      }}
    >
      {children}
      <div
        style={{
          backgroundColor: "rgb(0 0 0 / 20%)",
          borderBottomLeftRadius: 14,
          borderBottomRightRadius: 14,
          marginBottom: 0,
          marginTop: 4.5,
          height: 100,
          display: "flex",
          alignItems: "center",
          ...textDivStyles,
        }}
      >
        <p
          className="text-center"
          style={{ color: "white", marginBottom: 0, fontSize, flex: 1 }}
        >
          {text}
        </p>
      </div>
    </div>
  );
}
