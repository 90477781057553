import React, { useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

export default function DeleteConfirmationModal({
  modalOpen,
  closeModal,
  handleDelete,
  idToDelete,

}) {
  const { t } = useTranslation();

  return (
    <div>
      {modalOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)", // Background with opacity,
          }}
        >
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "400px",
              height: "170px",
              background: "rgba(255, 255, 255)", // Background with opacity
              borderRadius: "8px",
              padding: "20px",
              color: "#fff", // Text color inside the modal
              zIndex: "1",
              color: "black",
            }}
          >
            <div style={{ position: "absolute", right: 0, top: 5 }}>
              <IconButton
                onClick={() => {
                  closeModal();
                }}
              >
                <HighlightOffIcon style={{ fontSize: 40, color: "red" }} />
              </IconButton>
            </div>
            <br />
            <br />
            <p>{t("table.deleteConfirmation")}</p>
            <div
              style={{
                position: "absolute",
                bottom: 10,
                right: 10,
                display: "flex",
                gap: 10,
              }}
            >
              <Button
                variant="contained"
                sx={{ backgroundColor: "red" }}
                onClick={() => {
                  handleDelete(idToDelete);
                  closeModal();
                }}
              >
                {t("buttons.yes")}
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  closeModal();
                }}
              >
                {t("buttons.cancel")}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
