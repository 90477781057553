import { baseServerUrl } from "./globalVars";
import { getFBToken } from "./auth";
//the _ is an object given by the react query library (we dont need it)
//{$or: [{"name.en": {$regex: /مان/i } }, {"name.ar": {$regex: /مان/i } }]}
export const getTeams = async (searchText, specificTeamId, countryId) => {
  let query = "";
  if (searchText) {
    query = "?search=" + searchText;
  } else if (specificTeamId) {
    query = "?_id=" + specificTeamId;
  } else if (countryId) {
    query = "?country=" + countryId;
  }
  const token = await getFBToken();
  const res = await fetch(`${baseServerUrl}/team${query}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return await res.json();
};

export const addNewTeam = async (teamObj) => {
  const token = await getFBToken();
  const response = await fetch(`${baseServerUrl}/team`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(teamObj),
  });
  return response.data;
};

export const deleteTeam = async (id) => {
  const token = await getFBToken();
  const response = await fetch(`${baseServerUrl}/team/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const editTeam = async (obj) => {
  const token = await getFBToken();
  const response = await fetch(`${baseServerUrl}/team/${obj._id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(obj.updatedTeam),
  });
  return response.data;
};
