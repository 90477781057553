import React, { useState, useEffect, useContext } from "react";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router-dom";
import useTypingAnimationHook from "../../../hooks/Game/useTypingAnimationHook";
import GuessModalButtons from "../GuessModalButtons";
import AnswerAnimation from "../AnswerAnimation";
import { Context as UserContext } from "../../../context/UserContext";
import { useTranslation } from "react-i18next";

export default function GuessPlayerModal({
  open,
  setOpen,
  userPlayerQuery,
  setUserPlayerQuery,
  suggestedPlayers,
  setUserFinalAnswer,
  userFinalAnswer,
  isAnsCorrect,
  handleSubmission,
  fromFillTheLineupMode = false,
  customBubbleChatText = "",
  handleAnotherGameClick
}) {
  const { t } = useTranslation();
  const {
    state: { language },
  } = useContext(UserContext);
  const [text, setText] = useState(
    t("play.singlePlayerPage.hintsGameModePage.hintsText.modal.chatBubbleDef")
  );
  const { currentText, resetText } = useTypingAnimationHook(
    open,
    customBubbleChatText ? customBubbleChatText : text
  );
  const [showAniWhenAnswer, setShowAniWhenAnswer] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (userFinalAnswer && userFinalAnswer.length > 0) {
      setShowAniWhenAnswer(true);
    } else {
      setShowAniWhenAnswer(false);
    }
  }, [userFinalAnswer]);

  useEffect(() => {
    if (isAnsCorrect && userFinalAnswer) {
      setText(
        t(
          "play.singlePlayerPage.hintsGameModePage.hintsText.modal.correctAnsChatBubble"
        ) + userFinalAnswer
      );
      resetText();
    } else if (isAnsCorrect === false && userFinalAnswer) {
      setText(
        t(
          "play.singlePlayerPage.hintsGameModePage.hintsText.modal.wrongAnsChatBubble"
        ) +
          userFinalAnswer +
          "!"
      );
      resetText();
    }
  }, [isAnsCorrect, text, userFinalAnswer]);
  return (
    <div
      style={
        open
          ? {
              position: "absolute",
              height: "100%",
              width: "100%",
              top: 0,
              left: 0,
              backgroundColor: "rgba(0,0,0,0.5)",
              zIndex:100,
            }
          : null
      }
      onClick={(e) => {
        setOpen(false);
      }}
    >
      <div
        className={`guess-player-modal ${open ? "show" : ""}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="d-flex justify-content-center">
          <img
            src="/img/soccer/Team_B_Tackle_SOUTH.png"
            width={120}
            height={120}
          ></img>
          <div
            style={{
              backgroundImage: "url('/img/chat-bubble.png')",
              backgroundSize: "180px 150px",
              backgroundRepeat: "no-repeat",
              position: "absolute",
              width: 180,
              height: 150,
              top: -50,
              marginLeft: 70,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ width: 120 }}>
              <p
                style={{
                  color: "black",
                  textAlign: "center",
                  marginBottom: 20,
                }}
              >
                {currentText}
              </p>
            </div>
          </div>
        </div>
        {!isAnsCorrect ? (
          <div className="mt-4" style={{ textAlign: "center" }}>
            <InputLabel
              htmlFor="input-with-icon-adornment"
              style={{ color: "white" }}
            >
              {t("play.singlePlayerPage.hintsGameModePage.inputBttn")}
            </InputLabel>
            <Input
              id="input-with-icon-adornment"
              value={userPlayerQuery}
              style={{ color: "white" }}
              onChange={(e) => {
                setUserPlayerQuery(e.target.value);
              }}
              startAdornment={
                <InputAdornment position="start">
                  <AccountCircle style={{ color: "white" }} />
                </InputAdornment>
              }
              endAdornment={
                userPlayerQuery && (
                  <IconButton
                    onClick={() => {
                      setUserPlayerQuery("");
                    }}
                  >
                    <InputAdornment position="start">
                      <ClearIcon style={{ color: "red" }} />
                    </InputAdornment>
                  </IconButton>
                )
              }
            />
            <br />
            <div
              className={`suggested-players-div ${
                suggestedPlayers.length > 0 ? "show" : ""
              }`}
            >
              {suggestedPlayers.map((playerName) => {
                const searchIdx = playerName
                  .toLowerCase()
                  .search(userPlayerQuery.toLowerCase());
                return (
                  <IconButton
                    key={playerName}
                    onClick={() => {
                      setUserPlayerQuery(playerName);
                    }}
                  >
                    <Chip
                      style={{ backgroundColor: "rgb(255 255 255 / 90%)" }}
                      label={
                        <span>
                          {playerName.substring(0, searchIdx)}
                          <span
                            style={{ fontWeight: searchIdx < 0 ? "" : "bold" }}
                          >
                            {playerName.substring(
                              searchIdx,
                              searchIdx + userPlayerQuery.length
                            )}
                          </span>
                          {playerName.substring(
                            searchIdx + userPlayerQuery.length,
                            playerName.length
                          )}
                        </span>
                      }
                    />
                  </IconButton>
                );
              })}
            </div>
          </div>
        ) : null}
        <GuessModalButtons
          open={open}
          buttonsCondition={!isAnsCorrect}
          submitButtonDisabledCodition={
            ![...suggestedPlayers].includes(userPlayerQuery)
          }
          handleSubmit={() => {
            handleSubmission(userPlayerQuery);
            setUserFinalAnswer(userPlayerQuery);
          }}
          newGameButtonLabel={t(
            "play.singlePlayerPage.hintsGameModePage.hintsText.modal.newPlayer"
          )}
          fromFillTheLineupMode={fromFillTheLineupMode}
          handleAnotherGameClick={handleAnotherGameClick}
        />
      </div>

      {open && (
        <AnswerAnimation
          isShowAni={showAniWhenAnswer}
          isShowCorrect={isAnsCorrect}
          handleAnimationFinish={(cond) => {
            setShowAniWhenAnswer(false);
          }}
        />
      )}
    </div>
  );
}
