import { useEffect, useContext, useState } from "react";
import { auth } from "../config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { Navigate, Outlet } from "react-router-dom";
import { Context as UserContext } from "../context/UserContext";
import BallLoading from "../components/BallLoading";

export default function ResolveAuth({}) {
  const {
    state: { user },
    setUserDetails,
  } = useContext(UserContext);
  const [loading, setIsLoading] = useState(true);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        setUserDetails(token, user.email, user.displayName);
      } else {
        console.log("not logged in!");
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);
  
  if (loading) {
    return <BallLoading />;
  }
  return user.token ? <Outlet /> : <Navigate to={"fbk-admin/login"} />;
}
