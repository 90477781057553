import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Context as UserContext } from "../../context/UserContext";
import { jaroDistanceSearch } from "../../services/helperFunctions";
import { getPlayers } from "../../services/api/player";
import trophiesTranslations from "../../locales/trophies.json";

const trophyNames = Object.keys(trophiesTranslations);
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const extractHints = (player, language, t) => {
  //player object e.g., player:{country:object, fbref.pifbref.trophies: array, }
  let hints = [];
  if (player.fbref) {
    player.fbref.pi.position &&
      hints.push(
        `${t("play.singlePlayerPage.hintsGameModePage.hintsText.position")}` +
          player.fbref.pi.position
      );
    player.fbref.pi.foot &&
      hints.push(
        `${t(
          "play.singlePlayerPage.hintsGameModePage.hintsText.FavoriteFoot"
        )} ${
          trophyNames.includes(player.fbref.pi.foot)
            ? trophiesTranslations[player.fbref.pi.foot][language.lng]
            : player.fbref.pi.foot
        }`
      );
    hints = player.fbref.trophies
      ? [
          ...hints,
          ...player.fbref.trophies.map((eachTrophy) => {
            const tn = trophyNames.find((ename) => eachTrophy.includes(ename));
            if (tn) {
              const temp = eachTrophy
                .replace(tn, trophiesTranslations[tn][language.lng])
                .split(trophiesTranslations[tn][language.lng]);
              return `${trophiesTranslations[tn][language.lng]} ${temp[0]}`;
            } else return eachTrophy;
          }),
        ]
      : hints;
    hints = player.teamsPlayedFor
      ? [
          ...hints,
          ...player.teamsPlayedFor.map(
            (et) =>
              `${t(
                "play.singlePlayerPage.hintsGameModePage.hintsText.playedFor"
              )}` + et.name[language.lng]
          ),
        ]
      : hints;
    hints.push(
      `${t("play.singlePlayerPage.hintsGameModePage.hintsText.country")}` +
        player.country.name[language.lng]
    );
    player.whoscored.pi.height &&
      hints.push(
        `${t("play.singlePlayerPage.hintsGameModePage.hintsText.height")}` +
          player.whoscored.pi.height
      );
    if (player.whoscored.pi.dob) {
      const dob = new Date(player.whoscored.pi.dob);
      const dobStr = `${t(
        "play.singlePlayerPage.hintsGameModePage.hintsText.dob"
      )} ${dob.getDate().toString()}/${(dob.getMonth() + 1).toString()}/${dob
        .getFullYear()
        .toString()}`;
      hints.push(dobStr);
    }
  }
  return hints;
};

export const storageName = "hintsCache";
const getCache = () => {
  return JSON.parse(localStorage.getItem(storageName));
};

const saveToCache = (type, payload) => {
  if (type === "NEW_GAME") {
    localStorage.setItem(
      storageName,
      JSON.stringify({
        mainPlayer: payload.mainPlayer,
        otherPlayers: payload.otherPlayers,
        mainPlayerHints: payload.mainPlayerHints,
      })
    );
  } else if (type === "ANOTHER_GAME") {
    localStorage.removeItem(storageName);
    window.location.reload();
  } else if (type === "SAVE_USER_ANSWER") {
    const temp = getCache();
    localStorage.setItem(
      storageName,
      JSON.stringify({
        ...temp,
        userFinalAnswer: payload.userFinalAnswer,
      })
    );
  }
};

export default function useHintsGameModeHook() {
  const { t } = useTranslation();
  const {
    state: { language },
  } = useContext(UserContext);
  const [data, setData] = useState(undefined);
  const [userPlayerQuery, setUserPlayerQuery] = useState("");
  const [suggestedPlayers, setSuggestedPlayers] = useState([]);
  const [mainPlayerHints, setMainPlayerHints] = useState([]);
  const [userFinalAnswer, setUserFinalAnswer] = useState("");
  const [guessModalOpen, setGuessModalOpen] = useState(false);
  useEffect(() => {
    const tempCache = getCache();
    if (tempCache && tempCache.mainPlayer) {
      sleep(1000).then(() => {
        setData({
          mainPlayer: tempCache.mainPlayer,
          otherPlayers: tempCache.otherPlayers,
        });
        setUserPlayerQuery(tempCache.userFinalAnswer?tempCache.userFinalAnswer:"");
        setUserFinalAnswer(tempCache.userFinalAnswer);
        setMainPlayerHints(tempCache.mainPlayerHints);
      });
    } else {
      const fetchPlayerForHints = async () => {
        const fetched = await getPlayers(undefined, undefined, undefined, true);
        await sleep(2000); // Waits for 2 seconds
        setData(fetched[0]); //the backed returns an array with a single object that contains the data we need. #TODO: make it an object
        setMainPlayerHints(
          extractHints(fetched[0].mainPlayer, language, t).sort(
            () => Math.random() - 0.5
          )
        );
        saveToCache("NEW_GAME", {
          mainPlayer: fetched[0].mainPlayer,
          otherPlayers: fetched[0].otherPlayers,
          mainPlayerHints: extractHints(
            fetched[0].mainPlayer,
            language,
            t
          ).sort(() => Math.random() - 0.5),
        });
      };
      fetchPlayerForHints();
    }
  }, []);

  useEffect(() => {
    if (data && userPlayerQuery.length >= 2) {
      //   const searchScriptResults = searchStrings(
      //     [data.mainPlayer.name.en, ...data.otherPlayers.map((ep) => ep.name.en)],
      //     userPlayerQuery
      //   );
      //   setSuggestedPlayers(searchScriptResults.splice(0, 5));
      setSuggestedPlayers(
        jaroDistanceSearch(
          [
            ...data.otherPlayers
              .filter((ep) => ep._id != data.mainPlayer._id) //this filter is here cuz the mainplayer can also be in otherplayers
              .map((ep) => ep.name[language.lng]),
            data.mainPlayer.name[language.lng],
          ],
          userPlayerQuery
        ).splice(0, 5)
      );
      //   setUserFinalAnswer("");
    } else if (data && userPlayerQuery.length == 0) {
      setSuggestedPlayers([]);
      setUserFinalAnswer("");
    }
  }, [userPlayerQuery, data]);

  useEffect(() => {
    if (userFinalAnswer) {
      console.log("UFA: ", userFinalAnswer);

      saveToCache("SAVE_USER_ANSWER", { userFinalAnswer: userFinalAnswer });
    }
  }, [userFinalAnswer]);

  console.log(data, mainPlayerHints);

  return {
    data,
    language,
    t,
    suggestedPlayers,
    mainPlayerHints,
    setUserPlayerQuery,
    userPlayerQuery,
    userFinalAnswer,
    setUserFinalAnswer,
    guessModalOpen,
    setGuessModalOpen,
    saveToCache,
  };
}
