import React, { useEffect, useState, useContext } from "react";
import LanguageSelectorModal from "../../components/GameHome/LanguageSelectorModal";
import { storageName } from "../../context/UserContext";
import { useTranslation } from "react-i18next";
import { Context as UserContext } from "../../context/UserContext";
import Navbar from "../../components/GameHome/Navbar";
import GameModeCard from "../../components/GameHome/GameModeCard";
import "../../styles/Game/GameHome.css";
import FilltheLineupGameCardLottie from "../../components/GameHome/FilltheLineupGameCardLottie";

export default function GameHome() {
  const { t } = useTranslation();
  const {
    setLanguage,
    state: { language },
  } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (!localStorage.getItem(storageName)) setOpen(true);
  }, []);
  useEffect(() => {
    if (setLanguage) setLanguage();
  }, []);

  return (
    <div style={{ fontSize: "3.2em", padding: "0 0 15px 0" }}>
      <Navbar setOpen={setOpen} title={t("play.homePage.gameTitle")} />
      <div className="" style={{maxWidth:1200, margin:"0 auto 0 auto"}}>
        <div className="mt-5 d-flex justify-content-around flex-wrap">
          <div className=" d-flex  mt-md-3" style={{minWidth:315, maxWidth:380}}>
            <GameModeCard
              t={t}
              text={t("play.singlePlayerPage.hints")}
              link={"hints"}
            >
              <div
                style={{
                  height: 200,
                  display: "flex",
                  alignItems: "center",
                  filter: "blur(5px)",
                  justifyContent: "center",
                }}
              >
                <img
                  src="/img/soccer/Team_B_Kick_SOUTHEAST.png"
                  width={150}
                  height={150}
                />
                <div style={{ position: "absolute", top: 7, right: 52 }}>
                  <img
                    src="/img/soccer/questionmark.png"
                    className="qnMarkImg"
                    width={100}
                    height={100}
                  />
                </div>
              </div>
            </GameModeCard>
          </div>
          <div className=" d-flex mt-3" style={{minWidth:315, maxWidth:380}}>
            <GameModeCard
              t={t}
              text={t("play.singlePlayerPage.wheredTheyDuod")}
              fontSize={language.dir === "ltr" ? 34 : null}
              link={"where'd-they-duo'd"}
            >
              <div
                style={{
                  height: 200,
                  display: "flex",
                  alignItems: "center",
                  filter: "blur(5px)",
                  justifyContent: "center",
                  wordWrap: "break-word",
                }}
              >
                <img src="/img/crest.png" width={150} height={150} />
                <div style={{ position: "absolute", top: 33, right: 109 }}>
                  <img
                    src="/img/soccer/questionmark.png"
                    className="qnMarkImg"
                    width={100}
                    height={100}
                  />
                </div>
              </div>
            </GameModeCard>
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <div className=" d-flex justify-content-center mt-3 mt-lg-5 " style={{minWidth:315, maxWidth:380}}>
            <GameModeCard
              t={t}
              text={t("play.singlePlayerPage.fillTheLineup")}
              fontSize={language.dir === "ltr" ? 34 : null}
              link={"fill-the-lineup"}
              parentStyles={{position:"relative"}}
              textDivStyles={{position:"absolute", width:"100%", bottom: 0}}
            >
              <div
                style={{
                  height: 200,
                  display: "flex",
                  alignItems: "center",
                  filter: "blur(2px)",
                  justifyContent: "center",
                  position:"absolute",
                  zIndex:-1,
                  left:0,
                  top:0,
                  width:"100%",
                  height:"100%"
                }}
              >
                <FilltheLineupGameCardLottie />
              </div>
            </GameModeCard>
          </div>
        </div>
      </div>
      <LanguageSelectorModal open={open} setOpen={setOpen} />
    </div>
  );
}
