import React, { useEffect, useState } from "react";
import DetailedViewModal from "../DetailedViewModal";
import SoccerFieldWithPlayers from "./SoccerFieldWithPlayers";
import { reshapePlayersFromAPI } from "../../services/helperFunctions";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Chip } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export default function DetailedMatchViewModal({ open, setOpen, matchObj }) {
  const [value, setValue] = React.useState(undefined);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (matchObj) {
      setValue(
        matchObj.teamOne.team ? matchObj.teamOne.team.name.en : matchObj.teamOne.nationalTeam.name.en
      );
    }
  }, [matchObj]);
  if (!matchObj) return;

  return (
    <DetailedViewModal open={open} setOpen={setOpen} customStyle={style}>
      <h3 className="mb-3">Detailed view of teams' players</h3>
      {value && (
        <FormControl>
          <FormLabel id="match-teams-radio-buttons-group">Team</FormLabel>
          <RadioGroup
            aria-labelledby="match-teams-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
            className="d-flex flex-row"
          >
            <FormControlLabel
              value={matchObj.teamOne.team ? matchObj.teamOne.team.name.en : matchObj.teamOne.nationalTeam.name.en}
              control={<Radio />}
              label={matchObj.teamOne.team ? matchObj.teamOne.team.name.en : matchObj.teamOne.nationalTeam.name.en}
            />
            <FormControlLabel
              value={matchObj.teamTwo.team? matchObj.teamTwo.team.name.en:matchObj.teamTwo.nationalTeam.name.en}
              control={<Radio />}
              label={matchObj.teamTwo.team? matchObj.teamTwo.team.name.en:matchObj.teamTwo.nationalTeam.name.en}
            />
          </RadioGroup>
        </FormControl>
      )}
      <div></div>
      {value == (matchObj.teamOne.team ? matchObj.teamOne.team.name.en : matchObj.teamOne.nationalTeam.name.en) && (
        <>
          <Chip
            className="my-2"
            label={"Coach: " + matchObj.teamOne.coach.name.en}
          />
          <SoccerFieldWithPlayers
            players={reshapePlayersFromAPI(
              matchObj.teamOne.players,
              matchObj.teamOne.lineup
            )}
            teamLineup={matchObj.teamOne.lineup}
          />
        </>
      )}
      {value == (matchObj.teamTwo.team? matchObj.teamTwo.team.name.en : matchObj.teamTwo.nationalTeam.name.en) && (
        <>
          <Chip
            className="my-2"
            label={"Coach: " + matchObj.teamTwo.coach.name.en}
          />
          <SoccerFieldWithPlayers
            players={reshapePlayersFromAPI(
              matchObj.teamTwo.players,
              matchObj.teamTwo.lineup
            )}
            teamLineup={matchObj.teamTwo.lineup}
          />
        </>
      )}
    </DetailedViewModal>
  );
}
