import React from "react";
import Lottie from "react-lottie";
import animationData from "../../lottie/fillthelineupGamecard.json";

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice",
  //   },
};
export default function FilltheLineupGameCardLottie() {
  return (
    <div style={{ display: "flex",
    justifyContent: "center",
    alignItems: "center", }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        //   backgroundColor: 'rgb(25 118 210 / 30%)',
          width: "100%",
          height: "100%",
        //   borderRadius: 400,
        }}
      >
        <Lottie
          options={defaultOptions}
          height={300}
          width={300}
          isClickToPauseDisabled
          
        />
      </div>
    </div>
  );
}
