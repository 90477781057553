import React, { useContext, useState } from "react";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import IconButton from "@mui/material/IconButton";
import { Context as UserContext } from "../../context/UserContext";
import { useTranslation } from "react-i18next";
import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useQuery } from "@tanstack/react-query";
import { getTeams } from "../../services/api/team";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import DetailedViewModal from "../DetailedViewModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",

  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  maxHeight: "80vh", // Adjust this value as needed
  overflowY: "auto",
  p: 4,
};

// const fbrefLeagues = ["Do", ""];
export default function DetailedPlayerViewModal({
  open,
  setOpen,
  playerToView,
}) {
  const {
    state: { language },
  } = useContext(UserContext);
  const { t } = useTranslation();
  const handleClose = () => setOpen(false);
  const {
    isPending,
    error,
    data: teamsData,
  } = useQuery({
    queryKey: ["teams"],
    queryFn: () => {
      return getTeams();
    },
  });

  const [fbrefLeageToView, setFbrefLeagueToView] = useState(
    "domesticLeaguesSeasons"
  );
  if (!playerToView) return;
  return (
    <DetailedViewModal open={open} setOpen={setOpen} customStyle={style}>
      <h4 className="text-center text-primary fw-bold display-6">
        {t("playerPage.detailedView.title")}
      </h4>
      <div className="d-flex gap-3 flex-wrap mt-5 align-items-center">
        <p sx={{ mt: 2 }}>
          <b>{t("playerPage.detailedView.id")}:</b> {playerToView._id}
          <IconButton
            onClick={() => {
              navigator.clipboard.writeText(playerToView._id);
            }}
          >
            <ContentPasteIcon />
          </IconButton>
        </p>
        <p sx={{ mt: 2 }}>
          <b>{t("playerPage.detailedView.name")}: </b>
          {playerToView.name[language.lng]}
          <IconButton
            onClick={() => {
              navigator.clipboard.writeText(playerToView.name[language.lng]);
            }}
          >
            <ContentPasteIcon />
          </IconButton>
        </p>
      </div>
      <div className="mt-3 mb-4">
        <p className="text-center text-primary fw-bold display-6">
          {t("playerPage.detailedView.fbrefDataTitle")}
        </p>
        {playerToView.fbref && playerToView.fbref.pi ? (
          <div>
            <div className="d-flex gap-3 flex-wrap mt-5 align-items-center">
              <p sx={{ mt: 2 }}>
                <b>{t("playerPage.detailedView.position")}:</b>{" "}
                {playerToView.fbref.pi.position}
              </p>
              <p sx={{ mt: 2 }}>
                <b>{t("playerPage.detailedView.foot")}:</b>{" "}
                {playerToView.fbref.pi.foot}
              </p>
              <p sx={{ mt: 2 }}>
                <b>{t("playerPage.detailedView.born")}:</b>{" "}
                {playerToView.fbref.pi.born}{" "}
              </p>
            </div>

            <div className="d-flex column-gap-2 row-gap-2 flex-wrap align-items-center mt-3">
              <b>{t("playerPage.detailedView.trophies")}: </b>

              {playerToView.fbref.trophies.map((each) => (
                <Chip label={each} />
              ))}
            </div>
            <div className="mt-5">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  Seasons
                </AccordionSummary>
                <AccordionDetails>
                  <Select
                    value={fbrefLeageToView}
                    onChange={(e) => {
                      console.log(fbrefLeageToView);
                      setFbrefLeagueToView(e.target.value);
                    }}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value={"domesticLeaguesSeasons"}>
                      Domestic Leagues
                    </MenuItem>
                    <MenuItem value={"domesticCupSeasons"}>
                      Domestic Cups
                    </MenuItem>
                    <MenuItem value={"internationalCupSeasons"}>
                      International Cups
                    </MenuItem>
                    <MenuItem value={"nationalTeamSeasons"}>
                      National Team
                    </MenuItem>
                  </Select>
                  <FormHelperText>Select a League</FormHelperText>
                  <Table
                    sx={{ minWidth: 650 }}
                    className="mt-2"
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Season</TableCell>
                        <TableCell>Age</TableCell>
                        <TableCell>Squad</TableCell>
                        <TableCell>MongoDB Team</TableCell>
                        <TableCell>Country</TableCell>
                        <TableCell>Competition</TableCell>
                        <TableCell>League Rank</TableCell>
                        <TableCell>MP</TableCell>
                        <TableCell>Starts</TableCell>
                        <TableCell>Mins</TableCell>
                        {/* <TableCell>90s</TableCell> */}
                        <TableCell>Goals</TableCell>
                        <TableCell>Ast</TableCell>
                        {/* <TableCell>G+A</TableCell> */}
                        <TableCell>G-PK</TableCell>
                        <TableCell>PK</TableCell>
                        <TableCell>PKatt</TableCell>
                        <TableCell>CrdY</TableCell>
                        <TableCell>CrdR</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {playerToView.fbref[fbrefLeageToView].map(
                        (eachSeason) => (
                          <TableRow>
                            <TableCell>{eachSeason.season}</TableCell>
                            <TableCell>{eachSeason.age}</TableCell>
                            <TableCell>{eachSeason.squad}</TableCell>
                            <TableCell>
                              {eachSeason.teamId
                                ? teamsData.find(
                                    (eachTeam) =>
                                      eachTeam._id == eachSeason.teamId
                                  ).name[language.lng]
                                : "N/A"}
                            </TableCell>
                            <TableCell>{eachSeason.country}</TableCell>
                            <TableCell>{eachSeason.competition}</TableCell>
                            <TableCell>{eachSeason.lgRank}</TableCell>
                            <TableCell>{eachSeason.matchesPlayed}</TableCell>
                            <TableCell>{eachSeason.starts}</TableCell>
                            <TableCell>{eachSeason.minutes}</TableCell>
                            {/* <TableCell>{eachSeason.nineties}</TableCell> */}
                            <TableCell>{eachSeason.goals}</TableCell>
                            <TableCell>{eachSeason.assists}</TableCell>
                            {/* <TableCell>{eachSeason.goalsPlusAssists}</TableCell> */}
                            <TableCell>{eachSeason.nonPenaltyGoals}</TableCell>
                            <TableCell>{eachSeason.penaltyKicksMade}</TableCell>
                            <TableCell>{eachSeason.pkAtt}</TableCell>
                            <TableCell>{eachSeason.yellowCards}</TableCell>
                            <TableCell>{eachSeason.redCards}</TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        ) : (
          <p>There is no FBREF data for this player.</p>
        )}
      </div>
      <div className="mt-3 mb-4">
        <p className="text-center text-primary fw-bold display-6">
          Whoscored Data
        </p>
        {playerToView.whoscored && playerToView.whoscored.pi ? (
          <div>
            <div className="d-flex gap-3 flex-wrap mt-5 align-items-center">
              <p sx={{ mt: 2 }}>
                <b>{t("playerPage.detailedView.shirtNo")}:</b>{" "}
                {playerToView.whoscored.pi.shirtNo
                  ? playerToView.whoscored.pi.shirtNo
                  : "N/A"}
              </p>
              <p sx={{ mt: 2 }}>
                <b>{t("playerPage.detailedView.height")}:</b>{" "}
                {playerToView.whoscored.pi.height}
              </p>
              <p sx={{ mt: 2 }}>
                <b>{t("playerPage.detailedView.whoscoredPosition")}:</b>{" "}
                {playerToView.whoscored.pi.positions}{" "}
              </p>
              <p sx={{ mt: 2 }}>
                <b>{t("playerPage.detailedView.whoscoredBorn")}:</b>{" "}
                {playerToView.whoscored.pi.dob}{" "}
              </p>
            </div>

            <div className="d-flex column-gap-2 row-gap-2 flex-wrap align-items-center mt-3">
              <b>Seasons: </b>

              {/* {playerToView.fbref.trophies.map((each)=>(<Chip label={each}/>))} */}
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Season</TableCell>
                    <TableCell>Team</TableCell>
                    <TableCell>Tournament</TableCell>
                    <TableCell>Appearances</TableCell>
                    <TableCell>Minutes</TableCell>
                    <TableCell>Goals</TableCell>
                    <TableCell>Assists</TableCell>
                    <TableCell>Yellow</TableCell>
                    <TableCell>Red</TableCell>
                    <TableCell>SpG</TableCell>
                    <TableCell>Aerials Won</TableCell>
                    <TableCell>pSPercent</TableCell>
                    <TableCell>Man of the Match</TableCell>
                    <TableCell>Rating</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {playerToView.whoscored.seasons.map((eachSeason) => (
                    <TableRow>
                      <TableCell>{eachSeason.season}</TableCell>
                      <TableCell>
                        {eachSeason.teamId
                          ? teamsData.find(
                              (eachTeam) => eachTeam._id == eachSeason.teamId
                            ).name[language.lng]
                          : eachSeason.team}
                      </TableCell>
                      <TableCell>{eachSeason.tournament}</TableCell>
                      <TableCell>{eachSeason.apps}</TableCell>
                      <TableCell>{eachSeason.mins}</TableCell>
                      <TableCell>{eachSeason.goals}</TableCell>
                      <TableCell>{eachSeason.assists}</TableCell>
                      <TableCell>{eachSeason.yellow}</TableCell>
                      <TableCell>{eachSeason.red}</TableCell>
                      <TableCell>{eachSeason.SpG}</TableCell>
                      <TableCell>{eachSeason.pSPercent}</TableCell>
                      <TableCell>{eachSeason.aerialsWon}</TableCell>
                      <TableCell>{eachSeason.mOTM}</TableCell>
                      <TableCell>{eachSeason.rating}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        ) : (
          <p>There is no Whoscored data for this player.</p>
        )}
      </div>
    </DetailedViewModal>
  );
}
