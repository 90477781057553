import React, { useEffect, useState } from "react";
import "../../../styles/Game/hintsGameMode.css";
import "../../../styles/buttonStyles.css";
import "../../../styles/modalStyle.css";
import Chip from "@mui/material/Chip";
import BallLoading from "../../../components/BallLoading";
import useHintsGameModeHook from "../../../hooks/Game/useHintsGameModeHook";
import GuessPlayerModal from "../../../components/GameHome/HintsGameMode/GuessPlayerModal";
import { commonBackgroundColorAndBorder } from "../../../services/styles";
import CommonQuickAccessMenu from "../../../components/GameHome/CommonQuickAccessMenu";
import useHintsGsapHook from "../../../hooks/Game/useHintsGsapHook";
import { gsap } from "gsap";
import { titleMenuAnimation } from "../../../services/gsapFunctions";

export default function HintsGameMode() {
  const {
    data,
    language,
    t,
    suggestedPlayers,
    mainPlayerHints,
    setUserPlayerQuery,
    userPlayerQuery,
    userFinalAnswer,
    setUserFinalAnswer,
    guessModalOpen,
    setGuessModalOpen,
    saveToCache,
  } = useHintsGameModeHook();
  useHintsGsapHook(mainPlayerHints);

  useEffect(() => {
    if (mainPlayerHints.length == 0) {
      return;
    }

  }, [mainPlayerHints]);

  if (!data || mainPlayerHints.length == 0) {
    return <BallLoading />;
  }
  return (
    <div
      className="container"
      style={{ direction: language.dir, overflow: "hidden" }}
    >
      <div
        className="title-menu-div"
        style={{
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          marginTop: 20,
          // flexDirection: language.dir === "ltr" ? "row" : "row-reverse",
        }}
      >
        <h2
          className="hints-mode-title"
          style={{ ...commonBackgroundColorAndBorder }}
        >
          {t("play.singlePlayerPage.hintsGameModePage.title")}
        </h2>
        <CommonQuickAccessMenu
          handleNewGame={() => {
            saveToCache("ANOTHER_GAME");
          }}
          newMatchName={t(
            "play.singlePlayerPage.quickAccessMenu.hintsGameModeNewGame"
          )}
        />
      </div>

      <div
        className="d-flex column-gap-4 row-gap-3 flex-wrap mt-3 parent-div"
        style={{
          ...commonBackgroundColorAndBorder,
          maxHeight: 331,
          overflowY: "auto",
        }}
      >
        {mainPlayerHints.map((eh, idx) => (
          <Chip
            className="chip-for-ani"
            key={eh + idx}
            label={eh}
            sx={{ color: "white", userSelect: "none" }}
          />
        ))}
      </div>
      <div
        className="mt-5 button-div-for-ani"
        style={{ ...commonBackgroundColorAndBorder }}
      >
        <button
          className="style-one-button"
          onClick={() => {
            setGuessModalOpen(true);
          }}
        >
          {t("play.singlePlayerPage.hintsGameModePage.inputBttn")}
        </button>
        <GuessPlayerModal
          open={guessModalOpen}
          setOpen={setGuessModalOpen}
          userPlayerQuery={userPlayerQuery}
          setUserPlayerQuery={setUserPlayerQuery}
          suggestedPlayers={suggestedPlayers}
          setUserFinalAnswer={setUserFinalAnswer}
          userFinalAnswer={userFinalAnswer}
          isAnsCorrect={data.mainPlayer.name[language.lng] === userFinalAnswer}
          handleSubmission={() => {}}
          handleAnotherGameClick={() => {
            saveToCache("ANOTHER_GAME");
          }}
        />
      </div>
    </div>
  );
}
