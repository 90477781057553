import { baseServerUrl } from "./globalVars";
import { getFBToken } from "./auth";

//the _ is an object given by the react query library (we dont need it)
export const getPlayers = async (
  searchText,
  specificPlayerId,
  countryId,
  forHintsGameMode,
  forWheredTheyDuodGameMode
) => {
  let query = "";
  if (searchText) {
    query = "?search=" + searchText;
  } else if (specificPlayerId) {
    query = "?_id=" + specificPlayerId;
  } else if (countryId) {
    query = "?country=" + countryId;
  } else if (forHintsGameMode) {
    query = "?generatePlayersForHintsMode=true";
  } else if (forWheredTheyDuodGameMode) {
    query = "?generateTwoPlayersForWhereDidTheyDuodMode=true";
  }
  const token = await getFBToken();
  const res = await fetch(`${baseServerUrl}/player${query}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return await res.json();
};

export const addNewPlayer = async (playerObj) => {
  const token = await getFBToken();
  const response = await fetch(`${baseServerUrl}/player`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(playerObj),
  });
  return response.data;
};

export const deletePlayer = async (id) => {
  const token = await getFBToken();
  const response = await fetch(`${baseServerUrl}/player/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const editPlayer = async (obj) => {
  const token = await getFBToken();
  const response = await fetch(`${baseServerUrl}/player/${obj._id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(obj.updatedPlayer),
  });
  return response.data;
};
