import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { Context as UserContext } from "../../context/UserContext";
import { getPlayers } from "../../services/api/player";
import { Chip } from "@mui/material";

export default function PlayerInforModal({
  open,
  setOpen,
  handlePlayerSelection,
}) {
  const { t } = useTranslation();
  const [localFilter, setLocalFilter] = useState("");
  const [searchedPlayers, setSearchedPlayers] = useState([]);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const {
    state: { language },
  } = useContext(UserContext);
  const fetchPlayers = async () => {
    setIsSearchLoading(true);
    const players = await getPlayers(localFilter);
    setIsSearchLoading(false);
    setSearchedPlayers(players);
  };
  useEffect(() => {
    if (!open) {
      setSearchedPlayers([]);
      setLocalFilter("");
    }
  }, [open]);
  return (
    open && (
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          top: 0,
          left: 0,
          backgroundColor: "rgba(0,0,0,0.4)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => {
          setOpen(false);
        }}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{
            backgroundColor: "white",
            zIndex: 2,
            padding: "40px 100px",
            borderRadius: 20,
          }}
        >
          <TextField
            label={t("inputLabels.search")}
            value={localFilter}
            id="outlined-start-adornment"
            onChange={(e) => {
              setLocalFilter(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                fetchPlayers(localFilter);
              }
            }}
            sx={{ m: 1, width: "30ch" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <span
                    style={{ rotate: language.dir === "ltr" ? 0 : "90deg" }}
                  >
                    <SearchIcon />
                  </span>
                </InputAdornment>
              ),
              endAdornment: localFilter && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setLocalFilter("");
                      setSearchedPlayers([]);
                      // setSearchString(pageName, "");
                    }}
                  >
                    <span
                      style={{ rotate: language.dir === "ltr" ? 0 : "90deg" }}
                    >
                      <CloseIcon />
                    </span>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <div className="mt-3">
            {/* search results */}
            {isSearchLoading ? <CircularProgress /> : null}
            {!isSearchLoading && searchedPlayers.length > 0 ? (
              searchedPlayers.map((eachPlayer, idx) => {
                return (
                  <IconButton
                    key={idx}
                    onClick={() => {
                      handlePlayerSelection(eachPlayer);
                    }}
                  >
                    <Chip label={eachPlayer.name.en} />
                  </IconButton>
                );
              })
            ) : (
              <p className="">No players found...</p>
            )}
          </div>
        </div>
      </div>
    )
  );
}
