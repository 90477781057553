import React, { useState, useEffect, useRef } from "react";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import StadiumIcon from "@mui/icons-material/Stadium";
import useTypingAnimationHook from "../../../hooks/Game/useTypingAnimationHook";
import GuessModalButtons from "../GuessModalButtons";
import AnswerAnimation from "../AnswerAnimation";
import { useTranslation } from "react-i18next";

export default function GuessTeamModal({
  open,
  setOpen,
  twoPlayerObjAndTeams,
  returnSuggestTeams,
  language,
  handleAnotherGameClick,
  saveToCache,
  getCachedDuo,
}) {
  const { t } = useTranslation();
  const [userPlayerQuery, setUserPlayerQuery] = useState("");
  const [userFinalAnswer, setUserFinalAnswer] = useState();
  const [text, setText] = useState(
    t("play.singlePlayerPage.wheredTheyDuodGameModePage.modal.text")
  );
  const { currentText, resetText } = useTypingAnimationHook(open, text);
  const [suggestTeams, setSuggestedTeams] = useState([]);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
  const [isShowAnswerAnimation, setIsShowAnswerAnimation] = useState(false);

  useEffect(() => {
    // if there is a cached user answer, then set it so the user remembers what he last entered
    const temp = getCachedDuo();
    if (temp && temp.userFinalAnswer) {
      setUserFinalAnswer(temp.userFinalAnswer);
    }
  }, []);
  useEffect(() => {
    if (userPlayerQuery) {
      setSuggestedTeams(returnSuggestTeams(userPlayerQuery));
    } else {
      setSuggestedTeams([]);
    }
  }, [userPlayerQuery]);

  useEffect(() => {
    if (userFinalAnswer) {
      saveToCache("SAVE_USER_ANSWER", { userFinalAnswer });
      const keys = Object.keys(twoPlayerObjAndTeams.teamsPlayedFor);
      const team = keys.find(
        (ek) =>
          twoPlayerObjAndTeams.teamsPlayedFor[ek].name[language.lng] ===
          userFinalAnswer
      );
      if (team) {
        setIsAnswerCorrect(true);
        setText(
          t(
            "play.singlePlayerPage.wheredTheyDuodGameModePage.modal.correctTeamName"
          ) +
            userFinalAnswer +
            "."
        );
        resetText();
      } else {
        setIsAnswerCorrect(false);
        setText(
          t(
            "play.singlePlayerPage.wheredTheyDuodGameModePage.modal.wrongTeamName"
          ) +
            userFinalAnswer +
            "."
        );
        resetText();
      }
      setIsShowAnswerAnimation(true);
    }
  }, [userFinalAnswer]);
  return (
    <div
      style={
        open
          ? {
              position: "absolute",
              height: "100%",
              width: "100%",
              top: 0,
              left: 0,
              backgroundColor: "rgba(0,0,0,0.5)",
              zIndex: 100,
            }
          : null
      }
      onClick={() => {
        setOpen(false);
      }}
    >
      <div
        className={`guess-player-modal ${open ? "show" : ""}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <p className="text-center lead"> {currentText}</p>
        {!isAnswerCorrect && (
          <>
            {" "}
            <div className="mt-4 text-center">
              <InputLabel
                htmlFor="input-with-icon-adornment"
                style={{ color: "white" }}
              >
                {t(
                  "play.singlePlayerPage.wheredTheyDuodGameModePage.modal.inputLabel"
                )}
              </InputLabel>
              <Input
                id="input-with-icon-adornment"
                value={userPlayerQuery}
                style={{ color: "white" }}
                onChange={(e) => {
                  setUserPlayerQuery(e.target.value);
                }}
                startAdornment={
                  <InputAdornment
                    position="start"
                    className={`${language.dir === "rtl" ? "ms-1" : "me-1"}`}
                  >
                    <StadiumIcon style={{ color: "white" }} />
                  </InputAdornment>
                }
                endAdornment={
                  userPlayerQuery && (
                    <IconButton
                      onClick={() => {
                        setUserPlayerQuery("");
                      }}
                    >
                      <InputAdornment position="start">
                        <ClearIcon style={{ color: "red" }} />
                      </InputAdornment>
                    </IconButton>
                  )
                }
              />
            </div>
            <div
              className={`suggested-players-div ${
                suggestTeams.length > 0 ? "show" : ""
              } mt-3 d-flex justify-content-center flex-wrap`}
            >
              {suggestTeams.map((teamName) => {
                const searchIdx = teamName
                  .toLowerCase()
                  .search(userPlayerQuery.toLowerCase());
                return (
                  <IconButton
                    key={teamName}
                    onClick={() => {
                      setUserPlayerQuery(teamName);
                    }}
                  >
                    <Chip
                      style={{ backgroundColor: "rgb(255 255 255 / 90%)" }}
                      label={
                        <span>
                          {teamName.substring(0, searchIdx)}
                          <span
                            style={{ fontWeight: searchIdx < 0 ? "" : "bold" }}
                          >
                            {teamName.substring(
                              searchIdx,
                              searchIdx + userPlayerQuery.length
                            )}
                          </span>
                          {teamName.substring(
                            searchIdx + userPlayerQuery.length,
                            teamName.length
                          )}
                        </span>
                      }
                    />
                  </IconButton>
                );
              })}
            </div>
          </>
        )}

        <GuessModalButtons
          open={open}
          buttonsCondition={!isAnswerCorrect}
          submitButtonDisabledCodition={!suggestTeams.includes(userPlayerQuery)}
          handleSubmit={() => {
            setUserFinalAnswer(userPlayerQuery);
          }}
          newGameButtonLabel={t(
            "play.singlePlayerPage.wheredTheyDuodGameModePage.modal.newTeam"
          )}
          handleAnotherGameClick={handleAnotherGameClick}
        />
      </div>
      {open && (
        <AnswerAnimation
          isShowAni={isShowAnswerAnimation}
          isShowCorrect={isAnswerCorrect}
          handleAnimationFinish={() => {
            setIsShowAnswerAnimation(false);
          }}
        />
      )}
    </div>
  );
}
