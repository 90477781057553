import React from "react";
import { commonBackgroundColorAndBorder } from "../../../services/styles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { IconButton } from "@mui/material";
import GuessModalButtons from "../GuessModalButtons";
import { useTranslation } from "react-i18next";

export default function MatchInfo({
  matchObj,
  openMatchInfoModal,
  language,
  setOpenMatchInfoModal,
  showButtonsWhenUserWon,
}) {
  const { t } = useTranslation();
  return (
    <div
      className={`match-info-modal ${openMatchInfoModal ? "show" : "not-show"}`}
      style={{
        ...commonBackgroundColorAndBorder,
        height: "fit-content",
        backgroundColor: "rgba(16, 51, 90, 0.92)",
        width: "100%",
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {/* left side */}
      <div style={{ position: "relative", width: "100%" }}>
        <h1 className="text-center mt-2 fw-bold">
          {/* Category */}
          {matchObj.category.name[language.lng]}
        </h1>
      </div>

      <h2 className="text-center mt-5" style={{ fontSize: 26 }}>
        {/* Team1 vs Team 2 */}
        <span className="me-3">{matchObj.teamOne.team.name[language.lng]}</span>
        <span className="me-4">{` ${matchObj.teamOne.goals} `}</span> -
        <span className="ms-4">{` ${matchObj.teamTwo.goals} `}</span>
        <span className="ms-3">{matchObj.teamTwo.team.name[language.lng]}</span>
      </h2>
      {matchObj.teamOne.penaltyGoals >= 0 && (
        <h3 className="mt-3 text-center" style={{ fontSize: 20 }}>
          {/* IF there were Penalties  */}
          Penalties: {matchObj.teamOne.penaltyGoals} -{" "}
          {matchObj.teamTwo.penaltyGoals}
        </h3>
      )}

      <h2 className="text-center mt-5" style={{ fontSize: 22 }}>
        {matchObj.matchStage.name[language.lng]},{" "}
        {new Date(matchObj.matchDate).toDateString()}
      </h2>

      <h2 className="text-center mt-5" style={{ fontSize: 22 }}>
        {language.lng === "ar"
          ? `${t("play.singlePlayerPage.fillTheLineupGameMode.stadium")} ${
              matchObj.stadium.name[language.lng]
            } `
          : ` ${matchObj.stadium.name[language.lng]} ${t(
              "play.singlePlayerPage.fillTheLineupGameMode.stadium"
            )}`}
      </h2>
      <div
        className="close-modal-style"
        style={{ position: "absolute", right: 5, top: 10 }}
      >
        <IconButton
          onClick={() => {
            setOpenMatchInfoModal(false);
          }}
        >
          <HighlightOffIcon style={{ width: 40, height: 40, color: "red" }} />
        </IconButton>
      </div>
      {showButtonsWhenUserWon && (
        <GuessModalButtons
          buttonsCondition={!showButtonsWhenUserWon}
          open={!showButtonsWhenUserWon}
          handleSubmit={() => {}}
          newGameButtonLabel={"New Match"}
          submitButtonDisabledCodition={false}
        />
      )}
    </div>
  );
}
