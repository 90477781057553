import React from "react";
import AnswerAni from "./HintsGameMode/AnswerAni";

export default function AnswerAnimation({
  isShowAni,
  isShowCorrect,
  handleAnimationFinish,
}) {
  return (
    <div className={`answer-ani-div ${isShowAni ? "show" : "notshow"}`}
      onClick={(e)=>{
        e.stopPropagation()
      }}
    >
      {isShowAni && isShowCorrect ? (
        <AnswerAni
          showAniWhenAnswer={isShowAni}
          showCorrectAni={true}
          handleAnimationDone={() => {
            handleAnimationFinish();
          }}
        />
      ) : (
        <AnswerAni
          showAniWhenAnswer={isShowAni}
          showCorrectAni={false}
          handleAnimationDone={() => {
            handleAnimationFinish();
          }}
        />
      )}
    </div>
  );
}
