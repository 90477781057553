import React, { useState } from "react";
import TextInputWithTranslationModal from "../TextInputWithTranslationModal";
import CategoryIcon from "@mui/icons-material/Category";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Toast from "../Toast";
import { areObjectsEqual } from "../../services/helperFunctions";
import useFormHook from "../../hooks/useFormHook";
import { languageResources } from "../../services/i18next";
import { addNewPlayer, editPlayer } from "../../services/api/player";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import CountrySelector from "../CountrySelector";
import SeasonsAccordion from "./SeasonsAccordion";

// should be same as mongodb model
const initialPlayerObj = {
  //this reduce func returns {en:"", ar:""}
  name: Object.keys(languageResources).reduce((acc, key) => {
    acc[key] = "";
    return acc;
  }, {}),
  country: "",
  teams: [], // [{teamId, season, onLoan}, ...];
};

export default function Player({ player }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const playerSchema = yup.object().shape({
    name: yup.object().shape({
      en: yup.string().required(1, t("playerPage.errors.nameMinErr")),
      ar: yup.string().required(1, t("playerPage.errors.nameMinErr")),
    }),
  });
  const [currentSeasonTeamSelected, setCurrentSeasonTeamSelected] = useState({
    season: `${new Date().getFullYear() - 1}/${new Date().getFullYear()}`,
    teamId: "",
    onLoan: false
  });
  const { formik, postMutation, editMutation, snackbar, handleSnackbarClose } =
    useFormHook(
      navigate,
      initialPlayerObj,
      player,
      addNewPlayer,
      editPlayer,
      playerSchema,
      "playerPage",
      "players"
    );
  return (
    <div style={{ width: "100%" }}>
      <Toast handleSnackbarClose={handleSnackbarClose} snackbar={snackbar} />
      <TextInputWithTranslationModal
        iconComponent={<CategoryIcon />}
        labelI18Key={"playerPage.addNewPlayerPage.firstTextFieldLabel"}
        formik={formik}
        fieldName={"name"} //For Player DB model, there is a field called "name:{en,ar}"
      />
      <div className="d-flex  align-item-center mt-5 mb-5 justify-content-between flex-wrap gap-3">
        <CountrySelector formik={formik} />
      </div>
      <div className="align-self-end mt-3">
        {/* <SeasonTeamSelector
            formik={formik}
            currentSeasonTeamSelected={currentSeasonTeamSelected}
            setCurrentSeasonTeamSelected={setCurrentSeasonTeamSelected}
          /> */}
      </div>
      <div className="mt-3">
        <SeasonsAccordion
          formik={formik}
          currentSeasonTeamSelected={currentSeasonTeamSelected}
          setCurrentSeasonTeamSelected={setCurrentSeasonTeamSelected}
        />
      </div>
      <div className="d-flex justify-content-center gap-5 mt-5">
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            player
              ? editMutation.mutate({
                  _id: player._id,
                  updatedPlayer: formik.values,
                })
              : postMutation.mutate(formik.values);
          }}
          disabled={
            (Object.keys(formik.errors).length > 0 ? true : false) ||
            areObjectsEqual(player, formik.values)
          }
        >
          {t("buttons.save")}
        </Button>
        <Button
          variant="contained"
          size="large"
          color="error"
          onClick={() => {
            navigate(-1);
          }}
        >
          {t("buttons.cancel")}
        </Button>
      </div>
    </div>
  );
}
