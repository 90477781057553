import React from "react";
import IconButton from "@mui/material/IconButton";
import LanguageIcon from "@mui/icons-material/Language";

export default function Navbar({ setOpen, title }) {
  return (
    <div
      style={{
        width: "100%",
        minHeight: 100,
        height: "2vw",
        // backgroundImage: "linear-gradient(to right, #b6fbff, #83a4d4)",
        backgroundColor: "#1976d2",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="nav-bar-div"
    >
      <h1
        className="text-center nav-bar-title"
        style={{ color: "white",  letterSpacing: "2px" }}
      >
        {title}
      </h1>
      {/* <div style={{ position: "relative" }}> */}
      <IconButton
        style={{ position: "absolute", right: 10 }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <LanguageIcon className="navbar-lang-icon" style={{ fontSize: 40, color: "white" }} />
      </IconButton>
      {/* </div> */}
    </div>
  );
}
