import React, { useState, useEffect } from "react";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import { createTheme } from "@mui/material/styles";
import createCache from "@emotion/cache";

export default function useMuiDirChangeHook(language) {
  const [theme, setTheme] = useState(
    createTheme({
      direction: language.dir,
      palette: {
        // mode: outerTheme.palette.mode,
      },
    })
  );
  const [cacheRtl, setCacheRtl] = useState(
    createCache({
      key: `mui${language.dir}`,
      stylisPlugins: [prefixer],
    })
  );
  useEffect(() => {
    setTheme(
      createTheme({
        direction: language.dir,
        palette: {
          // mode: outerTheme.palette.mode,
        },
      })
    );
    setCacheRtl(
      createCache({
        key: `mui${language.dir}`,
        stylisPlugins:
          language.dir === "rtl" ? [prefixer, rtlPlugin] : [prefixer],
      })
    );
  }, [language]);
  return { theme, cacheRtl };
}
