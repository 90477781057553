import React, { useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { languageResources } from "../services/i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export default function useFormHook(
  navigate,
  initialObject,
  objectToEdit,
  postNewObject,
  editObject,
  objectSchema,
  pageNameString,
  queryCacheName
) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const postMutation = useMutation({
    mutationFn: postNewObject,
    onSuccess: () => {
      queryClient.invalidateQueries(queryCacheName);
      setSnackbar({
        open: true,
        message: t(`${pageNameString}.success.post`),
        severity: "success",
      });
    },
    onError: () => {
      setSnackbar({
        open: true,
        message: t(`${pageNameString}.errors.post`),
        severity: "error",
      });
    },
  });

  const editMutation = useMutation({
    mutationFn: editObject,
    onSuccess: () => {
      queryClient.invalidateQueries(queryCacheName);
      setSnackbar({
        open: true,
        message: t(`${pageNameString}.success.edit`),
        severity: "success",
      });
    },
    onError: () => {
      setSnackbar({
        open: true,
        message: t(`${pageNameString}.errors.edit`),
        severity: "error",
      });
    },
  });

  const handleSnackbarClose = () => {
    if (snackbar.severity == "success") navigate(-1);

    setSnackbar({ open: false, message: "" });
  };

  const formik = useFormik({
    initialValues: objectToEdit ? objectToEdit : initialObject,
    validationSchema: objectSchema,
    validateOnMount: true,
  });
  return {
    t,
    formik,
    postMutation,
    editMutation,
    snackbar,
    handleSnackbarClose,
    setSnackbar,
  };
}
