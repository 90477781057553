import Container from "@mui/material/Container";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PlayerForm from "../../components/Player/PlayerForm";
import { Context as UserContext } from "../../context/UserContext";
import { useTranslation } from "react-i18next";
import { getPlayers } from "../../services/api/player";
import LoadingAnimation from "../../components/LoadingAnimation";

export default function EditPlayer() {
  const { t } = useTranslation();
  const {
    state: { language },
  } = useContext(UserContext);
  const { playerId } = useParams();
  const [player, setPlayer] = useState(undefined);

  useEffect(() => {
    const fetchPlayer = async () => {
      const resPlayer = await getPlayers(undefined, playerId);
      const playerWithCountryId = {
        ...resPlayer[0],
        country: resPlayer[0].country ? resPlayer[0].country._id : undefined,
      };
      setPlayer(playerWithCountryId);
    };
    fetchPlayer();
  }, []);
  if (!player) {
    return (
      <Container>
        <LoadingAnimation />
      </Container>
    );
  }
  return (
    <Container className="container">
      <p className="display-5 mt-4 mb-5">
        {t("playerPage.edit")} - {player.name[language.lng]}
      </p>
      <PlayerForm player={player} />
    </Container>
  );
}
