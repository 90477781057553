import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../locales/en.json";
import ar from "../locales/ar.json";

export const languageResources = {
  en: {
    translation: en,
    dir: "ltr",
    align: "left",
    text: { ar: "انجيليزي", en: "English" },
  },
  ar: {
    translation: ar,
    dir: "rtl",
    align: "right",
    text: { ar: "عربي", en: "Arabic" },
  },
};
const userLanguage = localStorage.getItem("language");
i18next.use(initReactI18next).init({
  debug: false,
  compatibilityJSON: "v3",
  lng: userLanguage ? userLanguage : "en",
  fallbackLng: "en",
  resources: languageResources,
});
