import { useRef } from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import {
  titleMenuAnimation,
  leftRightAnimations,
} from "../../services/gsapFunctions";

export default function useWheredTheyDuodGsapHook(isLoading,dir) {
  useGSAP(() => {
    if (isLoading) return;
    titleMenuAnimation();
    gsap.from(".parent-div", {
      opacity: 0,
      ease: "expo.inOut",
      delay: 2,
      duration: 2,
    });

    gsap.from(".title-in-parent", {
      opacity: 0,
      ease: "expo.inOut",
      delay: 3,
      duration: 1,
    });

    gsap.fromTo(
      ".left-side",
      {
        x: dir ==="ltr"?"-100%":"100%",
        opacity: 0,
      },
      { x: 0, opacity: 1, ease: "sine.inOut", duration: 1, delay: 4 }
    );

    gsap.fromTo(
      ".right-side",
      {
        x:  dir ==="ltr"?"100%":"-100%",
        opacity: 0,
      },
      { x: 0, opacity: 1, ease: "sine.inOut", duration: 1, delay: 4 }
    );

    gsap.fromTo(".button-div-for-ani",{opacity:0, y:"100%"},  { y: 0, opacity: 1, ease: "sine.inOut", duration: 1, delay: 4 })
  }, [isLoading]);
}
