import { Container } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import MatchForm from "../../components/Match/MatchForm";
import {
  lineups,
  reshapePlayers,
} from "../../services/Match/helperFunctionsAndObjects";
import dayjs from "dayjs";
import { addNewMatch } from "../../services/api/match";
import { reshapeMatchObject } from "../../services/helperFunctions";

const teamObj = {
  lineup: lineups[0],
  goals: -1,
  penaltyGoals: -1,
  team: undefined,
  nationalTeam: undefined,
  coach: undefined,
};

const initialMatchObj = {
  teamOne: {
    ...teamObj,
    players: reshapePlayers(lineups[0]), //will consist of rows and columns
  },
  teamTwo: {
    ...teamObj,
    players: reshapePlayers(lineups[0]), //will consist of rows and columns
  },
  stadium: undefined,
  matchDate: dayjs().toDate(),
  category: undefined,
  matchStage: undefined,
};

export default function AddNewMatch() {
  const { t } = useTranslation();

  return (
    <Container>
      <MatchForm
        title={"Add a New Match"}
        initialMatchObj={structuredClone(initialMatchObj)}
        handleFormSubmit={(matchObj) => {
          //new object to not cause issues, using {...matchObj} will create a shallow copy, meaning objects inside matctObj will get affected if the new object changes the same inner objects
          addNewMatch(reshapeMatchObject(structuredClone(matchObj)));
        }}
      />
    </Container>
  );
}
