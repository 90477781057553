import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useQuery } from "@tanstack/react-query";
import { getTeams } from "../../services/api/team";
import LoadingAnimation from "../../components/LoadingAnimation";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { getCategories } from "../../services/api/category";
import { getStadiums } from "../../services/api/stadium";
import SoccerFieldWithPlayers from "../../components/Match/SoccerFieldWithPlayers";
import PlayerInforModal from "../../components/Match/PlayerInforModal";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { getCoaches } from "../../services/api/coach";
import {
  lineups,
  categoriesThatAreNational,
} from "../../services/Match/helperFunctionsAndObjects";
import { getMatchStages } from "../../services/api/matchStage";
import Toast from "../Toast";
import useMatchFormHook from "../../hooks/useMatchFormHook";
import { useNavigate } from "react-router-dom";
import { areObjectsEqual } from "../../services/helperFunctions";
import { getNationalTeams } from "../../services/api/nationalTeams";
// import matchStagesData from "../../locales/matchStages.json";

export default function MatchForm({
  title,
  initialMatchObj,
  handleFormSubmit,
  isEdit,
}) {
  const [openPlayerModal, setOpenPlayerModal] = useState(false);
  const navigate = useNavigate();
  const {
    addMatchMutation,
    snackbar,
    handleSnackbarClose,
    ACTIONS,
    checkObjectIsIncomplete,
    dispatch,
    state,
    editMatchMutation,
  } = useMatchFormHook(
    navigate,
    {
      matchObj: initialMatchObj,
      currentTeamToModify: "teamOne",
      currentAutocompleteInpTeamObj: "",
      teamInputValue: "",
      stadiumInputValue: "",
      playerToBeSelectedRowNCol: { row: undefined, col: undefined },
      playersCounter: isEdit ? 22 : 0,
    },
    handleFormSubmit
  );

  const { isPending, error, data } = useQuery({
    queryKey: ["teams"],
    queryFn: () => {
      return getTeams();
    },
  });

  const {
    isPending: isPendingNationalTeam,
    error: errorNationalTeam,
    data: nationalTeamdata,
  } = useQuery({
    queryKey: ["nationalTeams"],
    queryFn: () => {
      return getNationalTeams();
    },
  });

  const {
    isPending: isPendingStadiums,
    error: errorStadium,
    data: stadiumData,
  } = useQuery({
    queryKey: ["stadiums"],
    queryFn: () => {
      return getStadiums();
    },
  });

  const {
    isPending: isPendingCategories,
    error: errorCategory,
    data: categoryData,
  } = useQuery({
    queryKey: ["categories"],
    queryFn: () => {
      return getCategories();
    },
  });

  const {
    isPending: isPendingCoaches,
    error: errorCoach,
    data: coachData,
  } = useQuery({
    queryKey: ["coaches"],
    queryFn: () => {
      return getCoaches();
    },
  });

  const {
    isPending: isPendingMatchStages,
    error: errorMatchStages,
    data: matchStagesData,
  } = useQuery({
    queryKey: ["matchStages"],
    queryFn: () => {
      return getMatchStages();
    },
  });

  //TODO: this is to be removed when API testing is done
  // const {
  //   isPending: isPendingPlayers,
  //   error: errorPlayers,
  //   data: playersData,
  // } = useQuery({
  //   queryKey: ["players"],
  //   queryFn: () => {
  //     return getPlayers();
  //   },
  // });

  // useEffect(() => {
  //   if (playersData) {
  //     dispatch({
  //       type: ACTIONS.AUTO_FILL_PLAYERS_FOR_TESTING_API,
  //       payload: playersData[0],
  //     });
  //   }
  // }, [playersData]);

  if (
    isPending ||
    isPendingStadiums ||
    isPendingCategories ||
    isPendingCoaches ||
    isPendingMatchStages ||
    isPendingNationalTeam
  ) {
    return <LoadingAnimation />;
  }

  if (
    error ||
    errorStadium ||
    errorCategory ||
    errorCoach ||
    errorMatchStages ||
    errorNationalTeam
  ) {
    return <h1>OOpsieee! Error fetching data</h1>;
  }

  return (
    <>
      <h1 className="display-4 mt-4 mb-5">{title}</h1>
      <div className="d-flex gap-5">
        <div style={{ flex: 2 }}>
          <div className="d-flex justify-content-around align-items-end flex-wrap row-gap-4 p-4">
            <FormControl style={{ flex: "20%" }}>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Team to Modify
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={state.currentTeamToModify}
                onChange={(e) => {
                  dispatch({
                    type: ACTIONS.CHANGE_TEAM_TO_MODIFY,
                    payload: e.target.value,
                  });
                }}
              >
                <FormControlLabel
                  value="teamOne"
                  control={<Radio />}
                  label="Team One"
                />
                <FormControlLabel
                  value="teamTwo"
                  control={<Radio />}
                  label="Team Two"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="row align-items-end">
            {(!state.matchObj.category ||
              !categoriesThatAreNational.includes(
                state.matchObj.category ? state.matchObj.category.name.en : ""
              )) && (
              <div className="col-6">
                <Autocomplete
                  options={data.map((et) => ({
                    label: et.name.en,
                    teamObj: et,
                  }))}
                  getOptionLabel={(team) => team.label}
                  isOptionEqualToValue={(option, value) =>
                    option.teamObj._id == value.teamObj._id
                  }
                  sx={{ width: "auto" }}
                  key={state.teamInputKey}
                  onChange={(e, obj) => {
                    dispatch({
                      type: ACTIONS.CHANGE_TEAM,
                      payload: obj ? obj.teamObj : "",
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Team" />
                  )}
                />
              </div>
            )}
            {categoriesThatAreNational.includes(
              state.matchObj.category ? state.matchObj.category.name.en : ""
            ) && (
              <div className="col-6">
                <Autocomplete
                  options={nationalTeamdata.map((et) => ({
                    label: et.name.en,
                    teamObj: et,
                  }))}
                  getOptionLabel={(team) => team.label}
                  isOptionEqualToValue={(option, value) =>
                    option.teamObj._id == value.teamObj._id
                  }
                  sx={{ width: "auto" }}
                  key={state.nationalTeamInputKey}
                  onChange={(e, obj) => {
                    dispatch({
                      type: ACTIONS.CHANGE_NATIONAL_TEAM,
                      payload: obj ? obj.teamObj : "",
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="National Team" />
                  )}
                />
              </div>
            )}
            <div
              className={`${
                state.matchObj.teamOne.goals == state.matchObj.teamTwo.goals &&
                state.matchObj.teamOne.goals > -1
                  ? "col-3"
                  : "col-6"
              }`}
            >
              <TextField
                label="Goals Scored"
                className="w-100"
                key={state.goalsInputKey}
                variant="outlined"
                onChange={(e) => {
                  dispatch({
                    type: ACTIONS.CHANGE_TEAM_GOALS_SCORED,
                    payload: Number(e.target.value),
                  });
                }}
              />
            </div>
            {state.matchObj.teamOne.goals == state.matchObj.teamTwo.goals &&
              state.matchObj.teamOne.goals > -1 && (
                <div className="col-3">
                  <TextField
                    label="Penalties Scored"
                    className="w-100"
                    key={state.penaltyGoalsInputKey}
                    variant="outlined"
                    onChange={(e) => {
                      dispatch({
                        type: ACTIONS.CHANGE_TEAM_PENALTY_GOALS_SCORED,
                        payload: Number(e.target.value),
                      });
                    }}
                  />
                </div>
              )}
            <div className="col-6 mt-3">
              <Autocomplete
                options={coachData.map((ec) => ({
                  label: ec.name.en,
                  coachObj: ec,
                }))}
                getOptionLabel={(coach) => coach.label}
                isOptionEqualToValue={(option, value) =>
                  option.coachObj._id == value.coachObj._id
                }
                sx={{ width: "auto" }}
                key={state.coachInputKey}
                onChange={(e, obj) => {
                  dispatch({
                    type: ACTIONS.CHANGE_TEAM_COACH,
                    payload: obj ? obj.coachObj : "",
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Coach" />
                )}
              />
            </div>
            <div className="col-6 mt-3" style={{ flex: "30%" }}>
              <Autocomplete
                options={stadiumData.map((eS) => ({
                  label: eS.name.en,
                  stadiumObj: eS,
                }))}
                key={state.stadiumInputKey}
                getOptionLabel={(stadium) => stadium.label}
                isOptionEqualToValue={
                  (option, value) => true
                  // option.teamObj._id == value.stadiumObj._id
                }
                sx={{ width: "auto" }}
                onChange={(e, obj) => {
                  dispatch({
                    type: ACTIONS.CHANGE_TEAM_STADIUM,
                    payload: obj ? obj.stadiumObj : "",
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Stadium" />
                )}
              />
            </div>
            <div className="col-6 mt-3">
              <InputLabel id="lineup-select">Lineup</InputLabel>
              <Select
                className="w-100"
                labelId="lineup-select"
                id="lineup-select"
                value={state.matchObj[state.currentTeamToModify].lineup}
                label="Lineup"
                onChange={(e) => {
                  dispatch({
                    type: ACTIONS.CHANGE_TEAM_LINEUP,
                    payload: e.target.value,
                  });
                }}
              >
                {lineups.map((eachLineup, idx) => (
                  <MenuItem value={eachLineup} key={idx + eachLineup}>
                    {eachLineup}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="col-6 mt-3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  defaultValue={dayjs()}
                  className="w-100"
                  onChange={(e) => {
                    dispatch({
                      type: ACTIONS.CHANGE_MATCH_DATE,
                      payload: e.toDate(),
                    });
                  }}
                  views={["year", "month", "day"]}
                />
              </LocalizationProvider>
            </div>
            <div className="col-6 mt-3">
              <Autocomplete
                options={categoryData.map((ec) => ({
                  label: ec.name.en,
                  categoryObj: ec,
                }))}
                getOptionLabel={(category) => category.label}
                isOptionEqualToValue={(option, value) =>
                  option.categoryObj._id == value.categoryObj._id
                }
                sx={{ width: "auto" }}
                key={state.categoryInputKey}
                onChange={(e, obj) => {
                  dispatch({
                    type: ACTIONS.CHANGE_MATCH_CATEGORY,
                    payload: obj ? obj.categoryObj : "",
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Category" />
                )}
              />
            </div>
            <div className="col-6 mt-3">
              <Autocomplete
                options={matchStagesData.map((eMs) => ({
                  label: eMs.name.en,
                  matchStageObj: eMs,
                }))}
                getOptionLabel={(matchStage) => matchStage.label}
                isOptionEqualToValue={(option, value) =>
                  option.matchStageObj._id == value.matchStageObj._id
                }
                sx={{ width: "auto" }}
                key={state.matchStageInputKey}
                onChange={(e, obj) => {
                  dispatch({
                    type: ACTIONS.CHANGE_MATCH_STAGE,
                    payload: obj ? obj.matchStageObj : "",
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Match Stage" />
                )}
              />
            </div>
            <div className="col-12 mt-5"></div>
            <div className="col-6 mt-5 text-end">
              <Button
                variant="contained"
                disabled={
                  isEdit
                    ? areObjectsEqual(state.matchObj, initialMatchObj) ||
                      checkObjectIsIncomplete(state)
                    : checkObjectIsIncomplete(state)
                }
                onClick={() => {
                  // handleFormSubmit(state.matchObj);
                  isEdit
                    ? editMatchMutation.mutate(state.matchObj)
                    : addMatchMutation.mutate(state.matchObj);
                }}
              >
                {isEdit ? "Edit" : "Create"}
              </Button>
            </div>
            <div className="col-6 mt-5 text-start">
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
        <div style={{}}>
          <div
            className="mb-2 d-flex flex-wrap gap-3"
            style={{ boxShadow: "none !important", width: 500 }}
          >
            {state.matchObj[state.currentTeamToModify].team ? (
              <Chip
                label={`Team: ${
                  state.matchObj[state.currentTeamToModify].team.name.en
                }`}
              ></Chip>
            ) : null}
            {state.matchObj[state.currentTeamToModify].nationalTeam ? (
              <Chip
                label={`National Team: ${
                  state.matchObj[state.currentTeamToModify].nationalTeam.name.en
                }`}
              ></Chip>
            ) : null}
            {state.matchObj[state.currentTeamToModify].goals != -1 ? (
              <Chip
                label={`Goals: ${
                  state.matchObj[state.currentTeamToModify].goals
                }`}
              ></Chip>
            ) : null}
            {state.matchObj[state.currentTeamToModify].penaltyGoals && state.matchObj[state.currentTeamToModify].penaltyGoals != -1 ? (
              <Chip
                label={`Penalty Goals: ${
                  state.matchObj[state.currentTeamToModify].penaltyGoals
                }`}
              ></Chip>
            ) : null}
            {state.matchObj[state.currentTeamToModify].coach ? (
              <Chip
                label={`Coach: ${
                  state.matchObj[state.currentTeamToModify].coach.name.en
                }`}
              ></Chip>
            ) : null}
            {state.matchObj.stadium ? (
              <Chip
                // className="mb-2"
                className="data-for-both-teams"
                label={`Stadium: ${state.matchObj.stadium.name.en}`}
              ></Chip>
            ) : null}

            {state.matchObj.matchStage ? (
              <Chip
                // className="mb-2"
                className="data-for-both-teams"
                label={`Match Stage: ${state.matchObj.matchStage.name.en}`}
              ></Chip>
            ) : null}

            {state.matchObj.category ? (
              <Chip
                // className="mb-2"
                className="data-for-both-teams"
                label={state.matchObj.category.name.en}
              ></Chip>
            ) : null}
            {state.matchObj.matchDate ? (
              <Chip
                // className="mb-2"
                className="data-for-both-teams"
                label={`${state.matchObj.matchDate
                  .toString()
                  .substring(0, 15)}`}
              ></Chip>
            ) : null}
            <br />
          </div>
          <SoccerFieldWithPlayers
            teamLineup={state.matchObj[state.currentTeamToModify].lineup}
            handlePlayerClick={(rowColObj) => {
              setOpenPlayerModal(true);
              dispatch({
                type: ACTIONS.CHANGE_PLAYER_TO_BE_SELECTED,
                payload: rowColObj,
              });
            }}
            players={state.matchObj[state.currentTeamToModify].players}
          />
        </div>
      </div>
      <PlayerInforModal
        open={openPlayerModal}
        setOpen={setOpenPlayerModal}
        handlePlayerSelection={(playerSelected) => {
          dispatch({
            type: ACTIONS.CHOOSE_A_PLAYER_IN_FORMATION,
            payload: {
              row: Number(state.playerToBeSelectedRowNCol.row),
              col: Number(state.playerToBeSelectedRowNCol.col),
              selectedPlayer: playerSelected,
              playersCounter: state.playersCounter + 1,
            },
          });
          setOpenPlayerModal(false);
        }}
      />
      {/* <CategoryForm /> */}
      <Toast snackbar={snackbar} handleSnackbarClose={handleSnackbarClose} />
      <style>
        {`.data-for-both-teams{
          background-color: lightgreen;
        }`}
      </style>
    </>
  );
}
