import React, { useState, useEffect } from "react";
import { Container } from "@mui/material";
import { useParams } from "react-router-dom";
import MatchForm from "../../components/Match/MatchForm";
import { addNewMatch, editMatch, getMatches } from "../../services/api/match";
import { reshapePlayersFromAPI } from "../../services/helperFunctions";
import LoadingAnimation from "../../components/LoadingAnimation";
import { reshapeMatchObject } from "../../services/helperFunctions";

export default function EditMatch() {
  const { matchId } = useParams();
  const [match, setMatch] = useState(undefined);

  useEffect(() => {
    const fetchMatch = async () => {
      const resMatch = await getMatches(matchId);
      let matchObj = resMatch[0];
      matchObj.teamOne.players = reshapePlayersFromAPI(
        matchObj.teamOne.players,
        matchObj.teamOne.lineup
      );
      matchObj.teamTwo.players = reshapePlayersFromAPI(
        matchObj.teamTwo.players,
        matchObj.teamTwo.lineup
      );
      setMatch(matchObj);
    };
    fetchMatch();
  }, []);

  if (!match) {
    return (
      <Container>
        <LoadingAnimation />
      </Container>
    );
  }

  return (
    <Container>
      <MatchForm
        title={
          "Edit Match " +
          `${
            match.teamOne.team
              ? match.teamOne.team.name.en
              : match.teamOne.nationalTeam.name.en
          }` +
          " VS " +
          `${
            match.teamTwo.team
              ? match.teamTwo.team.name.en
              : match.teamTwo.nationalTeam.name.en
          }`
        }
        initialMatchObj={match}
        isEdit={true}
        handleFormSubmit={(matchObj) => {
          //new object to not cause issues, using {...matchObj} will create a shallow copy, meaning objects inside matctObj will get affected if the new object changes the same inner objects
          editMatch(matchId, reshapeMatchObject(structuredClone(matchObj)));
        }}
      />
    </Container>
  );
}
