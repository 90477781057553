import React, { useContext, useMemo, useState } from "react";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import MenuIcon from "@mui/icons-material/Menu";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import FiberNewTwoToneIcon from "@mui/icons-material/FiberNewTwoTone";
import { useNavigate } from "react-router-dom";
import { Context as UserContext } from "../../context/UserContext";
import { useTranslation } from "react-i18next";

export default function CommonQuickAccessMenu({ handleNewGame, newMatchName }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: { language },
  } = useContext(UserContext);
  const actions = useMemo(
    () => [
      {
        icon: <HomeOutlinedIcon />,
        name: t("play.singlePlayerPage.quickAccessMenu.home"),
        onClick: () => {
          navigate("/play");
        },
      },
      {
        icon: <FiberNewTwoToneIcon />,
        name: newMatchName,
        onClick: () => {
          handleNewGame();
        },
      },
    ],
    [navigate]
  );

  const [open, setOpen] = useState(false);
  return (
    <div
      dir="ltr"
      style={{
        position: "relative",
        transform:
          language.dir === "ltr"
            ? "translate(-46px, -26px)"
            : "translate(0px, -26px)",
        zIndex: 99,
        flexDirection: language === "ltr" ? "row" : "row-reverse",
      }}
    >
      <SpeedDial
        ariaLabel="SpeedDial"
        sx={{ position: "absolute" }}
        icon={<MenuIcon />}
        direction="down"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            onClick={action.onClick}
            tooltipTitle={action.name}
            sx={{
              marginLeft: 4,
            }}
            tooltipOpen
            open={true}
          />
        ))}
      </SpeedDial>
      <style>
        {`
        .MuiFab-root {
            width:45px;
            height:45px;
        }

        #SpeedDial-actions {
            transform: translateX(-20px);
            position: absolute;
            top:40px;
            right: -90px;
            width: 152px;
            text-align: center;
        }

        .MuiSpeedDialAction-staticTooltipLabel {
                width: 100% !important;
                transform: ${language.dir === "rtl"? "translateX(200px)" : "none"};
              }
        `}
      </style>
    </div>
  );
}
