import React from "react";
import TextInputWithTranslationModal from "../TextInputWithTranslationModal";
import CategoryIcon from "@mui/icons-material/Category";
import Button from "@mui/material/Button";
import useCategoryFormHook from "../../hooks/useCategoryFormHook";
import { useNavigate } from "react-router-dom";
import Toast from "../Toast";
import { areObjectsEqual } from "../../services/helperFunctions";
import useFormHook from "../../hooks/useFormHook";
import { languageResources } from "../../services/i18next";
import { addNewCategory, editCategory } from "../../services/api/category";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

// should be same as mongodb model
const initialCategoryObj = {
  //this reduce func returns {en:"", ar:""}
  name: Object.keys(languageResources).reduce((acc, key) => {
    acc[key] = "";
    return acc;
  }, {}),
};

export default function CategoryForm({ category }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const categorySchema = yup.object().shape({
    name: yup.object().shape({
      en: yup.string().required(1, t("categoryPage.errors.nameMinErr")),
      ar: yup.string().required(1, t("categoryPage.errors.nameMinErr")),
    }),
  });
  const {
    formik,
    postMutation,
    editMutation,
    snackbar,
    handleSnackbarClose,
  } = useFormHook(
    navigate,
    initialCategoryObj,
    category,
    addNewCategory,
    editCategory,
    categorySchema,
    "categoryPage",
    "categories"
  );

  return (
    <div style={{ width: "100%" }}>
      <Toast handleSnackbarClose={handleSnackbarClose} snackbar={snackbar} />
      <TextInputWithTranslationModal
        iconComponent={<CategoryIcon />}
        labelI18Key={"categoryPage.addNewCatPage.firstTextFieldLabel"}
        formik={formik}
        fieldName={"name"} //For Category DB model, there is a field called "name:{en,ar}"
      />
      <div className="d-flex justify-content-center gap-5 mt-5">
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            category
              ? editMutation.mutate({
                  _id: category._id,
                  updatedCategory: formik.values,
                })
              : postMutation.mutate(formik.values);
          }}
          disabled={
            (Object.keys(formik.errors).length > 0 ? true : false) ||
            areObjectsEqual(category, formik.values)
          }
        >
          {t("buttons.save")}
        </Button>
        <Button
          variant="contained"
          size="large"
          color="error"
          onClick={() => {
            navigate(-1);
          }}
        >
          {t("buttons.cancel")}
        </Button>
      </div>
    </div>
  );
}
