import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function GuessModalButtons({
  open,
  buttonsCondition,
  submitButtonDisabledCodition,
  handleSubmit,
  newGameButtonLabel,
  fromFillTheLineupMode,
  handleAnotherGameClick
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="buttons-div">
      {buttonsCondition ? (
        <button
          variant="contained"
          className={`mt-2 style-one-button submit-btn ${open ? "show" : ""}`}
          disabled={submitButtonDisabledCodition}
          onClick={handleSubmit}
        >
          {t("play.singlePlayerPage.hintsGameModePage.submitButton")}
        </button>
      ) : (
        !fromFillTheLineupMode && (
          <>
            <button
              className="style-two-button mt-5"
              onClick={() => {
                navigate("/play");
              }}
            >
              {t(
                "play.singlePlayerPage.hintsGameModePage.hintsText.modal.homeBtn"
              )}
            </button>
            <button
            
              className="style-one-button mt-5"
              onClick={() => {
                handleAnotherGameClick();
              }}
            >
              {newGameButtonLabel}
            </button>
          </>
        )
      )}
    </div>
  );
}
