import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "@mui/material";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getCategories, deleteCategory } from "../../services/api/category";
import LoadingAnimation from "../../components/LoadingAnimation";
import CustomTable from "../../components/CustomTable";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import Toast from "../../components/Toast";

export default function Category() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const { isPending, error, data } = useQuery({
    queryKey: ["categories"],
    queryFn: getCategories,
  });
  const deleteCategoryMutation = useMutation({
    mutationFn: deleteCategory,
    onSuccess: () => {
      queryClient.invalidateQueries("categories");
      setSnackbar({
        open: true,
        message: t("categoryPage.success.delete"),
        severity: "success",
      });
    },
    onError: () => {
      setSnackbar({
        open: true,
        message: t("categoryPage.errors.delete"),
        severity: "error",
      });
    },
  });
  if (isPending) {
    return <LoadingAnimation />;
  }
  if (data) {
    return (
      <Container>
        <h1 className="mt-5"> {t("categoryPage.title")}</h1>
        <div className="d-flex justify-content-between mt-5 mb-2">
          <div></div>
          <div>
            <Link to={"add-new"}>
              <Button variant="contained">
                {t("categoryPage.add")} <AddIcon />
              </Button>
            </Link>
          </div>
        </div>
        <CustomTable
          columns={[
            { headerName: t("categoryPage.columns.item1") },
            { headerName: t("categoryPage.columns.item2") },
          ]}
          rows={data.map((eachCat) => ({
            categoryName: eachCat.name.en,
            categoryNameAr: eachCat.name.ar,
            _id: eachCat._id,
          }))}
          handleDelete={(id) => {
            deleteCategoryMutation.mutate(id);
          }}
          pageName={"categoryPage"}
          // getRowId={(row) => row._id}//must be unique
        />
        <Toast
          handleSnackbarClose={() => {
            setSnackbar({ open: false, message: "" });
          }}
          snackbar={snackbar}
        />
      </Container>
    );
  }
}
