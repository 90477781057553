import React, { useEffect, useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, TablePagination } from "@mui/material";
import { Context as UserContext } from "../context/UserContext";
import { globalColors } from "../services/colors";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { Link } from "react-router-dom";
import { Context as UserTableOptionsContext } from "../context/TablePageOptions";

import "../styles/table.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: globalColors.primary,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: globalColors.secondary,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CustomTable({ rows, columns, handleDelete, pageName, handleDetailedView }) {
  const { t } = useTranslation();
  const { state, setUserTableOptionsForPage } = useContext(
    UserTableOptionsContext
  );
  const [page, setPage] = React.useState(state[pageName].page);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    state[pageName].rowsPerPage
  );
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [idToDelete, setIdToDelete] = useState(undefined);

  const openModal = () => {
    setDeleteModalOpen(true);
  };

  const closeModal = () => {
    setDeleteModalOpen(false);
  };
  const {
    state: { language },
  } = useContext(UserContext);

  const currentRows = rows.filter((r, ind) => {
    return ind >= rowsPerPage * page && ind < rowsPerPage * (page + 1);
  });

  const changeTablePaginationText = () => {
    //css-levciy-MuiTablePagination-displayedRows
    const temp = document.getElementsByClassName(
      "css-pdct74-MuiTablePagination-selectLabel"
    );
    const temp2 = document.getElementsByClassName(
      "css-levciy-MuiTablePagination-displayedRows"
    );
    if (temp.length > 0 && temp2.length > 0) {
      temp[0].innerText =
        language.lng === "ar" ? "عدد الصفوف في الصفحة:" : temp[0].innerText;
      temp2[0].innerText =
        language.lng === "ar"
          ? temp2[0].innerText.replace("of", "من")
          : temp2[0].innerText;
      // temp[0].innerHTML = "TEST PLS WORK";
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setUserTableOptionsForPage(pageName, {
      page: newPage,
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setUserTableOptionsForPage(pageName, {
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };
  useEffect(() => {
    changeTablePaginationText();
  }, [language, page]);

  useEffect(() => {
    setPage(state[pageName].page);
  }, [state[pageName].page]);
  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            {columns.map((eachCol, idx) => (
              <StyledTableCell key={idx} align={language.align}>
                {eachCol.headerName}
              </StyledTableCell>
            ))}
            <StyledTableCell align={language.align}>
              {t("table.actions")}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentRows.map((row, idx) => {
            const values = Object.values(row);
            return (
              <StyledTableRow key={idx}>
                {values.map(
                  (eachVal, idx2) =>
                    row["_id"] != eachVal && (
                      <StyledTableCell
                        key={idx2}
                        component="th"
                        scope="row"
                        align={language.align}
                      >
                        {eachVal}
                      </StyledTableCell>
                    )
                )}
                <StyledTableCell
                  component="th"
                  scope="row"
                  align={language.align}
                >
                  <Link to={`edit/${row["_id"]}`}>
                    <IconButton
                      sx={{ rotate: language.dir === "ltr" ? 0 : "270deg" }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Link>
                  <IconButton
                    onClick={() => {
                      setIdToDelete(row["_id"]);
                      openModal();
                      // handleDelete(row["_id"]);
                    }}
                  >
                    <DeleteIcon sx={{ color: "red" }} />
                  </IconButton>
                  {handleDetailedView && <IconButton
                    onClick={() => {
                      handleDetailedView(row["_id"]);
                    }}
                  >
                    <RemoveRedEyeIcon sx={{  }} />
                  </IconButton>}
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <DeleteConfirmationModal
        modalOpen={deleteModalOpen}
        closeModal={closeModal}
        idToDelete={idToDelete}
        handleDelete={handleDelete}
      />
    </TableContainer>
  );
}
