import { useRef } from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";

const animationForPlayers = (playerCircles, hiddenCircle, isDelay, dir) => {
  let xTemp =
    dir == "ltr"
      ? -hiddenCircle.getBoundingClientRect().left - 200
      : hiddenCircle.getBoundingClientRect().left - 200;
  gsap.fromTo(
    `.player-circle-for-ani`,
    {
      x: xTemp,
      y: Math.abs(hiddenCircle.getBoundingClientRect().top) + 700,
    },

    {
      x: 0,
      y: 0,
      duration: 1,
      delay: isDelay ? 3 : 0,
      stagger: 0.2,
      ease: "sine.inOut",
    }
  );
  gsap.fromTo(
    ".player-tooltip-for-ani",
    { opacity: 0 },
    { opacity: 1, delay: isDelay ? 7 : 4 }
  );
};

export default function useLineupGsapHook(
  isLoading,
  matchInfoModalWidthSizeThreshold,
  openMatchInfoModal,
  dir
) {
  const isAniPlayedRef = useRef(false);
  useGSAP(() => {
    if (isLoading) return;

    gsap.from(".title-menu-div", {
      opacity: 0,
      duration: 1,
      ease: "sine.inOut",
    });

    gsap.fromTo(
      ".left-side",
      {
        x:
          window.innerWidth > matchInfoModalWidthSizeThreshold
            ? dir === "ltr"
              ? "-100%"
              : "100%"
            : 0,
        opacity: 0,
      },
      { x: 0, opacity: 1, ease: "sine.inOut", duration: 2, delay: 1 }
    );

    gsap.fromTo(
      ".right-side",
      {
        x:
          window.innerWidth > matchInfoModalWidthSizeThreshold
            ? dir === "ltr"
              ? "100%"
              : "-100%"
            : 0,
        opacity: 0,
      },
      { x: 0, opacity: 1, ease: "sine.inOut", duration: 2, delay: 1 }
    );
  }, [isLoading]);

  useGSAP(() => {
    const playerCircles = document.querySelectorAll(".player-circle-for-ani");
    const hiddenCircle = document.querySelector(".centered-circle-for-ani");
    if (
      window.innerWidth < matchInfoModalWidthSizeThreshold &&
      openMatchInfoModal &&
      !isAniPlayedRef.current
    ) {
      playerCircles.forEach((eachCircle) => {
        eachCircle.classList.add("opacity-0");
      });
    }
    if (
      !isLoading &&
      window.innerWidth < matchInfoModalWidthSizeThreshold &&
      !openMatchInfoModal &&
      !isAniPlayedRef.current
    ) {
      playerCircles.forEach((eachCircle) => {
        eachCircle.classList.add("opacity-100");
      });
      animationForPlayers(playerCircles, hiddenCircle, false, dir);
      isAniPlayedRef.current = true;
    } else if (
      !isLoading &&
      window.innerWidth >= matchInfoModalWidthSizeThreshold &&
      !isAniPlayedRef.current
    ) {
      animationForPlayers(playerCircles, hiddenCircle, true, dir);
      isAniPlayedRef.current = true;
    }
  }, [isLoading, openMatchInfoModal]);

  return;
}
