import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import IconButton from "@mui/material/IconButton";
import { Context as UserContext } from "../../context/UserContext";
import { useTranslation } from "react-i18next";
import DetailedViewModal from "../DetailedViewModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",

  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function DetailedTeamViewModal({ open, setOpen, teamToView }) {
  const {
    state: { language },
  } = useContext(UserContext);
  const { t } = useTranslation();
  if (!teamToView) return;
  return (
    <DetailedViewModal open={open} setOpen={setOpen} customStyle={style}>
      <h4 className="text-center">{t("teamPage.detailedView.title")}</h4>
      <div className="d-flex gap-3 flex-wrap mt-5 align-items-center">
        <p id="modal-modal-description" sx={{ mt: 2 }}>
          <b>{t("teamPage.detailedView.id")}:</b> {teamToView._id}
          <IconButton
            onClick={() => {
              navigator.clipboard.writeText(teamToView._id);
            }}
          >
            <ContentPasteIcon />
          </IconButton>
        </p>
        <p id="modal-modal-description" sx={{ mt: 2 }}>
          <b>{t("teamPage.detailedView.name")}:</b>
          {teamToView.name[language.lng]}
          <IconButton
            onClick={() => {
              navigator.clipboard.writeText(teamToView.name[language.lng]);
            }}
          >
            <ContentPasteIcon />
          </IconButton>
        </p>
      </div>
    </DetailedViewModal>
  );
}
