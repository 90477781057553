import { gsap } from "gsap";
export const titleMenuAnimation = () => {
  gsap.fromTo(
    ".title-menu-div",
    {
      opacity: 0,
    },
    { opacity: 1, duration: 1, ease: "sine.inOut" }
  );
};
