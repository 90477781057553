import GoogleIcon from "@mui/icons-material/Google";
import { signInWithPopup } from "firebase/auth";
import { auth, provider } from "../../config/firebase";
import { login } from "../../services/api/auth";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const navigate = useNavigate();
  const loginWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const token = await result.user.getIdToken();
      await login(token);
      navigate("/fbk-admin");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div
      style={{
        width: "100%",
        height: "20vh",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <div
        className="shadow-lg"
        style={{
          width: 400,
          height: 150,
          //   backgroundColor: "#e7eeff",
          borderRadius: 20,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <div
          className="p-3 sign-in-google-box"
          style={{
            border: "1px solid black",
            borderRadius: 10,
          }}
          onClick={()=>{
            loginWithGoogle();
          }}
        >
          <span>
            Login with Google <GoogleIcon />
          </span>
        </div>
      </div>
      <style>
        {`
            .sign-in-google-box:hover{
                background-color: black;
                color: white;
                cursor: pointer;
                border-color:white !important;
                scale: 1.1;
            }
        `}
      </style>
    </div>
  );
}
