import { baseServerUrl } from "./globalVars";
import { getFBToken } from "./auth";
export const getMatches = async (getMatchById, fillTheLineupGameMode) => {
  let query = "";
  if (getMatchById) {
    query = "?_id=" + getMatchById;
  } else if (fillTheLineupGameMode) {
    query = "?fillTheLineup=true";
  }
  const token = await getFBToken();
  const res = await fetch(`${baseServerUrl}/match${query ? query : ""}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return await res.json();
};

export const getMatchesByPOST = async (fillTheLineupObj) => {
  //no fb token needed for this
  // We're gonna fetch matches by POST cuz we can't use body in GET
  if (!fillTheLineupObj.matchesFinished) {
    return await getMatches(undefined, true);
  }
  const response = await fetch(
    `${baseServerUrl}/match/get-matches-fill-line-up`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        isCached: fillTheLineupObj.isCached,
        matchesFinished: fillTheLineupObj.matchesFinished,
      }),
    }
  );
  return response.json();
};

export const addNewMatch = async (matchObj) => {
  const token = await getFBToken();
  const response = await fetch(`${baseServerUrl}/match`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(matchObj),
  });
  return response.data;
};

export const deleteMatch = async (id) => {
  const token = await getFBToken();
  const response = await fetch(`${baseServerUrl}/match/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const editMatch = async (_id, obj) => {
  const token = await getFBToken();
  const response = await fetch(`${baseServerUrl}/match/${_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(obj),
  });
  return response.data;
};
