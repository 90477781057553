import React from "react";

export default function EnglandFlag() {
  return (
    <svg width={40} height={40} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 15">
      <rect width="25" height="15" fill="#FFF" />
      <g fill="#CE1124">
        <rect width="3" height="15" x="11" />
        <rect width="25" height="3" y="6" />
      </g>
    </svg>
  );
}
