import Container from "@mui/material/Container";
import React from "react";
import { useTranslation } from "react-i18next";
import PlayerForm from "../../components/Player/PlayerForm";

export default function AddNewPlayer() {
  const { t } = useTranslation();
  return (
    <Container>
      <h1 className="display-4 mt-4 mb-5">{t("playerPage.add")}</h1>
      <PlayerForm />
    </Container>
  );
}
