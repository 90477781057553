import React, { useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { languageResources } from "../services/i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addNewCategory, editCategory } from "../services/api/category";


// should be same as mongodb model
const initialCategoryObj = {
  //this reduce func returns {en:"", ar:""}
  name: Object.keys(languageResources).reduce((acc, key) => {
    acc[key] = "";
    return acc;
  }, {}),
};

export default function useCategoryFormHook(navigate, category) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const categorySchema = yup.object().shape({
    name: yup.object().shape({
      en: yup.string().required(1, t("categoryPage.errors.nameMinErr")),
      ar: yup.string().required(1, t("categoryPage.errors.nameMinErr")),
    }),
  });

  const addCategoryMutation = useMutation({
    mutationFn: addNewCategory,
    onSuccess: () => {
      queryClient.invalidateQueries("categories");
      setSnackbar({
        open: true,
        message: t("categoryPage.success.postCategorySuccess"),
        severity: "success",
      });
    },
    onError: () => {
      setSnackbar({
        open: true,
        message: t("categoryPage.errors.postCategoryErr"),
        severity: "error",
      });
    },
  });

  const editCategoryMutation = useMutation({
    mutationFn: editCategory,
    onSuccess: () => {
      queryClient.invalidateQueries("categories");
      setSnackbar({
        open: true,
        message: t("categoryPage.success.editCategorySuccess"),
        severity: "success",
      });
    },
    onError: () => {
      setSnackbar({
        open: true,
        message: t("categoryPage.errors.editCategoryErr"),
        severity: "error",
      });
    },
  });

  const handleSnackbarClose = () => {
    if (snackbar.severity == "success") navigate(-1);

    setSnackbar({ open: false, message: "" });
  };

  const formik = useFormik({
    initialValues: category ? category : initialCategoryObj,
    validationSchema: categorySchema,
    validateOnMount: true,
  });
  return {
    t,
    formik,
    addCategoryMutation,
    editCategoryMutation,
    snackbar,
    handleSnackbarClose,
    setSnackbar,
  };
}
