import { jaroDistance, tverskyDistance } from "extra-string";

//checks if two objects are equal or not.
export const areObjectsEqual = (obj1, obj2) => {
  // Check if both values are objects
  if (
    typeof obj1 === "object" &&
    typeof obj2 === "object" &&
    obj1 !== null &&
    obj2 !== null
  ) {
    // Get keys of both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if the number of keys is the same
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Iterate through keys and recursively compare values
    for (const key of keys1) {
      if (!areObjectsEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    // If all key-value pairs are equal, objects are equal
    return true;
  } else {
    // If values are not objects, perform a simple equality check
    return obj1 === obj2;
  }
};
export const jaroDistanceSearch = (arrayOfStrings, userQuery) => {
  // tverskyDistance()
  return arrayOfStrings
    .map((ep) => ({
      string: ep,
      probability: tverskyDistance(
        ep.toLowerCase(),
        userQuery.toLowerCase(),
        3,
        0.3,
        0.7
      ),
    }))
    .sort((a, b) => a.probability - b.probability)
    .map((eObj) => eObj.string);
  // console.log("JARO", arrayOfStrings[0], userQuery);
  // console.log(jaroDistance(arrayOfStrings[0], userQuery));
};

// search function:
export function searchStrings(strings, query) {
  const matches = strings
    .map((str) => ({
      string: str,
      score: str
        .split(" ")
        .reduce(
          (accu, currentValue) =>
            calculateScore(currentValue.toLowerCase(), query.toLowerCase()),
          0
        ),
    }))
    .sort((a, b) => {
      // Prioritize exact matches
      if (a.string.toLowerCase() === query.toLowerCase()) {
        return -1;
      } else if (b.string.toLowerCase() === query.toLowerCase()) {
        return 1;
      }
      // // Prioritize matches where the query appears at the beginning of the string
      // const aStartsWithQuery = a.string
      //   .toLowerCase()
      //   .startsWith(query.toLowerCase());
      // const bStartsWithQuery = b.string
      //   .toLowerCase()
      //   .startsWith(query.toLowerCase());
      // if (aStartsWithQuery && !bStartsWithQuery) {
      //   return -1;
      // } else if (!aStartsWithQuery && bStartsWithQuery) {
      //   return 1;
      // }
      // If not exact matches or query appearing at the beginning, sort by similarity score
      return b.score - a.score;
    })
    .map((match) => match.string);

  return matches;
}

function calculateScore(string, query) {
  const m = string.length;
  const n = query.length;
  const matchingDistance = Math.floor(Math.max(m, n) / 2) - 1;

  let matches = 0;
  let transpositions = 0;
  let previousMatchIndex = -1;

  for (let i = 0; i < m; i++) {
    const start = Math.max(0, i - matchingDistance);
    const end = Math.min(i + matchingDistance + 1, n);

    for (let j = start; j < end; j++) {
      if (string[i] === query[j]) {
        matches++;

        if (previousMatchIndex !== -1 && j < previousMatchIndex) {
          transpositions++;
        }

        previousMatchIndex = j;
        break;
      }
    }
  }

  if (matches === 0) {
    return 0;
  }

  const jaroDistance =
    (matches / m + matches / n + (matches - transpositions / 2) / matches) / 3;

  const prefixLength = calculatePrefixLength(string, query);
  const scalingFactor = 0.1;
  const jaroWinklerDistance =
    jaroDistance + prefixLength * scalingFactor * (1 - jaroDistance);

  return jaroWinklerDistance;
}

function calculatePrefixLength(string, query) {
  const maxPrefixLength = 4;
  const commonPrefixLength = string
    .substring(0, maxPrefixLength)
    .split("")
    .filter((char, index) => char === query[index]).length;

  return Math.min(maxPrefixLength, commonPrefixLength);
}

//  **** For Match Page   ****
const reshapePlayersToSingleArray = (players) => {
  const temp = [];
  players.forEach((eachArray) => {
    eachArray.forEach((eachPlayer) => {
      temp.push(eachPlayer._id);
    });
  });
  return temp;
};

export const reshapeMatchObject = (matchObj) => {
  try {
    
    matchObj.teamOne.players = reshapePlayersToSingleArray(
      matchObj.teamOne.players
    );
    matchObj.teamOne.coach = matchObj.teamOne.coach._id;
    matchObj.teamOne.team &&  (matchObj.teamOne.team = matchObj.teamOne.team._id);
    matchObj.teamOne.nationalTeam && (matchObj.teamOne.team = matchObj.teamOne.nationalTeam._id);
    matchObj.teamTwo.players = reshapePlayersToSingleArray(
      matchObj.teamTwo.players
    );
    matchObj.teamTwo.team &&   (matchObj.teamTwo.team = matchObj.teamTwo.team._id);
    matchObj.teamTwo.nationalTeam &&   (matchObj.teamTwo.nationalTeam = matchObj.teamTwo.nationalTeam._id);
    matchObj.teamTwo.coach = matchObj.teamTwo.coach._id;

    matchObj.category = matchObj.category._id;
    matchObj.stadium = matchObj.stadium._id;
    matchObj.matchStage = matchObj.matchStage._id;

    return matchObj;
  } catch (error) {
    console.log(error);
  }
};

export const reshapePlayersFromAPI = (players, lineup) => {
  let newPlayers = [[players[0]]]; // players[0] will always be the GK
  let idx = 1;
  lineup.split("-").forEach((eachNumberOfPlayersInRow) => {
    let tempArr = [];
    for (let i = 0; i < eachNumberOfPlayersInRow; i++) {
      tempArr.push(players[idx]);
      idx += 1;
    }
    newPlayers.push(tempArr);
  });
  return newPlayers;
};
// **** End For Match Page   ****
