import createDataContext from "./createDataContext";
import { languageResources } from "../services/i18next"; //this contains the list of supported languages

export const storageName = "language";

const userReducer = (state, action) => {
  switch (action.type) {
    case "set_language":
      return { ...state, language: action.payload };
    case "set_user_details":
      return { ...state, user: action.payload };
    default:
      return state;
  }
};

const setLanguage = (dispatch) => {
  return (selectedLanguage) => {
    if (!selectedLanguage) {
      let language = "en";
      if (!localStorage.getItem(storageName)) {
        localStorage.setItem(storageName, language);
      } else {
        language = localStorage.getItem(storageName);
      }
      document
        .getElementsByTagName("html")[0]
        .setAttribute("dir", languageResources[language].dir);
      return dispatch({
        type: "set_language",
        payload: {
          lng: language,
          dir: languageResources[language].dir,
          align: languageResources[language].align,
        },
      });
    }
    document
      .getElementsByTagName("html")[0]
      .setAttribute("dir", languageResources[selectedLanguage].dir);
    localStorage.setItem(storageName, selectedLanguage);
    dispatch({
      type: "set_language",
      payload: {
        lng: selectedLanguage,
        dir: languageResources[selectedLanguage].dir,
        align: languageResources[selectedLanguage].align,
      },
    });
  };
};

const setUserDetails = (dispatch) => {
  return (accessToken, email, name) => {
    dispatch({
      type: "set_user_details",
      payload: { token: accessToken, email, name },
    });
  };
};

const storedLanguage = localStorage.getItem(storageName);
const initialObjectLng = storedLanguage
  ? {
      lng: storedLanguage,
      dir: languageResources[storedLanguage].dir,
      align: languageResources[storedLanguage].align,
    }
  : { lng: "en", dir: "ltr", align: "left" };
export const { Context, Provider } = createDataContext(
  userReducer,
  {
    setLanguage,
    setUserDetails,
  },
  {
    language: initialObjectLng,
    user: { token: undefined, email: "", name: "" },
  }
);
