import { Routes, Route, useLocation } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Player from "./pages/Player/Player";
import Team from "./pages/Team/Team";
import GameHome from "./pages/Game/GameHome";
import Category from "./pages/Category/Category";
import { Provider as UserProvider } from "./context/UserContext";
import { Provider as UserTableOptionsProvider } from "./context/TablePageOptions";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AddNewCategory from "./pages/Category/AddNewCategory";
import EditCategory from "./pages/Category/EditCategory";
import AddNewTeam from "./pages/Team/AddNewTeam";
import EditTeam from "./pages/Team/EditTeam";
import EditPlayer from "./pages/Player/EditTeam";
import AddNewPlayer from "./pages/Player/AddNewPlayer";
import SingleplayerHome from "./pages/Game/Singleplayer/SingleplayerHome";
import HintsGameMode from "./pages/Game/Singleplayer/HintsGameMode";
import WheredTheyDuodGameMode from "./pages/Game/Singleplayer/WheredTheyDuodGameMode";
import FillTheLineup from "./pages/Game/Singleplayer/FillTheLineup";
import "./styles/muiInputs.css";
import Match from "./pages/Match/Match";
import AddANewMatch from "./pages/Match/AddANewMatch";
import EditMatch from "./pages/Match/EditMatch";
import Login from "./pages/Auth/Login";
import { useEffect } from "react";
import ResolveAuth from "./pages/ResolveAuth";

const queryClient = new QueryClient();

function App() {
  const location = useLocation();
  useEffect(() => {
    if (!location.pathname.includes("fbk-admin")) {
      document.querySelector("body").classList.add("general-bg-img");
    }
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <UserTableOptionsProvider>
          {!location.pathname.includes("fbk-admin") ? (
            <Routes>
              <Route path="/play">
                <Route index element={<GameHome />} />
                <Route path="single-player">
                  <Route index element={<SingleplayerHome />} />
                  <Route path="hints" element={<HintsGameMode />} />
                  <Route
                    path="where'd-they-duo'd"
                    element={<WheredTheyDuodGameMode />}
                  />
                  <Route path="fill-the-lineup" element={<FillTheLineup />} />
                </Route>
              </Route>
            </Routes>
          ) : (
            <Sidebar>
              <Routes>
                <Route element={<ResolveAuth />}>
                  <Route path="/fbk-admin">
                    <Route path="player">
                      <Route index element={<Player />} />
                      <Route path="add-new" element={<AddNewPlayer />} />
                      <Route path="edit/:playerId" element={<EditPlayer />} />
                    </Route>
                    <Route path="team">
                      <Route index element={<Team />} />
                      <Route path="add-new" element={<AddNewTeam />} />
                      <Route path="edit/:teamId" element={<EditTeam />} />
                    </Route>
                    <Route path="category">
                      <Route index element={<Category />} />
                      <Route path="add-new" element={<AddNewCategory />} />
                      <Route path="edit/:catId" element={<EditCategory />} />
                    </Route>
                    <Route path="match">
                      <Route index element={<Match />} />
                      <Route path="add-new" element={<AddANewMatch />} />
                      <Route path="edit/:matchId" element={<EditMatch />} />
                    </Route>
                  </Route>
                </Route>
                <Route path="/fbk-admin/login" element={<Login />} index />

              </Routes>
            </Sidebar>
          )}

          {/* <Route path="/fbk-admin" element={<ResolveAuth />} /> */}
          {/* <Route path="/fbk-admin/player" element={<Player />} /> */}
          {/* <Route path="/fbk-admin/team" element={<Team />} /> */}
          {/* <Route path="/fbk-admin/category" element={<Category />} /> */}
        </UserTableOptionsProvider>
      </UserProvider>
    </QueryClientProvider>
  );
}

export default App;
