import React, { useState, useEffect } from "react";

export default function useTypingAnimationHook(open, text) {

  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const resetText = () => {
    setCurrentIndex(0);
    setCurrentText("");
  };
  useEffect(() => {
    if(!open){
      return;
    }
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, 70);

      return () => clearTimeout(timeout);
    }
  }, [currentIndex, text, open]);
  useEffect(() => {
    if (!open) {
      setCurrentText("");
    } else {
      setCurrentIndex(0);
      setCurrentText("");
    }
  }, [open]);

  return { currentText, resetText };
}
