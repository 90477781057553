import React, { useRef, useState } from "react";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { globalColors } from "../../services/colors";
import "../../styles/Game/soccerFieldWithPlayers.css";
import { useTranslation } from "react-i18next";

export default function SoccerFieldWithPlayers({
  teamLineup,
  handlePlayerClick,
  players,
}) {
  const {t} = useTranslation();
  const getPlayerName = (row, col) => {
    return players[row][col].name
      ? players[row][col].name.en
      : players[row][col];
  };

  return (
    <div
      id="soccerFieldWithPlayers"
      dir="ltr"
      style={{
        position: "relative",
      }}
    >
      <img src="/img/soccer/Staduim_Pitch (2).png" style={{ width: "100%" }} />
      <div
        className="stadium-div"
        style={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          display: "flex",
          flexDirection: "column-reverse",
          height: "100%",
          justifyContent: "space-between",
          paddingTop: 70,
          overflow:"hidden"
        }}
      >
        {`1-${teamLineup}`.split("-").map((eNumber, idx) => (
          <div className="d-flex justify-content-around" key={idx}>
            {[...Array(Number(eNumber)).keys()].map((ePlayer, idx2) => {
              return (
                <IconButton
                  className={`p-0 player-circle-for-ani`}
                  key={idx2}
                  style={{ display: "flex", flexDirection: "column", }}
                  onClick={() => {
                    handlePlayerClick &&
                      handlePlayerClick({ row: idx, col: ePlayer });
                  }}
                >
                  {/* <img src="/img/soccer/test2.png" width={80} height={80} /> */}
                  <div
                    className="size-for-player-circle"
                    style={{ position: "" }}
                  >
                    <span
                      className={`size-for-player-circle player-circle ${
                        players[idx][ePlayer] ? "correct-guess" : ""
                      }`}
                    ></span>
                  </div>
                  {players && (
                    <div style={{ position: "absolute", width: 120 }}>
                      <Tooltip
                      className="player-tooltip-for-ani"
                        title={
                          players[idx][ePlayer]
                            ? getPlayerName(idx, ePlayer)
                            : t("play.singlePlayerPage.fillTheLineupGameMode.unknown")
                        }
                        enterTouchDelay={0}
                        style={{}}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Chip
                          label={
                            players[idx][ePlayer]
                              ? getPlayerName(idx, ePlayer).length > 11
                                ? getPlayerName(idx, ePlayer).replace(
                                    getPlayerName(idx, ePlayer).substring(
                                      11,
                                      getPlayerName(idx, ePlayer).length
                                    ),
                                    "..."
                                  )
                                : getPlayerName(idx, ePlayer)
                              : t("play.singlePlayerPage.fillTheLineupGameMode.unknown")
                          }
                          style={{
                            color: "white",
                            // position: "absolute",
                            // bottom: -30,
                            // alignSelf: "center",
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                            overflow: "visible",
                            // padding: "2px !important",
                            // paddingLeft: 0,
                            // paddingRight: 0,
                          }}
                        />
                      </Tooltip>
                    </div>
                  )}
                </IconButton>
              );
            })}
          </div>
        ))}
        {/* Centered circle for animation */}
        <div
          className="centered-circle-for-ani"
          style={{
            width: 10,
            height: 10,
            position: "absolute",
            top: "50%",
            left: 0,
            zIndex: -1,
          }}
        ></div>
      </div>

      <style>
        {`
        #soccerFieldWithPlayers .MuiTouchRipple-root {
          top: -50px;
        }

        #soccerFieldWithPlayers .MuiChip-root{
          background-color: rgba(0, 0, 0, 0.48);
        }
    `}
      </style>
    </div>
  );
}
