import Container from "@mui/material/Container";
import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import CategoryForm from "../../components/category/CategoryForm";
import { useQueryClient } from "@tanstack/react-query";
import { Context as UserContext } from "../../context/UserContext";
import { useTranslation } from "react-i18next";

export default function EditCategory() {
  const {t} = useTranslation();
    const {
    state: { language },
  } = useContext(UserContext);
  const { catId } = useParams();
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData(["categories"]);
  const category = data.filter((each) => each._id == catId)[0];

  return (
    <Container className="container">
      <p className="display-5 mt-4 mb-5">
        {t("categoryPage.edit")} - {category.name[language.lng]}
      </p>
      <CategoryForm category={category}/>
    </Container>
  );
}
