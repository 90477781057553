import React, { useEffect, useState, useContext, useReducer } from "react";
import { Context as UserContext } from "../../context/UserContext";
import { getMatches, getMatchesByPOST } from "../../services/api/match";
import {
  jaroDistanceSearch,
  reshapePlayersFromAPI,
} from "../../services/helperFunctions";
import { reshapePlayers } from "../../services/Match/helperFunctionsAndObjects";
import { useTranslation } from "react-i18next";

const transformSingleItemArrToObj = () => {};
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
const matchInfoModalWidthSizeThreshold = 1205;
export const storageName = "fillTheLineupCache"; //{matchObj:{}, teamToFillNo:"One" || "Two", otherPlayers, matchesFinished: [{matchObjId, teamToFillNo}, ..., N]}
const getCachedGame = () => {
  return JSON.parse(localStorage.getItem(storageName));
};

const saveToCache = (type, payload) => {
  const temp = getCachedGame();

  if (type === "NEW_MATCH") {
    if (temp) {
      localStorage.setItem(
        storageName,
        JSON.stringify({
          ...temp,
          matchObj: payload.matchObj,
          numberOfCorrectGuesses: payload.numberOfCorrectGuesses,
          otherPlayers: payload.otherPlayers,
          teamToFillNo: payload.teamToFillNo,
          userAnswers: payload.userAnswers,
          matchesFinished: payload.matchesFinished,
        })
      );
      return;
    }
    localStorage.setItem(storageName, JSON.stringify({ ...payload }));
  } else if (type === "SAVE_USER_ANSWERS") {
    localStorage.setItem(
      storageName,
      JSON.stringify({
        ...temp,
        userAnswers: payload.userAnswers,
        numberOfCorrectGuesses: payload.numberOfCorrectGuesses,
      })
    );
  } else if (type === "ANOTHER_MATCH_SAME_TEAM") {
    localStorage.setItem(
      storageName,
      JSON.stringify({
        matchesFinished: payload,
        matchObj: temp.matchObj,
        otherPlayers: temp.otherPlayers,
        teamToFillNo: "Two",
        numberOfCorrectGuesses: 0,
      })
    );
  } else if (type === "ANOTHER_MATCH_DIFFERENT_TEAM") {
    localStorage.setItem(
      storageName,
      JSON.stringify({
        matchesFinished: payload,
      })
    );
  } else if (type === "USER_FINISHED_ALL_MATCHES") {
    // remove cache bcuz user finished all matches in DB
    localStorage.removeItem(storageName);
  }
};

const ACTIONS = {
  NEW_MATCH_OBJ: "NEW_MATCH_OBJ",
  USE_CACHED_MATCH_OBJ: "USE_CACHED_MATCH_OBJ",
  MODIFY_USER_PLAYER_QUERY: "MODIFY_USER_PLAYER_QUERY",
  UPDATE_SUGGESTEmatchesFinishedanotD_PLAYERS: "UPDATE_SUGGESTED_PLAYERS",
  CHANGE_CURRENT_PLAYER_TO_GUESS: "CHANGE_CURRENT_PLAYER_TO_GUESS",
  GUESS_PLAYER_MODAL_TOGGLE: "GUESS_PLAYER_MODAL_TOGGLE",
  CHANGE_USER_FINAL_ANSWER_FOR_CURRENT_PLAYER_TO_GUESS:
    "CHANGE_USER_FINAL_ANSWER_FOR_CURRENT_PLAYER_TO_GUESS",
  USER_CORRECT_ANSWER: "USER_CORRECT_ANSWER",
  UPDATE_BUBBLE_CHAT: "UPDATE_BUBBLE_CHAT",
  UPDATE_OPEN_MATCH_INFO_BOOL: "UPDATE_OPEN_MATCH_INFO_BOOL",
  UPDATE_NUMBER_OF_CORRECT_GUESSES: "UPDATE_NUMBER_OF_CORRECT_GUESSES",
  UPDATE_SHOW_CELEBRTAION_ANIMATION: "UPDATE_SHOW_CELEBRTAION_ANIMATION",
};

const initialObject = {
  matchObj: undefined,
  teamToFillNo: undefined,
  userAnswers: undefined,
  userPlayerQuery: "",
  suggestedPlayers: [],
  otherPlayers: undefined,
  currentPlayerToGuess: undefined,
  openPlayerGuessModal: false,
  userFinalAnswerForCurrentPlayerToGuess: undefined,
  bubbleChat: "",
  openMatchInfoModal:
    window.innerWidth <= matchInfoModalWidthSizeThreshold ? true : false,
  numberOfCorrectGuesses: 0,
  showCelebrationAnimation: false,
  isMatchCached: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.NEW_MATCH_OBJ:
      return {
        ...initialObject,
        matchObj: action.payload.matchObj,
        teamToFillNo: action.payload.teamToFillNo,
        userAnswers: action.payload.userAnswers
          ? action.payload.userAnswers
          : reshapePlayers(
              action.payload.matchObj[`team${action.payload.teamToFillNo}`]
                .lineup
            ),
        otherPlayers: action.payload.otherPlayers,
        numberOfCorrectGuesses: action.payload.numberOfCorrectGuesses,
      };

    case ACTIONS.USE_CACHED_MATCH_OBJ:
      return { ...action.payload };

    case ACTIONS.MODIFY_USER_PLAYER_QUERY:
      if (action.payload == "") state.suggestedPlayers = [];
      return { ...state, userPlayerQuery: action.payload };

    case ACTIONS.UPDATE_SUGGESTED_PLAYERS:
      return { ...state, suggestedPlayers: action.payload };

    case ACTIONS.CHANGE_CURRENT_PLAYER_TO_GUESS:
      // if (action.payload) state.openPlayerGuessModal = true;
      // else state.openPlayerGuessModal = false;
      return {
        ...state,
        ...action.payload,
      }; //{row:number,col:number}
    case ACTIONS.GUESS_PLAYER_MODAL_TOGGLE:
      return { ...state, openPlayerGuessModal: action.payload };

    case ACTIONS.CHANGE_USER_FINAL_ANSWER_FOR_CURRENT_PLAYER_TO_GUESS:
      return {
        ...state,
        userFinalAnswerForCurrentPlayerToGuess: action.payload,
      };
    case ACTIONS.USER_CORRECT_ANSWER:
      return { ...state, ...action.payload };

    case ACTIONS.UPDATE_BUBBLE_CHAT:
      return { ...state, bubbleChat: action.payload };

    case ACTIONS.UPDATE_OPEN_MATCH_INFO_BOOL:
      return { ...state, openMatchInfoModal: action.payload };

    case ACTIONS.UPDATE_NUMBER_OF_CORRECT_GUESSES:
      return { ...state, numberOfCorrectGuesses: action.payload };
    case ACTIONS.UPDATE_SHOW_CELEBRTAION_ANIMATION:
      return { ...state, showCelebrationAnimation: action.payload };
    default:
      return state;
  }
};

export default function useLineupsGameModeHook() {
  const { t } = useTranslation();
  const {
    state: { language },
  } = useContext(UserContext);

  const [state, dispatch] = useReducer(reducer, initialObject);

  const setUserPlayerQuery = (str) => {
    dispatch({ type: ACTIONS.MODIFY_USER_PLAYER_QUERY, payload: str });
  };

  const setOpenPlayerGuessModal = (bol) => {
    dispatch({ type: ACTIONS.GUESS_PLAYER_MODAL_TOGGLE, payload: bol });
  };

  const setOpenMatchInfoModal = (bol) => {
    dispatch({ type: ACTIONS.UPDATE_OPEN_MATCH_INFO_BOOL, payload: bol });
  };

  const setUserFinalAnswerForCurrentPlayerToGuess = (str) => {
    dispatch({
      type: ACTIONS.CHANGE_USER_FINAL_ANSWER_FOR_CURRENT_PLAYER_TO_GUESS,
      payload: str,
    });
  };

  const checkUserAnswerCorrect = () => {
    if (
      state.currentPlayerToGuess &&
      state.userAnswers[state.currentPlayerToGuess.row][
        state.currentPlayerToGuess.col
      ]
    )
      return true;

    return (
      state.currentPlayerToGuess &&
      state.matchObj[`team${state.teamToFillNo}`].players[
        state.currentPlayerToGuess.row
      ][state.currentPlayerToGuess.col].name[language.lng] ==
        state.userFinalAnswerForCurrentPlayerToGuess
    );
  };

  const checkUserWonGame = () => {
    if (state.numberOfCorrectGuesses >= 11) return true;
    return false;
  };

  const anotherMatch = () => {
    const cachedObj = getCachedGame();
    if (cachedObj.matchesFinished) {
      if (state.teamToFillNo === "One") {
        cachedObj.matchesFinished.map((eachObject) => {
          if (eachObject._id == state.matchObj._id) {
            eachObject.teamToFillNo = "Two";
          }
          return eachObject;
        });
        saveToCache(
          "ANOTHER_MATCH_SAME_TEAM",
          cachedObj.matchesFinished.map((em) => {
            if (em._id == state.matchObj._id) {
              em.teamToFillNo = "Two";
            }
            return em;
          })
        );
      } else if (state.teamToFillNo === "Two") {
        saveToCache("ANOTHER_MATCH_DIFFERENT_TEAM", cachedObj.matchesFinished);
      }
    }
    window.location.reload();
  };

  useEffect(() => {
    // fetch and set the needed data for the game mode
    sleep(2000).then(() => {
      const cachedGame = getCachedGame();
      if (cachedGame && cachedGame.matchObj) {
        dispatch({
          type: ACTIONS.NEW_MATCH_OBJ,
          payload: { ...cachedGame, isMatchCached: true },
        });
      } else {
        const fetchMatchObj = async () => {
          let data = await getMatchesByPOST({
            matchesFinished:
              cachedGame && cachedGame.matchesFinished
                ? cachedGame.matchesFinished
                : undefined,
          });
          if (data.isUserFinishedAllMatches) {
            // remove cache bcuz user finished all matches in DB
            saveToCache("USER_FINISHED_ALL_MATCHES");
            window.location.reload();
            return;
          }
          const matchObj = data.match;
          const otherPlayers = data.otherPlayers.filter((eachSuggested) => {
            let flag = true;
            // each obj will have name and _id
            matchObj.teamOne.players.forEach((ep) => {
              if (eachSuggested._id == ep._id) {
                flag = false;
              }
            });
            matchObj.teamTwo.players.forEach((ep) => {
              if (eachSuggested._id == ep._id) {
                flag = false;
              }
            });
            return flag;
          });
          matchObj.teamOne.players = reshapePlayersFromAPI(
            matchObj.teamOne.players,
            matchObj.teamOne.lineup
          );
          matchObj.teamOne.team = matchObj.teamOne.team
            ? matchObj.teamOne.team
            : matchObj.teamOne.nationalTeam;

          matchObj.teamTwo.players = reshapePlayersFromAPI(
            matchObj.teamTwo.players,
            matchObj.teamTwo.lineup
          );
          matchObj.teamTwo.team = matchObj.teamTwo.team
            ? matchObj.teamTwo.team
            : matchObj.teamTwo.nationalTeam;
          dispatch({
            type: ACTIONS.NEW_MATCH_OBJ,
            payload: {
              matchObj: matchObj,
              teamToFillNo: "One",
              otherPlayers: otherPlayers.map((ep) => ep.name[language.lng]),
              numberOfCorrectGuesses: 0,
            },
          });
          saveToCache("NEW_MATCH", {
            matchObj: matchObj,
            numberOfCorrectGuesses: 0,
            otherPlayers: otherPlayers.map((ep) => ep.name[language.lng]),
            teamToFillNo: "One",
            userAnswers: undefined,
            matchesFinished:
              cachedGame && cachedGame.matchesFinished
                ? [
                    ...cachedGame.matchesFinished,
                    { _id: matchObj._id, teamToFillNo: "One" },
                  ]
                : [{ _id: matchObj._id, teamToFillNo: "One" }],
          });
        };
        fetchMatchObj();
      }
    });
  }, []);

  useEffect(() => {
    // set suggested players for the user to choose from when typing letters
    if (
      state.matchObj &&
      state.otherPlayers &&
      state.userPlayerQuery.length >= 2
    ) {
      dispatch({
        type: ACTIONS.UPDATE_SUGGESTED_PLAYERS,
        payload: jaroDistanceSearch(
          [
            ...state.otherPlayers,
            ...state.matchObj.teamOne.players
              .map((eachArr) => {
                return eachArr.map((ep) => {
                  return ep.name[language.lng];
                });
              })
              .flat(),
            ...state.matchObj.teamTwo.players
              .map((eachArr) => {
                return eachArr.map((ep) => {
                  return ep.name[language.lng];
                });
              })
              .flat(),
          ],
          state.userPlayerQuery
        ).splice(0, 5),
      });
    }
  }, [state.userPlayerQuery, state.matchObj, state.otherPlayers]);

  useEffect(() => {
    // change the player to guess
    if (!state.openPlayerGuessModal) {
      dispatch({
        type: ACTIONS.CHANGE_CURRENT_PLAYER_TO_GUESS,
        payload: {
          currentPlayerToGuess: undefined,
          userFinalAnswerForCurrentPlayerToGuess: "",
          userPlayerQuery: "",
          suggestedPlayers: [],
          bubbleChat: t(
            "play.singlePlayerPage.hintsGameModePage.hintsText.modal.chatBubbleDef"
          ),
        },
      });
    } else {
      if (checkUserAnswerCorrect())
        dispatch({
          type: ACTIONS.UPDATE_BUBBLE_CHAT,
          payload:
            t(
              "play.singlePlayerPage.hintsGameModePage.hintsText.modal.correctAnsChatBubble"
            ) +
            state.userAnswers[state.currentPlayerToGuess.row][
              state.currentPlayerToGuess.col
            ] +
            ".",
        });
    }
  }, [state.openPlayerGuessModal]);

  useEffect(() => {
    // when user answers we need to:
    // increment userTotalCorrectGuesses
    // modify the userAnswers array
    if (state.userFinalAnswerForCurrentPlayerToGuess) {
      if (checkUserAnswerCorrect()) {
        // sleep(1000); //wait for animation to finish
        const temp = state.userAnswers;
        temp[state.currentPlayerToGuess.row][state.currentPlayerToGuess.col] =
          state.userFinalAnswerForCurrentPlayerToGuess;
        saveToCache("SAVE_USER_ANSWERS", {
          userAnswers: temp,
          numberOfCorrectGuesses: state.numberOfCorrectGuesses + 1,
        });
        dispatch({
          type: ACTIONS.USER_CORRECT_ANSWER,
          payload: {
            userAnswers: temp,
            // userFinalAnswerForCurrentPlayerToGuess: "",
            userPlayerQuery: "",
            bubbleChat:
              t(
                "play.singlePlayerPage.hintsGameModePage.hintsText.modal.correctAnsChatBubble"
              ) +
              state.userFinalAnswerForCurrentPlayerToGuess +
              ".",
            numberOfCorrectGuesses: state.numberOfCorrectGuesses + 1,
            showCelebrationAnimation:
              state.numberOfCorrectGuesses + 1 >= 11 ? true : false,
            openPlayerGuessModal: true,
            [`${
              state.numberOfCorrectGuesses + 1 >= 11
                ? "openPlayerGuessModal"
                : ""
            }`]: false,
          },
        });
      } else {
        dispatch({
          type: ACTIONS.UPDATE_BUBBLE_CHAT,
          payload:
            t(
              "play.singlePlayerPage.hintsGameModePage.hintsText.modal.wrongAnsChatBubble"
            ) +
            state.userFinalAnswerForCurrentPlayerToGuess +
            "!",
        });
      }
    }
  }, [state.userFinalAnswerForCurrentPlayerToGuess]);

  console.log("state: ", state);

  return {
    language,
    ...state,
    setOpenPlayerGuessModal,
    setUserPlayerQuery,
    setUserFinalAnswerForCurrentPlayerToGuess,
    setOpenMatchInfoModal,
    checkUserAnswerCorrect,
    checkUserWonGame,
    anotherMatch,
    matchInfoModalWidthSizeThreshold,
    dispatch,
    ACTIONS,
  };
}
