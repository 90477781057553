import React, { useContext } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import * as Flags from "country-flag-icons/react/3x2";
import { useTranslation } from "react-i18next";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material/styles";
import useMuiDirChangeHook from "../hooks/useMuiDirChangeHook";
import { Context as UserContext } from "../context/UserContext";
import { useQuery } from "@tanstack/react-query";
import { getNationalTeams } from "../services/api/nationalTeams";
import LoadingAnimation from "./LoadingAnimation";
import EnglandFlag from "../icons/extraFlags/gb-eng";
import { Context as TablePageOptionsContext } from "../context/TablePageOptions";
import WalesFlag from "../icons/extraFlags/gb-wls";
import ScotlandFlag from "../icons/extraFlags/gb-sct";

export default function CountrySelector({ formik, pageName }) {
  const { t } = useTranslation();
  const {
    state: { language },
  } = useContext(UserContext);
  const { state: tableOptionsState, setSelectedCountry } = useContext(
    TablePageOptionsContext
  );
  const { theme, cacheRtl } = useMuiDirChangeHook(language);
  const { isPending, error, data } = useQuery({
    queryKey: ["nationalTeams"],
    queryFn: getNationalTeams,
  });
  if (error) {
    return <h5>Error happened try again later!</h5>;
  }
  if (isPending) {
    return <LoadingAnimation />;
  }
  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <FormControl className="" style={{}}>
          <InputLabel id="countries-dd" className="hide-label-onmodal">
            {t("inputLabels.country")}
          </InputLabel>
          <Select
            labelId="countries-dd"
            value={
              formik
                ? formik.values.country
                  ? formik.values.country
                  : data[0]._id
                : tableOptionsState[pageName].selectedCountry
                ? tableOptionsState[pageName].selectedCountry
                : ""
            }
            label={t("inputLabels.country")}
            style={{ width: 300 }}
            name="country"
            onChange={
              formik
                ? formik.handleChange
                : (e) => {
                    setSelectedCountry(pageName, e.target.value);
                  }
            }
            sx={{}}
          >
            {!formik && (
              <MenuItem value={""} className="d-flex gap-5">
                <span color="black">All</span>
              </MenuItem>
            )}
            {data.map((eachCountry, idx) => {
              let Flag = Flags[eachCountry.alpha_2];
              if (eachCountry.alpha_2 === "ENGLAND")
                Flag =
                  EnglandFlag; // THESE ARE SPECIFIC CASES BCZ WALES AND ENGLAND DONT HAVE A CODE. IRELAND TODO
              else if (eachCountry.alpha_2 === "WALES") Flag = WalesFlag;
              else if (eachCountry.alpha_2 === "SCOTLAND") Flag = ScotlandFlag;
              else if (Flag == undefined) return null;
              return (
                <MenuItem
                  value={eachCountry._id}
                  key={`${idx}${eachCountry.alpha_2}`}
                  className="d-flex gap-5"
                >
                  <Flag style={{ width: 40, height: 40 }} />{" "}
                  <span>{eachCountry.name[language.lng]}</span>
                </MenuItem>
              );
            })}
            {/* <MenuItem value={10}>Ten</MenuItem>
      <MenuItem value={20}>Twenty</MenuItem>
      <MenuItem value={30}>Thirty</MenuItem> */}
          </Select>
        </FormControl>
      </ThemeProvider>
    </CacheProvider>
  );
}
