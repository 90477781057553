import React, { useEffect, useRef } from "react";
import Lottie from "react-lottie";
import animationData from "../../../lottie/wrongAnswer.json";
import correctAnsData from "../../../lottie/correctAnswer.json";

export default function AnswerAni({
  handleAnimationDone,
  showAniWhenAnswer,
  showCorrectAni,
}) {
  if (!showAniWhenAnswer) {
    return <></>;
  }
  return (
    <div style={{ marginTop: 100 }}>
      <Lottie
        options={{
          loop: false,
          autoplay: true,
          animationData: showCorrectAni ? correctAnsData : animationData,
          rendererSettings: { duration: 1 },
        }}
        height={showCorrectAni ? 300 : 400}
        width={showCorrectAni ? 300 : 400}
        speed={2}
        isClickToPauseDisabled
        eventListeners={[
          {
            eventName: "complete",
            callback: handleAnimationDone,
          },
        ]}
      />
    </div>
  );
}
