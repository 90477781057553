import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "@mui/material";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getTeams, deleteTeam } from "../../services/api/team";
import { getNationalTeams } from "../../services/api/nationalTeams";
import LoadingAnimation from "../../components/LoadingAnimation";
import CustomTable from "../../components/CustomTable";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import Toast from "../../components/Toast";
import * as Flags from "country-flag-icons/react/3x2";
import { countries } from "country-flag-icons";
import { Context as UserContext } from "../../context/UserContext";
import { Context as TableOptionsContext } from "../../context/TablePageOptions";
import EnglandFlag from "../../icons/extraFlags/gb-eng";
import Search from "../../components/Search";
import VerticalRadioGroup from "../../components/Team/VerticalRadioGroup";
import CountrySelector from "../../components/CountrySelector";
import DetailedTeamViewModal from "../../components/Team/DetailedTeamViewModal";
import WalesFlag from "../../icons/extraFlags/gb-wls";
import ScotlandFlag from "../../icons/extraFlags/gb-sct";

export default function Team() {
  const { t } = useTranslation();
  const {
    state: { language },
  } = useContext(UserContext);
  const { state, setCurrentFilterType } = useContext(TableOptionsContext);
  const [open, setOpen] = useState(false);
  const [teamToView, setTeamToView] = useState(undefined);
  const queryClient = useQueryClient();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const { isPending, error, data } = useQuery({
    queryKey: [
      "teams",
      `${
        state["teamPage"].currentSelectedFilterType == "search"
          ? state["teamPage"].searchString
          : state["teamPage"].selectedCountry
      }`,
    ],
    queryFn: () => {
      return state["teamPage"].currentSelectedFilterType == "search"
        ? getTeams(state["teamPage"].searchString)
        : getTeams(undefined, undefined, state["teamPage"].selectedCountry);
    },
  });
  const {
    isPending: isPendingNationalTeams,
    error: isErrorNationalTeams,
    data: nationalTeams,
  } = useQuery({
    queryKey: ["nationalTeams"],
    queryFn: getNationalTeams,
  });
  const deleteTeamMutation = useMutation({
    mutationFn: deleteTeam,
    onSuccess: () => {
      queryClient.invalidateQueries("teams");
      setSnackbar({
        open: true,
        message: t("teamPage.success.delete"),
        severity: "success",
      });
    },
    onError: () => {
      setSnackbar({
        open: true,
        message: t("teamPage.errors.delete"),
        severity: "error",
      });
    },
  });
  if (error || isErrorNationalTeams) {
    return <h1>OPS, an error occurred!</h1>;
  }
  if (isPending || isPendingNationalTeams) {
    return <LoadingAnimation />;
  }
  return (
    <Container>
      <h1 className="mt-5"> {t("teamPage.title")}</h1>
      <div className="d-flex justify-content-between mt-5 mb-2 align-items-end">
        <div className="d-flex align-items-end gap-4">
          <VerticalRadioGroup
            searchType={state["teamPage"].currentSelectedFilterType}
            setCurrentFilterType={setCurrentFilterType}
          />
          {state["teamPage"].currentSelectedFilterType == "search" && (
            <Search pageName={"teamPage"} />
          )}
          {state["teamPage"].currentSelectedFilterType == "countrySelector" && (
            <CountrySelector pageName={"teamPage"} />
          )}
        </div>
        <div>
          <Link to={"add-new"}>
            <Button variant="contained">
              {t("teamPage.add")} <AddIcon />
            </Button>
          </Link>
        </div>
      </div>
      <CustomTable
        columns={[
          { headerName: t("teamPage.columns.item1") },
          { headerName: t("teamPage.columns.item2") },
          { headerName: t("teamPage.columns.item3") },
        ]}
        rows={data.map((eachCat) => {
          let Flag = undefined;
          if (countries.includes(eachCat.country && eachCat.country.alpha_2))
            Flag = Flags[eachCat.country.alpha_2];
          else if (eachCat.country && eachCat.country.alpha_2 === "ENGLAND")
            Flag = EnglandFlag;
          else if (eachCat.country && eachCat.country.alpha_2 === "WALES")
            Flag = WalesFlag;
          else if (eachCat.country && eachCat.country.alpha_2 === "SCOTLAND")
            Flag = ScotlandFlag;
          return {
            teamName: eachCat.name.en,
            teamNameAr: eachCat.name.ar,
            country: (
              <div
                className=""
                style={{ display: "flex", alignItems: "center", gap: 10 }}
              >
                {Flag && <Flag style={{ width: 40, height: 40 }} />}
                {nationalTeams.find(
                  (eachNt) =>
                    eachNt.alpha_2 ==
                    (eachCat.country && eachCat.country.alpha_2)
                )
                  ? nationalTeams.find(
                      (eachNt) => eachNt.alpha_2 == eachCat.country.alpha_2
                    ).name[language.lng]
                  : ""}
              </div>
            ),
            _id: eachCat._id,
          };
        })}
        handleDelete={(id) => {
          deleteTeamMutation.mutate(id);
        }}
        pageName={"teamPage"}
        handleDetailedView={(teamId) => {
          setOpen(true);
          setTeamToView(data.find((eachTeam) => eachTeam._id == teamId));
        }}
      />
      <Toast
        handleSnackbarClose={() => {
          setSnackbar({ open: false, message: "" });
        }}
        snackbar={snackbar}
      />
      <DetailedTeamViewModal
        open={open}
        setOpen={setOpen}
        teamToView={teamToView}
      />
    </Container>
  );
}
