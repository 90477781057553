import React, { useContext, useState } from "react";
import { commonBackgroundColorAndBorder } from "../../../services/styles";
import Chip from "@mui/material/Chip";
import "../../../styles/Game/wheredTheyduod.css";
import Tooltip from "@mui/material/Tooltip";
import useWheredTheyDuodGameModeHook from "../../../hooks/Game/useWheredTheyDuodGameModeHook";
import BallLoading from "../../../components/BallLoading";
import "../../../styles/buttonStyles.css";
import "../../../styles/modalStyle.css";
import GuessTeamModal from "../../../components/GameHome/WheredTheyDuod/GuessTeamModal";
import { useTranslation } from "react-i18next";
import { Context as UserContext } from "../../../context/UserContext";
import CommonQuickAccessMenu from "../../../components/GameHome/CommonQuickAccessMenu";
import useWheredTheyDuodGsapHook from "../../../hooks/Game/useWheredTheyDuodGsapHook";

export default function WheredTheyDuodGameMode() {
  const { t } = useTranslation();
  const {
    state: { language },
  } = useContext(UserContext);
  const {
    isLoading,
    twoPlayerObjAndTeams,
    returnSuggestTeams,
    saveToCache,
    getCachedDuo,
  } = useWheredTheyDuodGameModeHook(language);
  const [open, setOpen] = useState(false);
  useWheredTheyDuodGsapHook(isLoading, language.dir);
  if (isLoading) {
    return <BallLoading />;
  }
  return (
    <div
      className="container"
      style={{ direction: language.dir, overflow: "hidden" }}
    >
      <div
        className="title-menu-div"
        style={{
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          marginTop: 20,
        }}
      >
        <h2
          className="hints-mode-title"
          style={{ ...commonBackgroundColorAndBorder }}
        >
          {t("play.singlePlayerPage.wheredTheyDuodGameModePage.pageTitle")}
        </h2>
        <CommonQuickAccessMenu
          handleNewGame={() => {
            saveToCache("ANOTHER_GAME");
            window.location.reload();
          }}
          newMatchName={t(
            "play.singlePlayerPage.quickAccessMenu.wheredTheyDuod"
          )}
        />
      </div>
      <div
        style={{
          ...commonBackgroundColorAndBorder,
          width: "100%",
          marginTop: 30,
        }}
        className="parent-div"
      >
        <span
          style={{ direction: language.dir === "rtl" ? "ltr" : language.dir }} //use ltr when lang is rtl because there are english words
        >
          <h1 className="text-center title-in-parent">
            {t("play.singlePlayerPage.wheredTheyDuodGameModePage.title")}
          </h1>
        </span>

        <div className="div-with-all-images" style={{}}>
          <div
            className="left-side"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <div
              className="player-arrow-ani p1-div"
              style={{ marginRight: language.dir === "rtl" ? "46px" : null }}
            >
              <img src="/img/soccer/P1.png" className="img-ptext-size" />
            </div>
            <img
              src="/img/soccer/Team_B_Kick_SOUTHEAST.png"
              className="img-size"
              style={{
                transform: language.dir === "rtl" ? "rotateY(180deg)" : null,
              }}
            />
            <div className="mt-3 d-flex justify-content-center">
              <Tooltip
                title={twoPlayerObjAndTeams.playerOne.name[language.lng]}
                enterTouchDelay={0}
              >
                <Chip
                  label={
                    twoPlayerObjAndTeams.playerOne.name[language.lng].length > 8
                      ? twoPlayerObjAndTeams.playerOne.name[
                          language.lng
                        ].substring(0, 8) + "..."
                      : twoPlayerObjAndTeams.playerOne.name[language.lng]
                  }
                  style={{
                    color: "white",
                    position: "absolute",
                    bottom: -30,
                    alignSelf: "center",
                    whiteSpace: "break-spaces",
                    textAlign: "center",
                    overflow: "visible",
                  }}
                />
              </Tooltip>
            </div>
          </div>
          <div
            className="right-side"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <div
              className="player-arrow-ani p2-div"
              style={{ marginRight: language.dir === "rtl" ? "46px" : null }}
            >
              <img
                className="p2-img-text-arrow img-ptext-size"
                src="/img/soccer/P2.png"
              />
            </div>
            <img
              className="img-size"
              src="/img/soccer/Team_B_Kick_SOUTHEAST.png"
              style={{
                transform: language.dir === "ltr" ? "rotateY(180deg)" : null,
              }}
            />
            <div className="mt-3 d-flex justify-content-center">
              <Tooltip
                title={twoPlayerObjAndTeams.playerTwo.name[language.lng]}
                enterTouchDelay={0}
              >
                <Chip
                  label={
                    twoPlayerObjAndTeams.playerTwo.name[language.lng].length > 8
                      ? twoPlayerObjAndTeams.playerTwo.name[
                          language.lng
                        ].substring(0, 8) + "..."
                      : twoPlayerObjAndTeams.playerTwo.name[language.lng]
                  }
                  style={{
                    color: "white",
                    position: "absolute",
                    bottom: -30,
                    alignSelf: "center",
                    whiteSpace: "break-spaces",
                    textAlign: "center",
                    overflow: "visible",
                  }}
                />
              </Tooltip>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <div className="button-div-for-ani" style={{ textAlign: "center" }}>
          <button
            className="style-one-button"
            onClick={() => {
              setOpen(true);
            }}
          >
            {t("play.singlePlayerPage.wheredTheyDuodGameModePage.inputBttn")}
          </button>
        </div>
      </div>
      <GuessTeamModal
        open={open}
        setOpen={setOpen}
        twoPlayerObjAndTeams={twoPlayerObjAndTeams}
        returnSuggestTeams={returnSuggestTeams}
        language={language}
        handleAnotherGameClick={() => {
          saveToCache("ANOTHER_GAME");
          window.location.reload();
        }}
        saveToCache={saveToCache}
        getCachedDuo={getCachedDuo}
      />
    </div>
  );
}
