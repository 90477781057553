import { baseServerUrl } from "./globalVars";
import { getFBToken } from "./auth";

export const getMatchStages = async () => {
  const token = await getFBToken();
  const res = await fetch(`${baseServerUrl}/matchStage`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return await res.json();
};

// export const addNewCategory = async (categoryObj) => {
//   const response = await fetch(`${baseServerUrl}/category`, {
//     method: "POST",
//     headers: { "Content-Type": "application/json" },
//     body: JSON.stringify(categoryObj),
//   });
//   return response.data;
// };

// export const deleteCategory = async (id) => {
//   const response = await fetch(`${baseServerUrl}/category/${id}`, {
//     method: "DELETE",
//     headers: { "Content-Type": "application/json" },
//   });
//   return response.data;
// };

// export const editCategory = async (obj) => {
//   const response = await fetch(`${baseServerUrl}/category/${obj._id}`, {
//     method: "PUT",
//     headers: { "Content-Type": "application/json" },
//     body: JSON.stringify(obj.updatedCategory),
//   });
//   return response.data;
// };
