import React, { useState, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { reshapePlayers } from "../services/Match/helperFunctionsAndObjects";
import { categoriesThatAreNational } from "../services/Match/helperFunctionsAndObjects";

const checkObjectIsIncomplete = (state) => {
  if (
    !state.matchObj.stadium ||
    !state.matchObj.teamOne.coach ||
    !state.matchObj.teamTwo.coach ||
    state.matchObj.teamOne.goals == -1 ||
    // state.matchObj.teamOne.penaltyGoals == -1 ||
    state.matchObj.teamTwo.goals == -1 ||
    // state.matchObj.teamTwo.penaltyGoals == -1 ||
    (!state.matchObj.teamOne.team && !state.matchObj.teamOne.nationalTeam) ||
    (!state.matchObj.teamTwo.team && !state.matchObj.teamTwo.nationalTeam) ||
    !state.matchObj.category ||
    state.playersCounter != 22 ||
    !state.matchObj.matchStage
  )
    return true;

  return false;
};

const ACTIONS = {
  CHANGE_TEAM_TO_MODIFY: "CHANGE_TEAM_TO_MODIFY",
  CHANGE_TEAM: "CHANGE_TEAM",
  CURRENT_SELECTED_TEAM_AUTOCOMPLETE: "CURRENT_SELECTED_TEAM_AUTOCOMPLETE",
  CHANGE_TEAM_STADIUM: "CHANGE_TEAM_STADIUM",
  CHOOSE_A_PLAYER_IN_FORMATION: "CHOOSE_A_PLAYER_IN_FORMATION",
  RESHAPE_PLAYERS_OBJECT: "RESHAPE_PLAYERS_OBJECT",
  CHANGE_PLAYER_TO_BE_SELECTED: "CHANGE_PLAYER_TO_BE_SELECTED",
  CHANGE_TEAM_LINEUP: "CHANGE_TEAM_LINEUP",
  CHANGE_MATCH_DATE: "CHANGE_MATCH_DATE",
  CHANGE_MATCH_CATEGORY: "CHANGE_MATCH_CATEGORY",
  CHANGE_TEAM_COACH: "CHANGE_TEAM_COACH",
  CHANGE_TEAM_GOALS_SCORED: "CHANGE_TEAM_GOALS_SCORED",
  CHANGE_TEAM_PENALTY_GOALS_SCORED: "CHANGE_TEAM_PENALTY_GOALS_SCORED",
  CHANGE_MATCH_STAGE: "CHANGE_MATCH_STAGE",
  CHANGE_NATIONAL_TEAM: "CHANGE_NATIONAL_TEAM",
  AUTO_FILL_PLAYERS_FOR_TESTING_API: "AUTO_FILL_PLAYERS_FOR_TESTING_API",
};
function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.CHANGE_TEAM_TO_MODIFY:
      return {
        ...state,
        currentTeamToModify: action.payload,
        teamInputKey: action.payload + "teamInp",
        nationalTeamInputKey: action.payload + "nationalTeamInp",
        stadiumInputKey: action.payload + "stadiumInp",
        categoryInputKey: action.payload + "categoryInp",
        goalsInputKey: action.payload + "goalsInp",
        penaltyGoalsInputKey: action.payload + "penaltyGoalsInp",
        matchStageInputKey: action.payload + "matchStageInp",
        coachInputKey: action.payload + "coachInp",
      };
    case ACTIONS.CHANGE_TEAM:
      state.matchObj[state.currentTeamToModify].team = action.payload;
      return {
        ...state,
      };
    case ACTIONS.CURRENT_SELECTED_TEAM_AUTOCOMPLETE:
      return { ...state, currentAutocompleteInpTeamObj: action.payload };
    case ACTIONS.CHANGE_TEAM_STADIUM:
      state.matchObj.stadium = action.payload;
      return { ...state };
    case ACTIONS.RESHAPE_PLAYERS_OBJECT:
      return { ...state };
    case ACTIONS.CHOOSE_A_PLAYER_IN_FORMATION:
      if (
        !state.matchObj[state.currentTeamToModify].players[action.payload.row][
          action.payload.col
        ]
      )
        state.playersCounter = action.payload.playersCounter;
      state.matchObj[state.currentTeamToModify].players[action.payload.row][
        action.payload.col
      ] = action.payload.selectedPlayer;
      return { ...state };

    case ACTIONS.CHANGE_PLAYER_TO_BE_SELECTED:
      state.playerToBeSelectedRowNCol = {
        row: action.payload.row,
        col: action.payload.col,
      };
      return { ...state };

    case ACTIONS.CHANGE_TEAM_LINEUP:
      //TODO: to be removd and replaced with the commented code
      //   state.matchObj.teamOne.players.forEach((eachArr, idx) => {
      //     eachArr.forEach((eachPlayer, idx2) => {
      //       state.matchObj.teamOne.players[idx][idx2] = action.payload;
      //     });
      //   });
      //   state.matchObj.teamTwo.players.forEach((eachArr, idx) => {
      //     eachArr.forEach((eachPlayer, idx2) => {
      //       state.matchObj.teamTwo.players[idx][idx2] = action.payload;
      //     });
      //   });
      state.matchObj[state.currentTeamToModify].lineup = action.payload;

      state.matchObj[state.currentTeamToModify].players = reshapePlayers(
        state.matchObj[state.currentTeamToModify].lineup
      );
      return { ...state };

    case ACTIONS.CHANGE_MATCH_DATE:
      state.matchObj.matchDate = action.payload;
      return { ...state };

    case ACTIONS.CHANGE_MATCH_CATEGORY:
      if (
        categoriesThatAreNational.includes(
          state.matchObj.category ? state.matchObj.category.name.en : ""
        )
      )
        state.matchObj[state.currentTeamToModify].team = undefined;
      else state.matchObj[state.currentTeamToModify].nationalTeam = undefined;

      state.matchObj.category = action.payload;
      return { ...state };

    case ACTIONS.CHANGE_TEAM_COACH:
      state.matchObj[state.currentTeamToModify].coach = action.payload;
      return { ...state };

    case ACTIONS.CHANGE_TEAM_GOALS_SCORED:
      state.matchObj[state.currentTeamToModify].goals = action.payload;
      if(state.matchObj.teamOne.goals != state.matchObj.teamTwo.goals){
        state.matchObj.teamOne.penaltyGoals = -1;
        state.matchObj.teamTwo.penaltyGoals = -1;
      }
      return { ...state };

    case ACTIONS.CHANGE_TEAM_PENALTY_GOALS_SCORED:
      state.matchObj[state.currentTeamToModify].penaltyGoals = action.payload;
      return {...state};

    case ACTIONS.CHANGE_MATCH_STAGE:
      state.matchObj.matchStage = action.payload;
      return { ...state };

    case ACTIONS.AUTO_FILL_PLAYERS_FOR_TESTING_API:
      // state.matchObj.teamOne.players.forEach((eachArr, idx) => {
      //   eachArr.forEach((eachPlayer, idx2) => {
      //     state.matchObj.teamOne.players[idx][idx2] = action.payload;
      //   });
      // });
      // state.matchObj.teamTwo.players.forEach((eachArr, idx) => {
      //   eachArr.forEach((eachPlayer, idx2) => {
      //     state.matchObj.teamTwo.players[idx][idx2] = action.payload;
      //   });
      // });
      // state.playersCounter = 22
      return { ...state };

    case ACTIONS.CHANGE_NATIONAL_TEAM:
      state.matchObj[state.currentTeamToModify].nationalTeam = action.payload;
      return { ...state };
    default:
      return state;
  }
}

export default function useMatchFormHook(
  navigate,
  initialState,
  handleFormSubmit
) {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const queryClient = useQueryClient();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const addMatchMutation = useMutation({
    mutationFn: handleFormSubmit,
    onSuccess: () => {
      queryClient.invalidateQueries("matches");
      setSnackbar({
        open: true,
        message: "Match added successfully!",
        severity: "success",
      });
    },
    onError: () => {
      setSnackbar({
        open: true,
        message: "Error adding a new match!",
        severity: "error",
      });
    },
  });

  const editMatchMutation = useMutation({
    mutationFn: handleFormSubmit,
    onSuccess: () => {
      queryClient.invalidateQueries("matches");
      setSnackbar({
        open: true,
        message: "Match edited succesfully",
        severity: "success",
      });
    },
    onError: () => {
      setSnackbar({
        open: true,
        message: "Error editing the match! Please try again.",
        severity: "error",
      });
    },
  });

  const handleSnackbarClose = () => {
    if (snackbar.severity == "success") navigate(-1);
    setSnackbar({ open: false, message: "" });
  };

  return {
    t,
    addMatchMutation,
    snackbar,
    handleSnackbarClose,
    setSnackbar,
    ACTIONS,
    dispatch,
    checkObjectIsIncomplete,
    state,
    editMatchMutation,
  };
}
