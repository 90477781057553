import { baseServerUrl } from "./globalVars";
import { getFBToken } from "./auth";

export const getStadiums = async () => {
  const token = await getFBToken();
  const res = await fetch(`${baseServerUrl}/stadium`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return await res.json();
};

export const addNewStadium = async (stadiumObj) => {
  const token = await getFBToken();
  const response = await fetch(`${baseServerUrl}/stadium`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(stadiumObj),
  });
  return response.data;
};

export const deleteStadium = async (id) => {
  const token = await getFBToken();
  const response = await fetch(`${baseServerUrl}/stadium/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const editStadium = async (obj) => {
  const token = await getFBToken();
  const response = await fetch(`${baseServerUrl}/stadium/${obj._id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(obj.updatedStadium),
  });
  return response.data;
};
