import { baseServerUrl } from "./globalVars";
import { signOut } from "firebase/auth";
import { auth } from "../../config/firebase";
export const login = async (token) => {
  const response = await fetch(`${baseServerUrl}/auth/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    // Handle errors appropriately
    const errorMessage = await response.text(); // Get error message from response
    signOut(auth); // important to remove token if user not in mongodb 
    throw new Error(`Error: ${response.status} - ${errorMessage}`);
  }
  
  return "done";
};

export const getFBToken = async () => {
  try {
    const user = auth.currentUser;
    if (user) {
      const token = await user.getIdToken();
      return token;
    } else {
      // console.log("No user is signed in.");
      return null;
    }
  } catch (error) {
    console.log("Error getting token, api");
  }
}