import { IconButton } from "@mui/material";
import React from "react";
import Modal from "react-modal";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { languageResources } from "../services/i18next";
import { useContext } from "react";
import { Context as UserContext } from "../context/UserContext";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    width: "60%",
    height: "50%",
    margin: "auto",
    backgroundColor: "white",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    // zIndex:"10",
  },
};

export default function TranslationModal({
  isOpen,
  handleClose,
  labelI18Key,
  iconComponent,
  formik,
  fieldName,
}) {
  const {
    state: { language },
  } = useContext(UserContext);
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} style={customStyles}  ariaHideApp={false}>
      <IconButton
        sx={{ position: "absolute", right: 20, top: 10 }}
        onClick={handleClose}
      >
        <HighlightOffIcon sx={{ fontSize: 45, color: "red" }} />
      </IconButton>
      <div className="mb-5 text-center">
        <h2>{t("translationModal.title")}</h2>
      </div>
      <div>
        {Object.keys(languageResources).map((eachLng, idx) => {
          if (eachLng === language.lng) return null;
          return (
            <TextField
              label={`${t(labelI18Key)} (${
                languageResources[eachLng].text[language.lng]
              } - ${languageResources[eachLng].text[eachLng]})`}
              key={idx}
              sx={{ m: 1, width: "100%", paddingRight: 10 }}
              name={`${fieldName}.${eachLng}`}
              error={
                formik.errors[fieldName] && formik.errors[fieldName][eachLng]
                  ? true
                  : false
              }
              value={formik.values[fieldName][eachLng]}
              onChange={formik.handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {iconComponent}
                  </InputAdornment>
                ),
              }}
            />
          );
        })}
      </div>
    </Modal>
  );
}
